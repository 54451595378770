import { CountryCode } from '../../constants/languageConstants';
import { CurrencyCode } from '../../constants/currencyConstants';
import COUNTRY_LOCALES_MAP from '../../constants/localeConstants';

// TODO: Since /locations endpoint doesn’t provide language param yet in light house we are maintaing the region language/locale
// in FE and it will be moved to BE as api property once the param is available in light house
/**
 * Returns locales list for specified country code
 *
 * @function
 * @param {string} countryCode Two-letter ISO 3166-1 alpha-2 country code
 * @returns {undefined | string[]} list of locales
 */
export function getCountryLocales(countryCode: CountryCode): undefined | string[] {
	if (!Object.prototype.hasOwnProperty.call(COUNTRY_LOCALES_MAP, countryCode)) {
		return undefined;
	}

	return COUNTRY_LOCALES_MAP[countryCode];
}

/**
 * Returns currency symbol for a given currency code
 *
 * @param {string} currencyCode - Three-letter ISO 4217 currency code
 * @returns {string}
 */
export const getCurrencySymbol = (currencyCode: CurrencyCode = CurrencyCode.USD): string => {
	const currencySymbol =
		new Intl.NumberFormat(undefined, {
			style: 'currency',
			currency: currencyCode,
			currencyDisplay: 'narrowSymbol',
		})
			.formatToParts(1)
			.find((x) => x.type === 'currency')?.value ?? '';

	return currencySymbol;
};

/**
 * Checks if the given value is null or undefined.
 *
 * @param {number} value - The value to check for null or undefined.
 * @returns {boolean} - True if the value is null or undefined, false otherwise.
 */
function isNil(value: number) {
	return value === null || value === undefined;
}

/**
 * Returns formatted price as a string with currency symbol
 *
 * @param {number} cents
 * @param {string} currencyCode - Three-letter ISO 4217 currency code
 * @returns {string}
 */
export function getFormattedPrice(cents: number, currencyCode: CurrencyCode): string {
	let centsValue = cents;
	// Prevent -$0.00 value. In JS -0 === 0 is true
	if (cents === 0) {
		centsValue = 0;
	}

	return isNil(cents)
		? 'n/a'
		: new Intl.NumberFormat(undefined, {
				style: 'currency',
				currency: currencyCode,
				currencyDisplay: 'narrowSymbol',
				minimumIntegerDigits: 1,
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		}).format(centsValue / 100);
}
