import React from 'react';
import { getFormattedPrice, getCurrencySymbol } from '../helper/common';
import { CurrencyCode } from '../../constants/currencyConstants';
import styles from './MenuItems.module.scss';

export interface IMenuItemsWithBadgesProps {
	badges?: string[];
	itemName?: string;
	itemDescription?: string;
	itemPrice: number;
	itemImage?: string;
	itemImageSize: string;
	currency: CurrencyCode;
}

const MenuItemWithBadges: React.FC<IMenuItemsWithBadgesProps> = ({
	badges,
	itemName,
	itemDescription,
	itemPrice,
	itemImage,
	itemImageSize,
	currency,
	...props
}: IMenuItemsWithBadgesProps) => {
	return (
		<>
			<section className={styles.menuItems} {...props}>
				<div id="menuItemWithBadgesContainer" className={styles.itemDetail}>
					<div className={styles.badgeBlock}>
						{badges &&
							badges?.map((label) => {
								return (
									<span
										key={label}
										className={[styles.badge, `${styles[`badge${label}`]}`].join(' ')}
									>
										{label}
									</span>
								);
							})}
					</div>
					<h4 className={styles.itemTitle}>{itemName}</h4>
					<p className={styles.itemDesc}>{itemDescription}</p>
					{itemPrice ? (
						<p className={styles.itemPrice}>{getFormattedPrice(Number(itemPrice), currency)}</p>
					) : (
						<p className={styles.itemDefaultPrice}>{`${getCurrencySymbol(currency)}0.00`}</p>
					)}
				</div>
				{itemImage && <img src={itemImage} className={styles[`${itemImageSize}`]} alt={itemName} />}
			</section>
			<hr />
		</>
	);
};

export default MenuItemWithBadges;
