import React, { useContext, useState } from 'react';

import styles from './common.module.scss';
import ArrowDown from '../../../icons/ArrowUp';
import ArrowUp from '../../../icons/ArrowDown';
import NestedModifiers from '../NestedModifiers';
import { IItemsModifier } from './common';
import { getFormattedPrice } from '../../helper/common';
import MenuItemsPageContext, { IMenuItemContext } from '../../../Contexts/MenuItemsPageContext/MenuItemsPageContext';

interface IRadioButtonFieldProps {
	modifier: IItemsModifier;
	selectedModifierSets: string[];
	parentKey: string;
	handleModifiers(updateSelectedModifierSets: string[], selectedKey?: string, controlSelection?: string): void;
	isChildDisabled: boolean;
	modifierSetIndex: string;
}

const RadioButtonField: React.FC<IRadioButtonFieldProps> = ({
	modifier,
	selectedModifierSets,
	handleModifiers,
	isChildDisabled,
	parentKey,
	modifierSetIndex,
}: IRadioButtonFieldProps) => {
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const [expand, setExpand] = useState(false);
	const { modifierRef } = modifier;
	const modifierName = modifier.modifierDetails?.name;
	const modifierPrice = modifier.modifierDetails?.price;
	const modifierSets = modifier.modifierSets || [];
	const modifierKey = modifier.key;
	const isChecked = selectedModifierSets.filter((modifierData) => modifierData === modifierKey).length > 0;

	const handleExpandCollapse = () => {
		setExpand(!expand);
	};

	const handleChange = () => {
		setExpand(true);
		const updateModifierSets: string[] = selectedModifierSets?.length ? selectedModifierSets : [];
		handleModifiers([...updateModifierSets, modifierKey], modifierKey, 'selected');
	};

	return (
		<div className={isChildDisabled ? `${styles.modifierBlock} ${styles.modifierDisabled}` : styles.modifierBlock}>
			<div className={styles.selectionBlock}>
				<input
					type="radio"
					id={`${modifierRef}-${parentKey}`}
					name={`${modifierRef}-${parentKey}`}
					value={modifierRef}
					className={styles.radioButton}
					onChange={handleChange}
					checked={isChecked}
					disabled={isChildDisabled}
				/>
				{merchantData && (
					<div className={styles.labelBlock}>
						<label htmlFor={`${modifierRef}-${parentKey}`} className={styles.labelName}>
							{' '}
							{modifierName}{' '}
							{modifierPrice && modifierPrice !== 0
								? `+ ${getFormattedPrice(modifierPrice, merchantData.currency)}`
								: null}
						</label>
						{modifierSets?.length > 0 && (
							<button className={styles.cartIconStyle} onClick={() => handleExpandCollapse()}>
								{' '}
								{expand ? <ArrowDown /> : <ArrowUp />}{' '}
							</button>
						)}
					</div>
				)}
			</div>
			<div className={expand ? styles.modifierBody : styles.hideModifierBody}>
				{modifierSets?.length > 0 && (
					<NestedModifiers
						modifierSets={modifierSets}
						selectedModifierSets={selectedModifierSets}
						onHandleModifier={handleModifiers}
						isChildDisabled={false}
						modifierLastIndex={modifierSetIndex}
					/>
				)}
			</div>
		</div>
	);
};

export default RadioButtonField;
