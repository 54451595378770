import React from 'react';

import styles from './common.module.scss';
import { IItemsModifier } from './common';
import CheckBoxField from './CheckBoxField';

interface ICheckBoxSelectionProps {
	modifiers: IItemsModifier[];
	selectedModifierSets: string[];
	maxModifiersPerSet: number;
	parentKey: string;
	handleModifiers(updateSelectedModifierSets: string[], selectedKey?: string, controlSelection?: string): void;
	isChildDisabled: boolean;
	modifierSetIndex: string;
}

const CheckBoxSelection: React.FC<ICheckBoxSelectionProps> = ({
	modifiers,
	selectedModifierSets,
	maxModifiersPerSet,
	handleModifiers,
	parentKey,
	isChildDisabled,
	modifierSetIndex,
}: ICheckBoxSelectionProps) => {
	return (
		<>
			<div className={styles.mainBlock}>
				{modifiers?.length &&
					modifiers.map(
						(modifier: IItemsModifier) =>
							modifier?.modifierDetails?.available && (
								<CheckBoxField
									parentKey={parentKey}
									key={modifier.key}
									modifier={modifier}
									selectedModifierSets={selectedModifierSets}
									maxModifiersPerSet={maxModifiersPerSet}
									handleModifiers={(updateSelectedModifierSets, selectedKey, controlSelection) =>
										handleModifiers(updateSelectedModifierSets, selectedKey, controlSelection)
									}
									isChildDisabled={isChildDisabled}
									modifierSetIndex={modifierSetIndex}
								/>
							)
					)}
			</div>
		</>
	);
};

export default CheckBoxSelection;
