import styles from './Loader.module.scss';

interface ILoaderProps {
	loaderHeight?: string;
	loaderWidth?: string;
}

const getMenuItemWithBadge = () => {
	return (
		<div className={styles.container}>
			<div className={styles.linesContainer}>
				{' '}
				<div className={`${styles.lineShort} ${styles.placeHolderLoader}`}></div>
				<div className={`${styles.lineLong} ${styles.placeHolderLoader}`}></div>
				<div className={`${styles.lineMedium} ${styles.placeHolderLoader}`}></div>
			</div>
			<div>
				<div className={`${styles.imgLoader} ${styles.placeHolderLoader}`}></div>
			</div>
		</div>
	);
};

const getMenuItemWithOutBadge = () => {
	return (
		<div className={styles.container}>
			<div className={styles.linesContainer}>
				{' '}
				<div className={`${styles.lineShort} ${styles.placeHolderLoader}`}></div>
				<div className={`${styles.lineMedium} ${styles.placeHolderLoader}`}></div>
			</div>
			<div>
				<div className={`${styles.imgLoader} ${styles.placeHolderLoader}`}></div>
			</div>
		</div>
	);
};

const getMenuItemWithOutImage = () => {
	return (
		<div className={styles.container}>
			<div className={styles.linesContainer}>
				{' '}
				<div className={`${styles.lineShort} ${styles.marginBottom} ${styles.placeHolderLoader}`}></div>
				<div className={`${styles.lineLong}  ${styles.marginBottom} ${styles.placeHolderLoader}`}></div>
				<div className={`${styles.lineMedium} ${styles.marginBottom}  ${styles.placeHolderLoader}`}></div>
			</div>
		</div>
	);
};

export const Loader: React.FC<ILoaderProps> = () => {
	return (
		<>
			<div className={`${styles.imgHeader} ${styles.placeHolderLoader}`}></div>
			<div className={styles.linesContainer}>
				{' '}
				<div className={`${styles.lineShort} ${styles.marginBottom} ${styles.placeHolderLoader}`}></div>
				<div className={`${styles.lineMedium} ${styles.marginBottom} ${styles.placeHolderLoader}`}></div>
			</div>
			<div className={`${styles.menuLoader} ${styles.placeHolderLoader}`}></div>
			<div className={`${styles.navLoader} ${styles.placeHolderLoader}`}></div>
			{getMenuItemWithBadge()}
			{getMenuItemWithBadge()}
			{getMenuItemWithOutBadge()}
			{getMenuItemWithOutImage()}
			{getMenuItemWithBadge()}
			{getMenuItemWithBadge()}
			{getMenuItemWithOutBadge()}
			{getMenuItemWithOutImage()}
			{getMenuItemWithBadge()}
			{getMenuItemWithBadge()}
			{getMenuItemWithOutBadge()}
			{getMenuItemWithOutImage()}
			{getMenuItemWithBadge()}
			{getMenuItemWithOutBadge()}
			{getMenuItemWithOutImage()}
			{getMenuItemWithBadge()}
			{getMenuItemWithOutBadge()}
			{getMenuItemWithOutImage()}
		</>
	);
};
