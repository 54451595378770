import React from 'react';

const ArrowDown: React.FC = () => {
	return (
		<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.496095 8.84375L1.16406 9.54687C1.33985 9.72265 1.6211 9.72265 1.76172 9.54687L8.125 3.18359L14.5234 9.54687C14.6641 9.72266 14.9453 9.72266 15.1211 9.54687L15.7891 8.84375C15.9648 8.70312 15.9648 8.42187 15.7891 8.24609L8.44141 0.898437C8.26563 0.722656 8.01953 0.722656 7.84375 0.898437L0.496095 8.24609C0.320314 8.42187 0.320314 8.70312 0.496095 8.84375Z"
				fill="black"
			/>
		</svg>
	);
};

export default ArrowDown;
