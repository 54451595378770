import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { getFormattedPrice, getCurrencySymbol } from '../helper/common';
import cancelIcon from '../../../assets/cancelIcon.svg';

import styles from './ItemMinifiedHeader.module.scss';
import { CurrencyCode } from '../../constants/currencyConstants';

interface IItemMinifiedHeaderProps {
	name: string;
	imageUrl?: string;
	price?: number;
	description?: string;
	storeId: string;
	id: string;
	imgDisplay: string;
	isPageScrolled: boolean;
	currency: CurrencyCode;
}

const ItemMinifiedHeader: React.FC<IItemMinifiedHeaderProps> = ({
	name,
	imageUrl,
	price,
	description,
	storeId,
	id,
	imgDisplay,
	isPageScrolled,
	currency,
}: IItemMinifiedHeaderProps) => {
	const history = useHistory();
	const tableRefId = localStorage.getItem('tableRef');

	const handleCloseClick = () => {
		localStorage.removeItem('itemDetails');
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	return (
		<div
			id={id}
			className={
				imgDisplay === 'image' ? styles.itemMinifiedHeaderMainBlock : styles.itemMinifiedHeaderMainBlockNoImage
			}
			style={isPageScrolled ? { visibility: 'visible' } : { visibility: 'hidden' }}
		>
			<div className={isPageScrolled ? styles.itemMiniHeader : styles.itemMiniHeaderHidden}>
				{imageUrl && (
					<div className={styles.itemImageBlock}>
						<img src={imageUrl} className={styles.itemImage} alt={name} />
					</div>
				)}
				<div
					className={
						imageUrl && description
							? styles.itemInformationBlock
							: description?.length !== undefined
							? styles.itemInformationBlockWithoutImageWithDescription
							: styles.itemInformationBlockWithoutImage
					}
				>
					<div className={styles.itemTitle}>{name}</div>
					<div className={styles.itemDescription}>{description}</div>
					{price ? (
						<div className={styles.itemPrice}>{getFormattedPrice(price, currency)}</div>
					) : (
						<div className={styles.itemPrice}>{`${getCurrencySymbol(currency)}0.00`}</div>
					)}
				</div>
				<Button icon className="float right closeLink" onClick={handleCloseClick}>
					<img src={cancelIcon} className={styles.closeButton} alt="close" />
				</Button>
			</div>
		</div>
	);
};

export default ItemMinifiedHeader;
