import React, { useState } from 'react';

import AppContext from './AppContext';
import { postUserData, fetchSession, assignTicketSession } from '../../services/AppSessionServices';
import { ICustomerData, ITickets } from '../../pages/customerdetail/models/customerDataModels';

interface IProps {
	children?: React.ReactNode;
}

const AppContextProvider: React.FC<IProps> = ({ children }) => {
	const [isValidSession, setIsValidSession] = useState<boolean>(false);
	const [validSessionId, setValidSessionId] = useState<string>('');
	const [ticketRefDetail, setTicketRefDetail] = useState<ITickets>();
	const [isClientTokenSet, setIsClientTokenSet] = useState<boolean>(false);
	const [sessionErrorStatus, setSessionErrorStatus] = useState<number>(0);
	const [errorMessage, setErrorMessage] = useState<string | undefined>('');
	const [showLoaderIcon, setShowLoaderIcon] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [itemScrollPosition, setItemScrollPosition] = useState<number>(0);
	const [tempSessionId, setTempSessionId] = useState<string>('');

	const startSession = async (locationId: string, userData: ICustomerData) => {
		setShowLoaderIcon(true);
		const { clientToken, sessionId } = await postUserData(locationId, userData);
		if (sessionId) {
			setTempSessionId(sessionId);
		}
		if (clientToken) {
			localStorage.setItem('clientToken', clientToken);
			setIsClientTokenSet(true);
			setShowLoaderIcon(false);
		} else {
			localStorage.removeItem('clientToken');
			setIsClientTokenSet(false);
			setShowLoaderIcon(false);
		}
	};

	const checkSessionData = async (locationId: string, sessionIdData: string, code: string, tableRef: string) => {
		const clientToken = localStorage.getItem('clientToken');
		const smsCode = localStorage.getItem('clientCode') || code;
		if (clientToken && smsCode) {
			setShowLoaderIcon(true);
			const response: ITickets = await fetchSession(locationId, sessionIdData, clientToken, code, tableRef);
			if (response?.error) {
				localStorage.removeItem('storedSessionId');
				localStorage.removeItem('clientCode');
				setIsValidSession(false);
				setSessionErrorStatus(response.error.status);
				setValidSessionId('');
			} else {
				localStorage.setItem('storedSessionId', sessionIdData);
				localStorage.setItem('clientCode', code);
				localStorage.setItem('ticketRefDetail', JSON.stringify(response));
				setSessionErrorStatus(0);
				setTicketRefDetail(response);
				setIsValidSession(true);
				setValidSessionId(sessionIdData);
			}
			setShowLoaderIcon(false);
		}
	};

	const assignTicketToSession = async (
		locationId: string,
		sessionIdDetail: string,
		ticketRef: string,
		tableRefId: string
	) => {
		setShowLoaderIcon(true);
		const resultData = await assignTicketSession(locationId, sessionIdDetail, ticketRef, tableRefId);
		if (resultData?.error?.status === 418) {
			setTicketRefDetail(resultData);
			localStorage.setItem('ticketRefDetail', JSON.stringify(resultData));
		} else if (resultData?.error && [500, 400, 404].includes(resultData.error.status)) {
			setErrorMessage(resultData?.error?.message);
		} else {
			setTicketRefDetail({});
			localStorage.setItem('ticketRefDetail', '{}');
		}
		setShowLoaderIcon(false);
	};

	const updateToastMessage = (toastError: string) => {
		setToastMessage(toastError);
		setTimeout(() => setToastMessage(''), 3000);
	};

	const value = {
		isValidSession,
		setIsValidSession,
		startSession,
		checkSessionData,
		ticketRefDetail,
		isClientTokenSet,
		setIsClientTokenSet,
		validSessionId,
		sessionErrorStatus,
		setSessionErrorStatus,
		setValidSessionId,
		assignTicketToSession,
		errorMessage,
		setErrorMessage,
		setTicketRefDetail,
		showLoaderIcon,
		setShowLoaderIcon,
		updateToastMessage,
		toastMessage,
		itemScrollPosition,
		setItemScrollPosition,
		tempSessionId,
	};

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
