import React from 'react';
import { getCurrencySymbol, getFormattedPrice } from '../helper/common';
import { CurrencyCode } from '../../constants/currencyConstants';
import styles from '../menuitemswithbadges/MenuItems.module.scss';

export interface IMenuItemsWithoutBadgesProps {
	itemName?: string;
	itemDescription?: string;
	itemPrice: number;
	itemImage?: string;
	itemImageSize: string;
	currency: CurrencyCode;
}

const MenuItemWithoutBadges: React.FC<IMenuItemsWithoutBadgesProps> = ({
	itemName,
	itemDescription,
	itemPrice,
	itemImage,
	itemImageSize,
	currency,
	...props
}: IMenuItemsWithoutBadgesProps) => {
	return (
		<>
			<section className={styles.menuItems} {...props}>
				<div id="menuItemWithoutBadgesContainer" className={styles.itemDetail}>
					<h4 className={styles.itemTitle}>{itemName}</h4>
					<p className={styles.itemDesc}>{itemDescription}</p>
					{itemPrice ? (
						<p className={styles.itemPrice}>{getFormattedPrice(Number(itemPrice), currency)}</p>
					) : (
						<p className={styles.itemDefaultPrice}>{`${getCurrencySymbol(currency)}0.00`}</p>
					)}
				</div>
				{itemImage && <img src={itemImage} className={styles[`${itemImageSize}`]} alt={itemName} />}
			</section>
			<hr />
		</>
	);
};

export default MenuItemWithoutBadges;
