import React, { useEffect, useState } from 'react';
import CheckBoxSelection from './CheckBoxSelection';
import RadioButtonSelection from './RadioButtonSelection';
import styles from './common.module.scss';
import { messageToChoose, IItemsModifierSet, IItemsModifier } from './common';
import RequiredModifier from './RequiredModifiers';

export interface IModifierProps {
	modifierSet: IItemsModifierSet;
	selectedModifierSets: string[];
	handleModifiers(updateSelectedModifierSets: string[], selectedKey?: string, controlSelection?: string): void;
	isChildDisabled: boolean;
	modifierCurrentIndex: number;
	modifierLastIndex: string;
}

const Modifier: React.FC<IModifierProps> = ({
	modifierSet,
	selectedModifierSets,
	handleModifiers,
	isChildDisabled,
	modifierCurrentIndex,
	modifierLastIndex,
}) => {
	const MINIFIED_HEADER_DEFAULT_HEIGHT = 122;
	const NAV_BAR_DEFAULT_HEIGHT = 47;
	const Z_INDEX_MAX_VALUE = 20;
	const modifierSetDetails = modifierSet?.modifierSetDetails;
	const modifiers = modifierSet?.modifiers;
	const [miniHeaderHeight, setMiniHeaderHeight] = useState<number>(122);
	const qtyModifierAvailable: number =
		(modifiers?.length &&
			modifiers.filter((modifierData: IItemsModifier) => {
				return modifierData?.modifierDetails?.available;
			}).length) ||
		0;
	const navBarHeight = document.getElementById('navigationSection')?.offsetHeight || NAV_BAR_DEFAULT_HEIGHT;
	const minifiedheader = document.getElementById('miniHeader')?.offsetHeight || MINIFIED_HEADER_DEFAULT_HEIGHT;
	const modifierTopheight = miniHeaderHeight + navBarHeight;
	const modiferLevel = modifierLastIndex.split('-').length;

	useEffect(() => {
		const modifierParentHeight = document.getElementById(`modiferLevel-${modifierLastIndex}`)?.offsetHeight;
		if (modifierParentHeight) {
			setMiniHeaderHeight(minifiedheader + modifierParentHeight);
		} else {
			setMiniHeaderHeight(minifiedheader);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minifiedheader]);

	return (
		<div id={modifierSet.key} className={styles.modifierMainBlock}>
			<div
				className={styles.modifierHeader}
				id={`modiferLevel-${modifierLastIndex}-${modifierCurrentIndex}`}
				style={{ top: modifierTopheight, zIndex: Z_INDEX_MAX_VALUE - modiferLevel }}
			>
				<div className={styles.modifierHeaderLeftBlock}>
					<div className={styles.modifierName}>{modifierSetDetails?.name}</div>
					<div className={styles.modifierHeaderRightBlock}>
						{modifierSet.hasModifiers && (
							<div className={styles.modifierChoice}>
								{messageToChoose(
									modifierSetDetails?.minModifiersPerSet || 0,
									modifierSetDetails?.maxModifiersPerSet || 0,
									qtyModifierAvailable
								)}
							</div>
						)}
					</div>
				</div>
				{modifierSet.hasModifiers && (
					<RequiredModifier
						minimumModifiers={modifierSetDetails?.minModifiersPerSet || 0}
						maximumModifier={modifierSetDetails?.maxModifiersPerSet || 0}
						totalModifierAvailable={qtyModifierAvailable}
						modifiersArray={modifierSet.modifiers}
						selectedModifierSets={selectedModifierSets}
					/>
				)}
			</div>
			{!modifierSet.hasModifiers && (
				<div className={styles.modifierBody} id="modifierMsgContainer">
					<div className={`${styles.modifierHeader} ${styles.modifierUnavailable}`}>
						Modifiers are not available
					</div>
				</div>
			)}
			<div className={styles.modifierBody}>
				{modifierSetDetails?.maxModifiersPerSet &&
				(modifierSetDetails?.maxModifiersPerSet > 1 || !modifierSetDetails?.minModifiersPerSet) ? (
					<CheckBoxSelection
						modifiers={modifiers || []}
						selectedModifierSets={selectedModifierSets}
						maxModifiersPerSet={modifierSetDetails?.maxModifiersPerSet}
						handleModifiers={handleModifiers}
						parentKey={modifierSet.key}
						isChildDisabled={isChildDisabled}
						modifierSetIndex={`${modifierLastIndex}-${modifierCurrentIndex}`}
					/>
				) : (
					<RadioButtonSelection
						modifiers={modifiers || []}
						selectedModifierSets={selectedModifierSets}
						handleModifiers={handleModifiers}
						isChildDisabled={isChildDisabled}
						parentKey={modifierSet.key}
						modifierSetIndex={`${modifierLastIndex}-${modifierCurrentIndex}`}
					/>
				)}
			</div>
		</div>
	);
};

export default Modifier;
