import shift4Logo from '../../../assets/shift4Logo.svg';
import styles from './PreAuthenticationDetails.module.scss';

interface IPreAuthFooterProps {
	defaultTipPercentageValue: number | boolean | undefined;
}

const PreAuthFooter: React.FC<IPreAuthFooterProps> = ({ defaultTipPercentageValue }) => {
	return (
		<div className={styles.preAuthFooter} id="preAuthFooter">
			<p className={styles.subText}>
				Clicking continue means you agree to be charged for the ticket total{' '}
				{defaultTipPercentageValue ? `(plus ${defaultTipPercentageValue}% tip) ` : ''} if you leave without
				closing your tab. Thank you for helping us provide the best service. If you have questions, please ask
				our staff.
			</p>
			<div className={styles.logoContainer}>
				<img src={shift4Logo} alt="shift4 logo" />
			</div>
		</div>
	);
};

export default PreAuthFooter;
