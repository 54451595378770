import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ItemHeader from '../../components/itemheader/ItemHeader';
import ItemMinifiedHeader from '../../components/itemminifiedheader/ItemMinifiedHeader';
import SpecialInstruction from '../../components/specialinstruction/SpecialInstruction';
import Footer from '../../components/footer/Footer';
import Toast from '../../components/toast/Toast';
import ItemOrderPageContext, { IItemOrderContext } from '../../Contexts/ItemOrderPageContext/ItemOrderPageContext';
import { SPECIAL_INSTRUCTION, ADD_TO_ORDER, PLEASE_COMPLETE_REQUIRED_SECTIONS_TO_CONTINUE } from './labels';
import NestedModifiers from '../../components/nestedmodifiers/NestedModifiers';
import {
	updateModifiersSetDetails,
	calculateItemTotalPriceWithSelectedKeys,
	updateModifierSetsAsPerOrderApi,
	validateSelectedNestedModifierSets,
	convertSelectedKeysToModifierSetsFormat,
	filterSelectedKeys,
} from '../../utils/itemOrderUtils';
import { IItemsModifierSet } from '../../components/nestedmodifiers/components/common';
import { IOrderItem, IValidateNestedModifierSet } from '../home/models/menuItemModels';
import styles from './ItemOrder.module.scss';
import {
	FIRST_CATEGORY_NAVIGATION_ALIGN,
	HEADER_DEFAULT_HEIGHT,
	MINIFIED_HEADER_DEFAULT_HEIGHT,
	NAVIGATION_MARGIN,
	SCROLL_MODIFIER_BUFFER_OFFSET,
	SPECIAL_INSTRUCTION_BUFFER_PADDING,
	SPECIAL_INSTRUCTION_HEIGHT,
	SPECIAL_INSTRUCTION_SCROLL_BUFFER,
} from '../../constants/constants';
import AppContext, { IAppContext } from '../../Contexts/AppContext/AppContext';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';

interface IProps {
	match: {
		params: {
			storeId: string;
			itemRef: string;
		};
	};
}

const ItemOrder: React.FC<IProps> = (props) => {
	const history = useHistory();
	const [isPageScrolled, setIsPageScrolled] = useState<boolean>(false);
	const [itemTotalPrice, setItemTotalPrice] = useState<number>();
	const [selectedModifier, setSelectedModifier] = useState<string>();
	const [specialInstructionText, setSpecialInstructionText] = useState<string>('');
	const [paddingBelowSpecialInstruction, setPaddingBelowSpecialInstruction] = useState<string>('auto');
	const [quantityValue, setQuantityValue] = useState<number>(1);
	const [selectedModifierSets, setSelectedModifierSets] = useState<IItemsModifierSet[]>([]);
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
	const [categoryNavXPosition, setCategoryNavXPosition] = useState<number>(NAVIGATION_MARGIN);
	const [itemNavDetails, setItemNavDetails] = useState<IItemsModifierSet[]>([]);
	const { itemDetails, setSelectedItemDetails, setItemDetails } = useContext(
		ItemOrderPageContext
	) as IItemOrderContext;
	const { toastMessage, updateToastMessage } = useContext(AppContext) as IAppContext;
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const [miniHeaderHeight, setMiniHeaderHeight] = useState<number>(MINIFIED_HEADER_DEFAULT_HEIGHT);

	const { storeId, itemRef } = props.match.params;
	const modifiersData = localStorage.getItem('modifiersData');
	const pendingItemsInCart = localStorage.getItem('cartItems') || '{"totalPrice":0,"items":[]}';
	const modifierSection = document.getElementById('modifierSection');
	const miniHeaderElement = document.getElementById('miniHeader')?.offsetHeight || HEADER_DEFAULT_HEIGHT;
	const storedItemDetails = localStorage.getItem('itemDetails');
	const tableRefId = localStorage.getItem('tableRef');

	useEffect(() => {
		if (itemRef && !storedItemDetails) {
			setSelectedItemDetails(itemRef);
		}
		updateToastMessage('');
		//making the scroll to top on each page load
		const scrollToTop = () => {
			document.documentElement.scrollTop = 0;
		};
		requestAnimationFrame(scrollToTop);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (itemDetails && Object.keys(itemDetails).length > 0) {
			localStorage.setItem('itemDetails', JSON.stringify(itemDetails));
		} else if (storedItemDetails) {
			setItemDetails(JSON.parse(storedItemDetails));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemDetails]);

	useEffect(() => {
		setItemTotalPrice(itemDetails?.price);
		if (itemDetails && itemDetails.modifierSets && itemDetails.modifierSets.length > 0) {
			const itemDetailsSet: IItemsModifierSet[] = [];
			//Removing unavailable menu items
			itemDetails?.modifierSets.forEach((item) => {
				if (item.modifierSetDetails?.available) {
					itemDetailsSet.push(item);
				}
			});
			setItemNavDetails(itemDetailsSet);
			setSelectedModifier(itemDetailsSet[0]?.key);
		}
		setPaddingBelowSpecialInstruction(`calc(100vh - ${SPECIAL_INSTRUCTION_BUFFER_PADDING}px)`);
	}, [itemDetails]);

	useEffect(() => {
		const selectedItem = selectedModifier;
		//Horizontal scroll when the hidden Nav heading got highlighted
		const navigationCategory = document.getElementById(`nav-${selectedItem}`);
		if (selectedItem && navigationCategory) {
			const navigationCategoryHorizontalPosition = navigationCategory.getBoundingClientRect().x;
			const navigationCategoryWidth = navigationCategory.getBoundingClientRect().width;
			if (navigationCategoryHorizontalPosition && navigationCategoryWidth) {
				const categoryScrollLeftX =
					navigationCategoryHorizontalPosition +
					navigationCategoryWidth +
					categoryNavXPosition -
					screen.width;
				if (categoryScrollLeftX > 0 || categoryScrollLeftX < FIRST_CATEGORY_NAVIGATION_ALIGN) {
					document.getElementsByTagName('ul')[0].scrollLeft = categoryScrollLeftX;
				}
				if (categoryScrollLeftX > 0) {
					setCategoryNavXPosition(categoryScrollLeftX + NAVIGATION_MARGIN);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedModifier]);

	const listenToScroll = () => {
		const winScroll = document.documentElement.scrollTop;
		let currentSection = 0;
		const headerElement = document.getElementById('miniHeader');
		const navSection = document.getElementById('navigationSection');
		const headerElementYPos = headerElement && headerElement.getBoundingClientRect().height;
		const navSectionYPos = navSection && navSection.getBoundingClientRect().height;
		const categoryListArray = itemNavDetails.map((modifierSet) => modifierSet.key);
		categoryListArray?.map((section: string, index: number) => {
			const element = document.getElementById(section);
			const sectionTop = element?.offsetTop;
			const elementHeight = element?.clientHeight;
			if (
				sectionTop &&
				elementHeight &&
				headerElementYPos &&
				navSectionYPos &&
				winScroll >= sectionTop + elementHeight - (headerElementYPos + navSectionYPos)
			) {
				currentSection = index + 1;
			}
		});
		setSelectedModifier(categoryListArray?.[currentSection]);
		const mainHeaderHeight =
			(document.getElementById('mainHeader')?.offsetHeight || HEADER_DEFAULT_HEIGHT) -
			MINIFIED_HEADER_DEFAULT_HEIGHT;
		if (winScroll > mainHeaderHeight) {
			setIsPageScrolled(true);
		} else {
			setIsPageScrolled(false);
		}
	};

	useEffect(() => {
		const miniheader = document.getElementById('miniHeader')?.offsetHeight || MINIFIED_HEADER_DEFAULT_HEIGHT;
		setMiniHeaderHeight(miniheader);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPageScrolled]);

	useEffect(() => {
		window.addEventListener('scroll', listenToScroll);
		return () => {
			window.removeEventListener('scroll', listenToScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemNavDetails]);

	// called when we click on modifier and scroll to respective modifier
	const handleModifierSetClick = (modifierSetKey: string) => {
		const headerElement = document.getElementById('miniHeader');
		const navSection = document.getElementById('navigationSection');
		const modifierSetKeyElement = document.getElementById(modifierSetKey);
		const headerElementYPos = headerElement && headerElement.getBoundingClientRect().height;
		const navSectionYPos = navSection && navSection.getBoundingClientRect().height;
		if (modifierSetKeyElement && headerElementYPos && navSectionYPos) {
			window.scrollTo(
				0,
				modifierSetKeyElement.offsetTop + SCROLL_MODIFIER_BUFFER_OFFSET - (headerElementYPos + navSectionYPos)
			);
		}
	};

	// end react transition group between header and minified header
	const handleSpecialInfoChange = (value: string) => {
		setSpecialInstructionText(value);
	};

	const handleAddToOrder = (totalPrice: number, quantity: number) => {
		let updateOrderItems: IOrderItem[] = [];
		if (itemDetails?.modifierSets?.length) {
			const validateDetails: IValidateNestedModifierSet[] = validateSelectedNestedModifierSets(
				itemDetails?.modifierSets,
				selectedModifierSets
			);
			if (validateDetails?.length && validateDetails[0].modifierSetName) {
				handleModifierSetClick(validateDetails[0].modifierSetKey);
				updateToastMessage(PLEASE_COMPLETE_REQUIRED_SECTIONS_TO_CONTINUE);
			} else {
				const updateModifierSets = updateModifierSetsAsPerOrderApi(selectedModifierSets);
				updateOrderItems = [
					{
						menuItemGuid: itemDetails?.ref,
						specialRequest: specialInstructionText,
						quantity: quantity,
						modSets: updateModifierSets,
						itemName: itemDetails?.name,
						itemPrice: itemDetails?.price,
					},
				];
				handleRedirectToHomePage(updateOrderItems, totalPrice);
			}
		} else {
			updateOrderItems = [
				{
					menuItemGuid: itemDetails?.ref,
					specialRequest: specialInstructionText,
					quantity: quantity,
					modSets: [],
					itemName: itemDetails?.name,
					itemPrice: itemDetails?.price,
				},
			];
			handleRedirectToHomePage(updateOrderItems, totalPrice);
		}
	};

	const handleRedirectToHomePage = (updateOrderItems: IOrderItem[], totalPrice: number) => {
		if (pendingItemsInCart) {
			const pendingItem = JSON.parse(pendingItemsInCart);
			pendingItem.totalPrice = pendingItem.totalPrice + totalPrice;
			pendingItem.items = pendingItem.items.concat(updateOrderItems);
			localStorage.setItem('cartItems', JSON.stringify(pendingItem));
			history.push(`/${storeId}?tableRef=${tableRefId}`);
			localStorage.removeItem(`itemDetails`);
		}
	};

	/**
	 *
	 * @param updateSelectedModifierSets
	 * @param selectedKey
	 * @param controlSelection
	 * to set selected Modifier keys and finally convert to modifierSet Format (IItemModifierSet)
	 * copy/verify a selection/deselection of a nested modifier (control selection) two levels up or down the tree
	 */
	const handleModifier = (updateSelectedModifierSets: string[], selectedKey: string, controlSelection: string) => {
		const finalSelectedModifierKeys = filterSelectedKeys(
			updateSelectedModifierSets,
			selectedKey,
			controlSelection,
			selectedKeys,
			itemDetails?.modifierSets || []
		);
		let totalPrice: number = itemDetails?.price || 0;
		totalPrice = calculateItemTotalPriceWithSelectedKeys(
			itemDetails?.modifierSets || [],
			totalPrice,
			finalSelectedModifierKeys
		);
		// Selected Modifiers with unique keys storing in selectedKeys
		setSelectedKeys(finalSelectedModifierKeys);
		setItemTotalPrice(totalPrice);
		// Converting selectedKeys into IItemModifierSet[]
		setSelectedModifierSets(
			convertSelectedKeysToModifierSetsFormat(itemDetails?.modifierSets || [], finalSelectedModifierKeys)
		);
	};
	const sectionHeightValue = modifierSection && modifierSection.clientHeight + SPECIAL_INSTRUCTION_HEIGHT;

	return (
		<>
			{itemDetails && merchantData && (
				<>
					<div id="headerSection">
						<div
							className={styles.fullWidth}
							style={{
								zIndex: 1,
							}}
						>
							<ItemHeader
								id="headerLogo"
								name={itemDetails.name}
								imageUrl={itemDetails.imageUrl800x800}
								price={itemDetails.price}
								description={itemDetails.description}
								customLabels={[...itemDetails.customLabels, ...itemDetails.tags]}
								storeId={storeId}
								currency={merchantData?.currency}
							/>
						</div>
					</div>
					{itemDetails.imageUrl800x800 || itemDetails?.description?.length !== undefined ? (
						<ItemMinifiedHeader
							id="miniHeader"
							name={itemDetails?.name}
							imageUrl={itemDetails.imageUrl800x800}
							price={itemDetails.price}
							description={itemDetails.description}
							storeId={storeId}
							imgDisplay={`image`}
							isPageScrolled={isPageScrolled}
							currency={merchantData?.currency}
						/>
					) : (
						<ItemMinifiedHeader
							id="miniHeader"
							name={itemDetails?.name}
							imageUrl={itemDetails.imageUrl800x800}
							price={itemDetails.price}
							description={itemDetails.description}
							storeId={storeId}
							imgDisplay={`without-image`}
							isPageScrolled={isPageScrolled}
							currency={merchantData?.currency}
						/>
					)}
				</>
			)}

			{itemDetails?.modifierSets?.length ? (
				<nav
					key={itemDetails?.ref}
					className={
						itemDetails?.description
							? `${styles.modifierNav} ${styles.modifierNavWithImage}`
							: `${styles.modifierNav} ${styles.modifierNavWithoutImage}`
					}
					id="navigationSection"
					style={{ top: miniHeaderHeight }}
				>
					<ul key={`ul-${itemDetails?.ref}`} className={styles.scrollingNav}>
						{itemNavDetails.map(
							(modifierSet: IItemsModifierSet, index: number) =>
								!(
									!modifierSet.modifierSetDetails?.minModifiersPerSet &&
									!modifierSet.modifierSetDetails?.maxModifiersPerSet
								) &&
								modifierSet.hasModifiers && (
									<button
										id={`nav-${modifierSet.key}`}
										key={index}
										className={
											modifierSet.key === selectedModifier
												? modifierSet.modifierSetDetails?.name?.length > 30
													? `${styles.active} ${styles.modifierEllipsis}`
													: styles.active
												: modifierSet.modifierSetDetails?.name?.length > 30
												? `${styles.modifierName} ${styles.modifierEllipsis}`
												: styles.modifierName
										}
										onClick={() => handleModifierSetClick(modifierSet.key || '')}
									>
										{modifierSet.modifierSetDetails?.name}
									</button>
								)
						)}
					</ul>
				</nav>
			) : null}
			<div
				id="modifierScrollSpy"
				className={styles.modifierScrollSpy}
				style={{
					height: sectionHeightValue ? sectionHeightValue : 'auto',
				}}
			>
				{modifiersData && itemDetails?.modifierSets?.length ? (
					<section id="modifierSection">
						<NestedModifiers
							modifierSets={updateModifiersSetDetails(
								itemDetails?.modifierSets,
								JSON.parse(modifiersData)
							)}
							selectedModifierSets={selectedKeys}
							onHandleModifier={handleModifier}
							isChildDisabled={false}
							modifierLastIndex={'0'}
						/>
					</section>
				) : null}
				<SpecialInstruction
					externalStyle={{
						paddingBottom:
							modifierSection && modifierSection.children.length > 0
								? paddingBelowSpecialInstruction
								: `calc(100vh - ${miniHeaderElement + SPECIAL_INSTRUCTION_SCROLL_BUFFER}px)`,
					}}
					text={specialInstructionText}
					label={SPECIAL_INSTRUCTION}
					handleChange={handleSpecialInfoChange}
				/>
			</div>
			{toastMessage?.length > 0 && <Toast message={toastMessage} />}

			{itemTotalPrice != undefined && itemTotalPrice >= 0 ? (
				<Footer
					externalStyle={''}
					id={ADD_TO_ORDER}
					key={itemTotalPrice}
					labelText={ADD_TO_ORDER}
					itemPrice={itemTotalPrice}
					showQuantityDetail={true}
					addToOrderInner={handleAddToOrder}
					buttonColor="Red"
					quantityValue={quantityValue}
					updateQuantityValue={(quantity: number) => setQuantityValue(quantity)}
				/>
			) : null}
		</>
	);
};

export default ItemOrder;
