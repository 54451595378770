import React, { useEffect, useState } from 'react';
import styles from './Toast.module.scss';

interface IToastProps {
	message: string;
	handleCloseToast?(): void;
}

const Toast: React.FC<IToastProps> = ({ message, handleCloseToast, ...props }: IToastProps) => {
	const [showToast, setShowToast] = useState(false);

	useEffect(() => {
		setShowToast(true);
	}, []);

	const handleClose = () => {
		if (handleCloseToast) {
			handleCloseToast();
		}
	};

	return (
		<>
			<div
				className={
					showToast
						? `${styles.notificationContainer} ${styles.notificationUp}`
						: `${styles.notificationContainer} ${styles.notificationDown}`
				}
				{...props}
				onMouseDown={() => handleClose()}
				role="button"
				tabIndex={0}
			>
				<div className={styles.notification}>
					<p className={styles.notificationMessage}>{message}</p>
				</div>
			</div>
		</>
	);
};

export default Toast;
