import { getFormattedTime } from './menuItemUtils';

/**
 * This below function converts and returns ISO UTC to date time type.
 * @param {string} utcTime - ISO UTC time.
 * @param {string} locale - It represents locale for the specific region(i.e "en-US" for US region and "en-GB" for UK region) .
 * @returns {string} - returns the converted time
 */
export const convertUtcToTime = (utcTime: string, locale: undefined | string[]): string => {
	const hour = new Date(utcTime).getHours();
	const minutes = new Date(utcTime).getMinutes();
	const seconds = new Date(utcTime).getSeconds();
	const hms = `${hour}:${minutes}:${seconds}`;
	return getFormattedTime(hms, locale);
};
