import { createContext } from 'react';
import { IErrorResponse } from '../../pages/viewTickets/models/ticketDetailsModels';

export interface IOrderConfirmContext {
	getQRPayHost: (locationId: string, sessionId: string) => void;
	payRedirectError: IErrorResponse | undefined;
	setPayRedirectError: (payRedirectError: IErrorResponse | undefined) => void;
	showLoaderIcon: boolean;
	setShowLoaderIcon: (showLoaderIcon: boolean) => void;
}

export const OrderConfirmPageContext: React.Context<IOrderConfirmContext | null> =
	createContext<IOrderConfirmContext | null>(null);
export default OrderConfirmPageContext;
