import { useContext, useEffect } from 'react';
import { I4goIdResponse, IAccessDetailsResponse } from '../../pages/preAuthenticationDetail/models/PreAuthModel';
import styles from './CreditCardI4GoIFrame.module.scss';
import { cssRules } from './CreditCardI4GoIFramestyle';
import PreAuthPageContext, { IPreAuthItemContext } from '../../Contexts/PreAuthPageContext/PreAuthPageContext';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';
import { ZIP_CODE_COUNTRY_CODES } from '../../constants/constants';

declare global {
	interface JQuery {
		i4goTrueToken: (settings: unknown) => void;
	}
}

interface IProps {
	onSuccess: (data: I4goIdResponse) => void;
	onFailure: (data: I4goIdResponse) => void;
	accessDetails: IAccessDetailsResponse;
	i4goLoader: boolean;
	setI4goLoader: (i4goLoader: boolean) => void;
	setIsConsentModalVisible: (isConsentModalVisible: boolean) => void;
}

const FOOTER__OFFSET_TOP_THRESHOLD = 530;

const CreditCardI4GoIFrame: React.FC<IProps> = (props) => {
	const isDebugEnabled = process.env.REACT_APP_ENVIRONMENT === 'development';

	const { i4goLoader, setI4goLoader, setIsConsentModalVisible } = props;
	const { setAccessDetails } = useContext(PreAuthPageContext) as IPreAuthItemContext;
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const countryCode = (merchantData && merchantData?.countryCode) || 'US';

	useEffect(() => {
		const interval = setInterval(() => {
			const preAuthFooterPosition = document.getElementById('preAuthFooter')?.offsetTop || 0;
			if (preAuthFooterPosition > FOOTER__OFFSET_TOP_THRESHOLD) {
				clearInterval(interval);
				setI4goLoader(false);
				setIsConsentModalVisible(true);
				setAccessDetails({});
			}
		}, 500);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		$('#i4goFrame').i4goTrueToken({
			server: props.accessDetails?.server,
			accessBlock: props.accessDetails?.accessBlock,
			url: process.env.I4GO_HOST,
			self: document.location.href,
			template: 'plain',
			onSuccess: (_form: unknown, data: I4goIdResponse) => {
				return props.onSuccess(data);
			},
			onFailure: (_form: unknown, data: I4goIdResponse) => {
				return props.onFailure(data);
			},
			cardType: {
				classes: '',
				label: '',
				placeholder: '',
				message: '',
				visible: false,
			},
			cardholderName: {
				classes: 'cardHolderName',
				label: 'Your Name',
				placeholder: 'Your Name',
				message: '',
				visible: true,
				required: true,
			},
			cardNumber: {
				classes: 'cardNumber',
				label: '',
				placeholder: '16 digit number',
				message: '',
				visible: true,
				required: true,
			},
			cvv2Code: {
				classes: '',
				label: '',
				placeholder: 'Enter the 3 or 4-digit security code',
				message: '',
				visible: true,
				required: true,
			},
			postalCode: {
				classes: '',
				label: 'Zip Code',
				placeholder: 'Enter zip code',
				message: 'Invalid zip code',
				visible: countryCode === ZIP_CODE_COUNTRY_CODES,
				required: countryCode === ZIP_CODE_COUNTRY_CODES,
			},
			streetAddress: {
				classes: '',
				label: '',
				placeholder: '',
				message: '',
				visible: false,
				required: false,
			},
			submitButton: {
				label: 'Continue',
			},
			cssRules: [cssRules],
			debug: isDebugEnabled,
			remoteDebug: isDebugEnabled,
		});
	});

	return (
		<>
			<div id="i4goFrame" className={styles.i4goFrame}></div>
			{i4goLoader && (
				<div className={styles.i4goLoaderContainer}>
					<div className={styles.loader}></div>
				</div>
			)}
		</>
	);
};

export default CreditCardI4GoIFrame;
