import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AppContext, { IAppContext } from '../../Contexts/AppContext/AppContext';
import ticketDetailstyles from './SelectTicketView.module.scss';
import Toast from '../../components/toast/Toast';
import { convertUtcToTime } from '../../utils/selectTicketView';

import { Button, Divider } from 'semantic-ui-react';
import warning from '../../../assets/warning.svg';
import cancelIcon from '../../../assets/cancelIcon.svg';
import { ITicket } from '../customerdetail/models/customerDataModels';
import * as labels from './labels';
import LoaderIcon from '../../components/loaderIcon/LoaderIcon';
import { getCountryLocales, getCurrencySymbol } from '../../components/helper/common';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';

interface IProps {
	storeId: string;
	merchantName: string;
	merchantLogoUrl?: string | null;
}

const SelectTicketView: React.FC<IProps> = ({ storeId, merchantName, merchantLogoUrl }) => {
	const history = useHistory();
	const {
		validSessionId,
		assignTicketToSession,
		errorMessage,
		setErrorMessage,
		ticketRefDetail,
		showLoaderIcon,
		toastMessage,
		updateToastMessage,
	} = useContext(AppContext) as IAppContext;
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const [isErrorViewActive, setIsErrorViewActive] = useState(false);
	const [selectedTicketRef, setSelectedTicketRef] = useState('');
	const tableRefId = localStorage.getItem('tableRef');
	const locale: undefined | string[] = merchantData && getCountryLocales(merchantData?.countryCode);

	useEffect(() => {
		updateToastMessage('');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (errorMessage) {
			updateToastMessage(errorMessage);
			setErrorMessage('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorMessage]);

	useEffect(() => {
		if (ticketRefDetail && Object.keys(ticketRefDetail).length === 0) {
			history.push({
				pathname: `/${storeId}/orderDetail`,
				state: {
					storeId,
				},
			});
		}

		if (ticketRefDetail?.tickets && ticketRefDetail.tickets.length > 0) {
			setSelectedTicketRef(ticketRefDetail.tickets[0]?.ref);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketRefDetail]);

	const ticketListView = () =>
		ticketRefDetail?.tickets &&
		ticketRefDetail.tickets.length > 0 &&
		ticketRefDetail.tickets.map((ticket: ITicket, ticketIndex: number) => (
			<li key={ticket.ref}>
				<ul className={ticketDetailstyles.detailsContent}>
					<li className={ticketDetailstyles.actions}>
						<input
							type="radio"
							id={ticket.ref}
							name="tickets"
							defaultChecked={ticketIndex === 0}
							onChange={() => handleTicketSelection(ticket.ref)}
						/>
					</li>
					<li className={ticketDetailstyles.totalValue}>{`${
						merchantData && getCurrencySymbol(merchantData.currency)
					}${ticket.total}`}</li>
					<li className={ticketDetailstyles.timeValue}>{convertUtcToTime(ticket.openedAt, locale)}</li>
				</ul>
			</li>
		));

	const handleCloseClick = () => {
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	const handleTicketSelection = (ticketRef: string) => {
		setSelectedTicketRef(ticketRef);
	};

	const confirmTicketDetails = () => {
		if (tableRefId) {
			assignTicketToSession(storeId, validSessionId, selectedTicketRef, tableRefId);
		}
	};

	const denyTicketDetails = () => {
		setIsErrorViewActive(true);
	};

	return isErrorViewActive ? (
		<React.Fragment>
			<div className={`${ticketDetailstyles.ticketDetailsPageContainer} Container`}>
				<div className={`${ticketDetailstyles.navButton}  ui grid`}>
					<div className="six column row">
						<div className="left floated column"></div>
						<div className="right floated column">
							<Button icon className="float right closeButton" onClick={handleCloseClick}>
								<img src={cancelIcon} className={`${ticketDetailstyles.cancelBtn}`} alt="close" />
							</Button>
						</div>
					</div>
				</div>
				<section className={ticketDetailstyles.ticketDetailsPage}>
					{merchantLogoUrl && (
						<div className={ticketDetailstyles.logo}>
							<img
								src={merchantLogoUrl}
								className={`${ticketDetailstyles.restaurantLogo}`}
								alt="restaurant name "
							/>
						</div>
					)}
					<h3 className={ticketDetailstyles.restaurantName}> {merchantName}</h3>
					<Divider />
					<div className={ticketDetailstyles.ticketNotAvailable}>
						<img src={warning} className={ticketDetailstyles.serverErrorImg} alt={labels.WARNING} />
						<h2 className={ticketDetailstyles.contactServerMsg}>{labels.NO_TICKET_ERROR_MESSAGE}</h2>
					</div>
				</section>
			</div>
		</React.Fragment>
	) : (
		<div id="ticketViewContainer">
			{toastMessage?.length > 0 && <Toast message={toastMessage} />}

			<div className={`${ticketDetailstyles.ticketDetailsPageContainer} Container`}>
				<div className={`${ticketDetailstyles.navButton}  ui grid`}>
					<div className="six column row">
						<div className="left floated column"></div>
						<div className="right floated column">
							<Button
								icon
								className="float right closeButton"
								onClick={handleCloseClick}
								disabled={showLoaderIcon}
							>
								<img src={cancelIcon} className={`${ticketDetailstyles.cancelBtn}`} alt="close" />
							</Button>
						</div>
					</div>
				</div>
				<section className={ticketDetailstyles.ticketDetailsPage}>
					{merchantLogoUrl && (
						<div className={ticketDetailstyles.logo}>
							<img
								src={merchantLogoUrl}
								className={`${ticketDetailstyles.restaurantLogo}`}
								alt="restaurant name "
							/>
						</div>
					)}
					<h3 className={ticketDetailstyles.restaurantName}> {merchantName} </h3>
					<Divider />
					<div className={ticketDetailstyles.customerMsg}>
						<img src={warning} className={ticketDetailstyles.menuErrorImg} alt={labels.WARNING} />
						<h2 className={ticketDetailstyles.customerInfo}>{labels.HEADER_MESSAGE}</h2>
					</div>
					<Divider />
					{/* Once api is available to return table Name, need to integrate the same.*/}
					{/* <p className={ticketDetailstyles.tableDetails}>{labels.TABLE_NUMBER_TEXT}</p> */}
					<ul className={ticketDetailstyles.ticketDetails}>
						<li>
							<ul className={ticketDetailstyles.detailsHeader}>
								<li className={ticketDetailstyles.actions}></li>
								<li className={ticketDetailstyles.total}>Total</li>
								<li className={ticketDetailstyles.time}>Open Time</li>
							</ul>
						</li>
						{ticketListView()}
					</ul>
				</section>
			</div>
			<div
				className={`${ticketDetailstyles.footer} ${ticketDetailstyles.fullWidth} ${ticketDetailstyles.btnDisabled}`}
			>
				<button
					className={`${ticketDetailstyles.btn} ${ticketDetailstyles.btnEnabled}`}
					onClick={confirmTicketDetails}
				>
					{showLoaderIcon && <LoaderIcon />}
					{labels.CONFIRM_BUTTON}
				</button>
				<button
					className={`${ticketDetailstyles.btn} ${ticketDetailstyles.btnOutlineBlue} `}
					onClick={denyTicketDetails}
				>
					{labels.CANCEL_BUTTON}
				</button>
			</div>
		</div>
	);
};

export default SelectTicketView;
