import { createContext } from 'react';
import { IPlaceOrderResponse, IOrderDetailRequestObject } from '../../pages/home/models/orderDetailModels';

export interface IOrderDetailContext {
	postOrderDetail: (locationId: string, orderData: IOrderDetailRequestObject) => Promise<IPlaceOrderResponse>;
}

export const OrderDetailPageContext: React.Context<IOrderDetailContext | null> =
	createContext<IOrderDetailContext | null>(null);
export default OrderDetailPageContext;
