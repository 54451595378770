import React from 'react';
import { IItemsModifierSet } from './components/common';
import ModifierSet from './components/ModifierSet';

interface IModifiersProps {
	modifierSets: IItemsModifierSet[];
	selectedModifierSets: string[];
	onHandleModifier(updateSelectedModifierSets: string[], selectedKey?: string, controlSelection?: string): void;
	isChildDisabled: boolean;
	modifierLastIndex: string;
}

const NestedModifiers: React.FC<IModifiersProps> = ({
	modifierSets,
	selectedModifierSets,
	onHandleModifier,
	isChildDisabled,
	modifierLastIndex,
}: IModifiersProps) => {
	return (
		<>
			{modifierSets &&
				modifierSets?.length > 0 &&
				modifierSets?.map(
					(modifierSet: IItemsModifierSet, modifierCurrentIndex: number) =>
						modifierSet?.modifierSetDetails?.available &&
						!(
							!modifierSet.modifierSetDetails?.minModifiersPerSet &&
							!modifierSet.modifierSetDetails?.maxModifiersPerSet
						) &&
						modifierSet.hasModifiers && (
							<ModifierSet
								key={modifierSet.key}
								modifierSet={modifierSet}
								selectedModifierSets={selectedModifierSets}
								handleModifiers={onHandleModifier}
								isChildDisabled={isChildDisabled}
								modifierCurrentIndex={modifierCurrentIndex}
								modifierLastIndex={modifierLastIndex}
							/>
						)
				)}
		</>
	);
};

export default NestedModifiers;
