import { IResponseData } from '../pages/home/models/merchantModels';
import { IMenuResponse } from '../pages/home/models/menuItemModels';
import { apiCall } from '../api/apiCall';

/**
 * This below function call the api for getting all the merchant detail data
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 */
export const getMerchantData = async (locationId: string): Promise<IResponseData> =>
	await apiCall(`/api/v2/merchants/${locationId}`, 'GET');

/**
 * This below function fetches the menu detail data
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 */
export const getMenuData = async (locationId: string): Promise<IMenuResponse> =>
	await apiCall(`/api/v1/merchants/${locationId}/menu`, 'GET');
