import Tick from '../../../icons/Tick';
import { IItemsModifier, OPTIONAL, REQUIRED, isModifierRequiredOrOptional } from './common';
import styles from './common.module.scss';

interface ISampleModifierProps {
	minimumModifiers: number;
	maximumModifier: number;
	totalModifierAvailable: number;
	modifiersArray: IItemsModifier[] | undefined;
	selectedModifierSets: string[];
}

const RequiredModifier: React.FC<ISampleModifierProps> = ({
	minimumModifiers,
	maximumModifier,
	totalModifierAvailable,
	modifiersArray,
	selectedModifierSets,
}) => {
	let maximumModifiers = maximumModifier;
	if (maximumModifiers >= totalModifierAvailable) {
		maximumModifiers = totalModifierAvailable;
	}
	const matchingElements = selectedModifierSets.filter(
		(item) =>
			modifiersArray && modifiersArray.some((modifierItem: IItemsModifier) => modifierItem.key.includes(item))
	);

	const RequiredTick = () => {
		return (
			<div className={styles.modifierSelected}>
				<Tick />
				<span>{REQUIRED}</span>
			</div>
		);
	};
	const isModifierSelected = (): JSX.Element => {
		if (minimumModifiers && maximumModifiers && minimumModifiers === maximumModifiers) {
			if (matchingElements.length >= maximumModifiers) {
				return <RequiredTick />;
			}
		} else if (minimumModifiers && maximumModifiers && minimumModifiers < maximumModifiers) {
			if (matchingElements.length >= minimumModifiers && matchingElements.length <= maximumModifiers) {
				return <RequiredTick />;
			}
		} else if (maximumModifiers) {
			if (matchingElements.length <= maximumModifiers) {
				return <RequiredTick />;
			}
		}

		return <span className={styles.modifierRequired}>{REQUIRED}</span>;
	};

	return (
		<div className={styles.modifierName}>
			{isModifierRequiredOrOptional(minimumModifiers) === REQUIRED ? (
				isModifierSelected()
			) : (
				<div className={styles.modifierOptional}>{OPTIONAL}</div>
			)}
		</div>
	);
};

export default RequiredModifier;
