import React, { useContext, useState } from 'react';

import styles from './common.module.scss';
import ArrowDown from '../../../icons/ArrowUp';
import ArrowUp from '../../../icons/ArrowDown';
import NestedModifiers from '../NestedModifiers';
import { IItemsModifier } from './common';
import { getFormattedPrice } from '../../helper/common';
import MenuItemsPageContext, { IMenuItemContext } from '../../../Contexts/MenuItemsPageContext/MenuItemsPageContext';

interface ICheckBoxFieldProps {
	modifier: IItemsModifier;
	selectedModifierSets: string[];
	maxModifiersPerSet: number;
	parentKey: string;
	handleModifiers(updateSelectedModifierSets: string[], selectedKey?: string, controlSelection?: string): void;
	isChildDisabled: boolean;
	modifierSetIndex: string;
}

const CheckBoxField: React.FC<ICheckBoxFieldProps> = ({
	modifier,
	selectedModifierSets,
	handleModifiers,
	parentKey,
	maxModifiersPerSet,
	isChildDisabled,
	modifierSetIndex,
}: ICheckBoxFieldProps) => {
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const [expand, setExpand] = useState(false);
	const modifierRef = modifier?.modifierRef;
	const modifierName = modifier?.modifierDetails?.name;
	const modifierPrice = modifier?.modifierDetails?.price;
	const modifierSets = modifier?.modifierSets || [];
	const isModifierChecked = selectedModifierSets.filter((modifierData) => modifierData === modifier.key).length > 0;
	// use of 37: 37 + parentKey = modifier.length
	const isDisabled =
		!isModifierChecked &&
		selectedModifierSets.filter(
			(modifierData) => parentKey.length + 37 === modifierData.length && modifierData.includes(parentKey)
		).length === maxModifiersPerSet;

	const handleExpandCollapse = () => {
		setExpand(!expand);
	};

	const handleModifier = (isChecked: boolean) => {
		setExpand(isChecked);
		if (isChecked) {
			const updateModifierSets: string[] = selectedModifierSets?.length ? selectedModifierSets : [];
			handleModifiers([...updateModifierSets, modifier.key], modifier.key, 'checked');
		} else {
			handleModifiers(
				selectedModifierSets.filter((modifierSet) => modifierSet !== modifier.key),
				modifier.key,
				'unchecked'
			);
		}
	};
	return (
		<div
			id={modifier.key}
			key={`${modifierRef}-${modifier.key}`}
			className={
				isDisabled || isChildDisabled
					? `${styles.modifierBlock} ${styles.modifierDisabled}`
					: styles.modifierBlock
			}
		>
			<div className={styles.selectionBlock}>
				<input
					type="checkbox"
					value={modifierRef}
					className={styles.checkboxButton}
					id={`${modifierRef}-${parentKey}`}
					name={`${modifierRef}-${parentKey}`}
					onChange={(e) => handleModifier(e.target.checked)}
					checked={isModifierChecked}
					disabled={isDisabled || isChildDisabled}
				/>
				{merchantData && (
					<div className={styles.labelBlock}>
						<label htmlFor={`${modifierRef}-${parentKey}`} className={styles.labelName}>
							{modifierName}{' '}
							{modifierPrice && modifierPrice !== 0
								? `+ ${getFormattedPrice(modifierPrice, merchantData?.currency)}`
								: null}
						</label>
						{modifierSets?.length > 0 && (
							<button className={styles.cartIconStyle} onClick={handleExpandCollapse}>
								{' '}
								{expand ? <ArrowDown /> : <ArrowUp />}{' '}
							</button>
						)}
					</div>
				)}
			</div>
			<div className={expand ? styles.modifierBody : styles.hideModifierBody}>
				{modifierSets?.length > 0 && (
					<NestedModifiers
						modifierSets={modifierSets}
						selectedModifierSets={selectedModifierSets}
						onHandleModifier={handleModifiers}
						isChildDisabled={isDisabled}
						modifierLastIndex={modifierSetIndex}
					/>
				)}
			</div>{' '}
		</div>
	);
};

export default CheckBoxField;
