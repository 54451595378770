import React from 'react';
import styles from './ErrorModal.module.scss';
import errorIcon from '../../../assets/cross.png';
import BackArrow from '../../../assets/errorBackArrow.svg';

interface IErrorModalProps {
	typeOfError: string | undefined;
	errorMsg: string | undefined;
	handleDismiss: () => void;
	actionType: string;
	isBackIconEnabled: boolean;
}
const ApiErrorModal: React.FC<IErrorModalProps> = ({
	typeOfError,
	errorMsg,
	handleDismiss,
	actionType,
	isBackIconEnabled,
}: IErrorModalProps) => {
	const handleDismissClose = () => {
		handleDismiss();
	};

	return (
		<div className={styles.errorContainer}>
			{isBackIconEnabled && (
				<div className={styles.apiErrorBackicon}>
					<button className={styles.apiErrorBackButton} onClick={handleDismissClose}>
						<img src={BackArrow} alt="" />
					</button>
				</div>
			)}
			<div className={styles.apiErrorSection}>
				<img src={errorIcon} alt="" className={styles.errorIcon} />
				{typeOfError && (
					<div className={styles.errorTitle}>
						<b>{typeOfError}</b>
					</div>
				)}
				<div className={styles.errorDescription}>{errorMsg}</div>
			</div>
			<div className={styles.fixedBottom}>
				<div className={styles.btnContanier}>
					<button className={`${styles.retryBtn}`} onClick={handleDismissClose}>
						{actionType}
					</button>
				</div>
			</div>
		</div>
	);
};
export default ApiErrorModal;
