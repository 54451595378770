export const API_ERRORS = [
	{
		MESSAGE: `We couldn't look you up from that link. Please enter name/phone again, and retry.`,
		TITLE: `Retry?`,
		STATUS: 404,
	},
	{
		MESSAGE: `We could not process your request. Please rescan the QR Code and try again.`,
		TITLE: `Error`,
		STATUS: 400,
	},
	{
		MESSAGE: `Contact employee to place your order. There was an error on our side - and we could not submit it for you.`,
		TITLE: `Sorry`,
		STATUS: 500,
	},
];

export const PRE_AUTH_API_ERRORS = [
	{
		MESSAGE: 'Sorry, we could not find your session. Please re-identify yourself.',
		TITLE: `Error`,
		STATUS: 404,
	},
	{
		MESSAGE: `Sorry, we could not find your session. Please re-identify yourself.`,
		TITLE: `Error`,
		STATUS: 400,
	},
	{
		MESSAGE: `Contact employee to place your order. There was an error on our side - and we could not submit it for you.`,
		TITLE: `Sorry`,
		STATUS: 500,
	},
];

export const BROWSER_ERRORS = {
	MESSAGE: `The link is opened in other browser. Please open it again in the same browser you used to place your order.`,
	TITLE: `Error`,
	STATUS: 100,
};

export const SESSION_NOT_FOUND_ERROR_MESSAGE = 'Sorry, we could not find your session. Please re-identify yourself.';
export const TICKET_NOT_FOUND_ERROR_MESSAGE =
	'There was a problem initiating payment. Contact your server to pay for ticket before leaving.';
export const INVALID_TICKET_ERROR_MESSAGE = 'This ticket is closed. Please contact server for more information.';
export const PARTIAL_PAYMENT_ERROR_MESSAGE =
	'Partial payment was done for this ticket. Contact your server to pay your ticket before leaving.';
