const COUNTRY_LOCALES_MAP = {
	AD: ['ca-AD'],
	AE: ['ar-AE'],
	AF: ['fa-AF', 'ps-AF'],
	AG: ['en-AG'],
	AI: ['en-AI'],
	AL: ['sq-AL'],
	AM: ['hy-AM'],
	AO: ['pt-AO'],
	AQ: ['en-AQ', 'es-AQ', 'fr-AQ', 'ru[1]-AQ'],
	AR: ['es-AR'],
	AS: ['en-AS', 'sm-AS'],
	AT: ['de-AT'],
	AU: ['en-AU'],
	AW: ['nl-AW', 'pap-AW'],
	AX: ['sv-AX'],
	AZ: ['az-AZ'],
	BA: ['bs-BA', 'hr-BA', 'sr-BA'],
	BB: ['en-BB'],
	BD: ['bn-BD'],
	BE: ['nl-BE', 'fr-BE', 'de-BE'],
	BF: ['fr-BF'],
	BG: ['bg-BG'],
	BH: ['ar-BH'],
	BI: ['fr-BI'],
	BJ: ['fr-BJ'],
	BL: ['fr-BL'],
	BM: ['en-BM'],
	BN: ['ms-BN'],
	BO: ['es-BO', 'qu-BO', 'gn-BO', 'ay-BO'],
	BQ: ['nl-BQ'],
	BR: ['pt-BR'],
	BS: ['en-BS'],
	BT: ['dz-BT'],
	BV: ['no-BV'],
	BW: ['en-BW', 'tn-BW'],
	BY: ['be-BY', 'ru-BY'],
	BZ: ['en-BZ'],
	CA: ['en-CA', 'fr-CA'],
	CC: ['en-CC'],
	CD: ['fr-CD'],
	CF: ['fr-CF', 'sg-CF'],
	CG: ['fr-CG'],
	CH: ['de-CH', 'fr-CH', 'it-CH', 'rm-CH'],
	CI: ['fr-CI'],
	CK: ['en-CK', 'rar-CK'],
	CL: ['es-CL'],
	CM: ['fr-CM', 'en-CM'],
	CN: ['zh-hans-CN'],
	CO: ['es-CO'],
	CR: ['es-CR'],
	CU: ['es-CU'],
	CV: ['pt-CV'],
	CW: ['nl-CW', 'en-CW'],
	CX: ['en-CX'],
	CY: ['el-CY', 'tr-CY'],
	CZ: ['cs-CZ'],
	DE: ['de-DE'],
	DJ: ['fr-DJ', 'ar-DJ', 'so-DJ', 'aa-DJ'],
	DK: ['da-DK'],
	DM: ['en-DM'],
	DO: ['es-DO'],
	DZ: ['ar-DZ'],
	EC: ['es-EC'],
	EE: ['et-EE'],
	EG: ['ar-EG'],
	EH: ['ar-EH', 'es-EH', 'fr-EH'],
	ER: ['ti-ER', 'ar-ER', 'en-ER'],
	ES: ['ast-ES', 'ca-ES', 'es-ES', 'eu-ES', 'gl-ES'],
	ET: ['am-ET', 'om-ET'],
	FI: ['fi-FI', 'sv-FI', 'se-FI'],
	FJ: ['en-FJ'],
	FK: ['en-FK'],
	FM: ['en-FM'],
	FO: ['fo-FO', 'da-FO'],
	FR: ['fr-FR'],
	GA: ['fr-GA'],
	GB: ['en-GB', 'ga-GB', 'cy-GB', 'gd-GB', 'kw-GB'],
	GD: ['en-GD'],
	GE: ['ka-GE'],
	GF: ['fr-GF'],
	GG: ['en-GG'],
	GH: ['en-GH'],
	GI: ['en-GI'],
	GL: ['kl-GL', 'da-GL'],
	GM: ['en-GM'],
	GN: ['fr-GN'],
	GP: ['fr-GP'],
	GQ: ['es-GQ', 'fr-GQ', 'pt-GQ'],
	GR: ['el-GR'],
	GS: ['en-GS'],
	GT: ['es-GT'],
	GU: ['en-GU', 'ch-GU'],
	GW: ['pt-GW'],
	GY: ['en-GY'],
	HK: ['zh-hant-HK', 'en-HK'],
	HM: ['en-HM'],
	HN: ['es-HN'],
	HR: ['hr-HR'],
	HT: ['fr-HT', 'ht-HT'],
	HU: ['hu-HU'],
	ID: ['id-ID'],
	IE: ['en-IE', 'ga-IE'],
	IL: ['he-IL'],
	IM: ['en-IM'],
	IN: ['hi-IN', 'en-IN'],
	IO: ['en-IO'],
	IQ: ['ar-IQ', 'ku-IQ'],
	IR: ['fa-IR'],
	IS: ['is-IS'],
	IT: ['it-IT', 'de-IT', 'fr-IT'],
	JE: ['en-JE'],
	JM: ['en-JM'],
	JO: ['ar-JO'],
	JP: ['ja-JP'],
	KE: ['sw-KE', 'en-KE'],
	KG: ['ky-KG', 'ru-KG'],
	KH: ['km-KH'],
	KI: ['en-KI'],
	KM: ['ar-KM', 'fr-KM', 'sw-KM'],
	KN: ['en-KN'],
	KP: ['ko-KP'],
	KR: ['ko-KR', 'en-KR'],
	KW: ['ar-KW'],
	KY: ['en-KY'],
	KZ: ['kk-KZ', 'ru-KZ'],
	LA: ['lo-LA'],
	LB: ['ar-LB', 'fr-LB'],
	LC: ['en-LC'],
	LI: ['de-LI'],
	LK: ['si-LK', 'ta-LK'],
	LR: ['en-LR'],
	LS: ['en-LS', 'st-LS'],
	LT: ['lt-LT'],
	LU: ['lb-LU', 'fr-LU', 'de-LU'],
	LV: ['lv-LV'],
	LY: ['ar-LY'],
	MA: ['fr-MA', 'zgh-MA', 'ar-MA'],
	MC: ['fr-MC'],
	MD: ['ro-MD', 'ru-MD', 'uk-MD'],
	ME: ['srp-ME', 'sr-ME', 'hr-ME', 'bs-ME', 'sq-ME'],
	MF: ['fr-MF'],
	MG: ['mg-MG', 'fr-MG'],
	MH: ['en-MH', 'mh-MH'],
	MK: ['mk-MK'],
	ML: ['fr-ML'],
	MM: ['my-MM'],
	MN: ['mn-MN'],
	MO: ['zh-hant-MO', 'pt-MO'],
	MP: ['en-MP', 'ch-MP'],
	MQ: ['fr-MQ'],
	MR: ['ar-MR', 'fr-MR'],
	MS: ['en-MS'],
	MT: ['mt-MT', 'en-MT'],
	MU: ['mfe-MU', 'fr-MU', 'en-MU'],
	MV: ['dv-MV'],
	MW: ['en-MW', 'ny-MW'],
	MX: ['es-MX'],
	MY: ['ms-MY'],
	MZ: ['pt-MZ'],
	NA: ['en-NA', 'sf-NA', 'de-NA'],
	NC: ['fr-NC'],
	NE: ['fr-NE'],
	NF: ['en-NF', 'pih-NF'],
	NG: ['en-NG'],
	NI: ['es-NI'],
	NL: ['nl-NL'],
	NO: ['nb-NO', 'nn-NO', 'no-NO', 'se-NO'],
	NP: ['ne-NP'],
	NR: ['na-NR', 'en-NR'],
	NU: ['niu-NU', 'en-NU'],
	NZ: ['mi-NZ', 'en-NZ'],
	OM: ['ar-OM'],
	PA: ['es-PA'],
	PE: ['es-PE'],
	PF: ['fr-PF'],
	PG: ['en-PG', 'tpi-PG', 'ho-PG'],
	PH: ['en-PH', 'tl-PH'],
	PK: ['en-PK', 'ur-PK'],
	PL: ['pl-PL'],
	PM: ['fr-PM'],
	PN: ['en-PN', 'pih-PN'],
	PR: ['es-PR', 'en-PR'],
	PS: ['ar-PS', 'he-PS'],
	PT: ['pt-PT'],
	PW: ['en-PW', 'pau-PW', 'ja-PW', 'sov-PW', 'tox-PW'],
	PY: ['es-PY', 'gn-PY'],
	QA: ['ar-QA'],
	RE: ['fr-RE'],
	RO: ['ro-RO'],
	RS: ['sr-RS', 'sr-Latn-RS'],
	RU: ['ru-RU'],
	RW: ['rw-RW', 'fr-RW', 'en-RW'],
	SA: ['ar-SA'],
	SB: ['en-SB'],
	SC: ['fr-SC', 'en-SC', 'crs-SC'],
	SD: ['ar-SD', 'en-SD'],
	SE: ['sv-SE'],
	SG: ['zh-hans-SG', 'en-SG', 'ms-SG', 'ta-SG'],
	SH: ['en-SH'],
	SI: ['sl-SI'],
	SJ: ['no-SJ'],
	SK: ['sk-SK'],
	SL: ['en-SL'],
	SM: ['it-SM'],
	SN: ['fr-SN'],
	SO: ['so-SO', 'ar-SO'],
	SR: ['nl-SR'],
	SS: ['en-SS'],
	ST: ['pt-ST'],
	SV: ['es-SV'],
	SX: ['nl-SX', 'en-SX'],
	SY: ['ar-SY'],
	SZ: ['en-SZ', 'ss-SZ'],
	TC: ['en-TC'],
	TD: ['fr-TD', 'ar-TD'],
	TF: ['fr-TF'],
	TG: ['fr-TG'],
	TH: ['th-TH'],
	TJ: ['tg-TJ', 'ru-TJ'],
	TK: ['tkl-TK', 'en-TK', 'sm-TK'],
	TL: ['pt-TL', 'tet-TL'],
	TM: ['tk-TM'],
	TN: ['ar-TN', 'fr-TN'],
	TO: ['en-TO'],
	TR: ['tr-TR'],
	TT: ['en-TT'],
	TV: ['en-TV'],
	TW: ['zh-hant-TW'],
	TZ: ['sw-TZ', 'en-TZ'],
	UA: ['uk-UA'],
	UG: ['en-UG', 'sw-UG'],
	UM: ['en-UM'],
	US: ['en-US'],
	UY: ['es-UY'],
	UZ: ['uz-UZ', 'kaa-UZ'],
	VA: ['it-VA'],
	VC: ['en-VC'],
	VE: ['es-VE'],
	VG: ['en-VG'],
	VI: ['en-VI'],
	VN: ['vi-VN'],
	VU: ['bi-VU', 'en-VU', 'fr-VU'],
	WF: ['fr-WF'],
	WS: ['sm-WS', 'en-WS'],
	YE: ['ar-YE'],
	YT: ['fr-YT'],
	ZA: ['en-ZA', 'af-ZA', 'st-ZA', 'tn-ZA', 'xh-ZA', 'zu-ZA'],
	ZM: ['en-ZM'],
	ZW: ['en-ZW', 'sn-ZW', 'nd-ZW'],
};

export default COUNTRY_LOCALES_MAP;
