export const RECEIVE_LINK_QUESTION = `Didn't receive code?`;
export const RESEND = `Resend`;
export const NAME = `Name`;
export const MASKED_NUMBER = '******';
export const NAME_QUESTION = `What’s your name?`;
export const PHONE_NUMBER = `Phone number`;
export const SMS_CODE = `SMS Code`;
export const DELIVERY_FORM_PROMPT = {
	message_one: `This helps us deliver your order to you.`,
	message_two: `We ask for your phone number to help confirm that this order belongs to you.`,
};
export const CONTINUE_BUTTON = `Continue`;
export const MESSAGE_CONFIRM = `Code Sent`;
export const SEND_CONFIRMATION_TEXT = 'Send Confirmation Text';
export const MOBILE_LINK_MESSAGE = {
	message_one: `Confirmation code sent to:`,
};
