import React from 'react';
import { useHistory } from 'react-router-dom';
import CancelIcon from '../cancelIcon/CancelIcon';
import { getFormattedPrice, getCurrencySymbol } from '../helper/common';

import styles from './ItemHeader.module.scss';
import { CurrencyCode } from '../../constants/currencyConstants';

export interface IItemHeaderProps {
	name?: string;
	imageUrl?: string;
	price: number;
	description?: string;
	customLabels?: string[];
	id: string;
	storeId: string;
	isPageScrolled?: boolean;
	currency: CurrencyCode;
}

const ItemHeader: React.FC<IItemHeaderProps> = ({
	name,
	imageUrl,
	price,
	description,
	customLabels,
	id,
	storeId,
	isPageScrolled,
	currency,
}: IItemHeaderProps) => {
	const history = useHistory();
	const tableRefId = localStorage.getItem('tableRef');

	const handleCloseClick = () => {
		localStorage.removeItem('itemDetails');
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	return (
		<div style={{ minHeight: '130px' }}>
			{!isPageScrolled && (
				<div>
					<div className={styles.itemHeaderMainBlock} id="mainHeader">
						<CancelIcon type="image" handleCancelClick={handleCloseClick} />
						{imageUrl && (
							<div
								id={id}
								className={styles.itemHeaderImage}
								style={{
									backgroundImage: `URL(${imageUrl})`,
									height: isPageScrolled ? '0px' : '300px',
								}}
							/>
						)}
						<div className={styles.badgeBlock}>
							{customLabels &&
								customLabels?.map((label) => {
									return (
										<span
											key={label}
											className={[styles.badge, `${styles[`badge${label}`]}`].join(' ')}
										>
											{label}
										</span>
									);
								})}
						</div>
						<div className={styles.itemTitle}>{name}</div>
						<div className={styles.itemDescription}>{description}</div>
						{price ? (
							<div className={styles.itemPrice}>{currency && getFormattedPrice(price, currency)}</div>
						) : (
							<div className={styles.itemDefaultPrice}>
								{currency && `${getCurrencySymbol(currency)}0.00`}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ItemHeader;
