import { createContext } from 'react';

import { ICustomerData, ITickets } from '../../pages/customerdetail/models/customerDataModels';

export interface IAppContext {
	isValidSession: boolean | undefined;
	setIsValidSession: (isValidSession: boolean) => void;
	startSession: (locationId: string, userData: ICustomerData) => void;
	checkSessionData: (locationId: string, sessionId: string, code: string, tableRef: string) => void;
	assignTicketToSession: (locationId: string, sessionId: string, ticketRef: string, tableRefId: string) => void;
	ticketRefDetail: ITickets | undefined;
	isClientTokenSet: boolean;
	setIsClientTokenSet: (isClientToken: boolean) => void;
	validSessionId: string;
	sessionErrorStatus: number | undefined;
	setSessionErrorStatus: (sessionErrorStatus: number) => void;
	setValidSessionId: (sessionId: string) => void;
	errorMessage: string | undefined;
	setErrorMessage: (errorMessage: string) => void;
	setTicketRefDetail: (ticketsRef: ITickets) => void;
	showLoaderIcon: boolean;
	setShowLoaderIcon: (showLoaderIcon: boolean) => void;
	toastMessage: string;
	updateToastMessage: (toastMessage: string) => void;
	itemScrollPosition: number;
	setItemScrollPosition: (itemScrollPosition: number) => void;
	tempSessionId: string;
}

export const AppContext: React.Context<IAppContext | null> = createContext<IAppContext | null>(null);
export default AppContext;
