import React, { useEffect, useContext, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import lessThanIcon from '../../../assets/lessThanIcon.svg';

import Toast from '../../components/toast/Toast';
import AppContext, { IAppContext } from '../../Contexts/AppContext/AppContext';
import Footer from '../../components/footer/Footer';
import ViewTicketDetailPageContext, {
	IViewTicketDetailPageContext,
} from '../../Contexts/ViewTicketPageContext/ViewTicketPageContext';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';
import { IDiscount, IItem, ITicket, ISpecialRequests, ITaxList } from './models/ticketDetailsModels';

import viewDetailStyles from './ViewTicketDetail.module.scss';
import Spinner from '../../components/spinner/Spinner';
import {
	PARTIAL_PAYMENT_ERROR_MESSAGE,
	SESSION_NOT_FOUND_ERROR_MESSAGE,
	TICKET_NOT_FOUND_ERROR_MESSAGE,
} from './labels';
import { getCurrencySymbol } from '../../components/helper/common';

interface IProps {
	match: {
		params: {
			storeId: string;
		};
	};
}

const ViewTicket: React.FC<IProps> = (props) => {
	const history = useHistory();
	const {
		isValidSession,
		checkSessionData,
		validSessionId,
		setIsValidSession,
		setValidSessionId,
		toastMessage,
		updateToastMessage,
	} = useContext(AppContext) as IAppContext;
	const { getQrPayRedirect, ticketDetails, setTicketDetails, payRedirectError, setShouldRedirectToViewCheck } =
		useContext(ViewTicketDetailPageContext) as IViewTicketDetailPageContext;
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const [payView, setPayView] = useState<boolean>(false);
	const [showLoaderIcon, setShowLoaderIcon] = useState<boolean>(false);
	const { storeId } = props.match.params;
	const tableRefId = localStorage.getItem('tableRef');
	const existingSessionId = localStorage.getItem('storedSessionId');
	const isQrPayEnabled = merchantData?.isQRPayEnabled || localStorage.getItem('isQRPayEnabled') === 'true';
	const { getTicket } = useContext(ViewTicketDetailPageContext) as IViewTicketDetailPageContext;
	const [ticketViewDetails, setTicketViewDetails] = useState<ITicket>({});
	const merchantName = localStorage.getItem('merchantName');

	useEffect(() => {
		setShouldRedirectToViewCheck(false);
		updateToastMessage('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (merchantData) {
			localStorage.setItem('merchantName', merchantData?.name);
		}
		if (ticketDetails && Object.keys(ticketDetails).length > 0) {
			setTicketViewDetails(ticketDetails);
			localStorage.setItem('ticketDetails', JSON.stringify(ticketDetails));
			return;
		}
		const openTicketDetails = localStorage.getItem('ticketDetails');
		if (openTicketDetails && Object.keys(JSON.parse(openTicketDetails)).length > 0) {
			setTicketViewDetails(JSON.parse(openTicketDetails));
		}
	}, [ticketDetails, merchantData]);

	useEffect(() => {
		if (payRedirectError) {
			if (payRedirectError.code === 'USER_SESSION_NOT_FOUND') {
				updateToastMessage(SESSION_NOT_FOUND_ERROR_MESSAGE);
			} else if (payRedirectError.code === 'PARTIAL_PAYMENT_EXISTS') {
				updateToastMessage(PARTIAL_PAYMENT_ERROR_MESSAGE);
			} else {
				updateToastMessage(TICKET_NOT_FOUND_ERROR_MESSAGE);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payRedirectError]);

	useEffect(() => {
		const smsCode = localStorage.getItem('clientCode') || '';
		if (!existingSessionId) {
			if (!isValidSession) {
				if (storeId && tableRefId && validSessionId !== '') {
					checkSessionData(storeId, validSessionId, smsCode, tableRefId);
				}
			}
		} else {
			setIsValidSession(true);
			setValidSessionId(existingSessionId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkSessionData, validSessionId, storeId, tableRefId]);

	useEffect(() => {
		if (payView && ticketViewDetails?.amountPaid) {
			if (Number(ticketViewDetails?.amountPaid > 0)) {
				updateToastMessage(PARTIAL_PAYMENT_ERROR_MESSAGE);
			} else {
				setShowLoaderIcon(true);
				getQrPayRedirect(storeId, validSessionId);
			}
			setPayView(false);
		}
		setShouldRedirectToViewCheck(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketViewDetails, payView]);

	const addToQRpay = () => {
		setTicketDetails({});
		if (existingSessionId) {
			getTicket(storeId, existingSessionId, false);
			setPayView(true);
		}
	};

	const handleBackClick = () => {
		localStorage.removeItem('ticketDetails');
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	const balanceValue = ticketViewDetails?.amountDue || 0;

	const TaxDetails: React.FC = () => {

	const isTaxListAvailable = ticketViewDetails?.taxList && ticketViewDetails?.taxList.length > 0 && ticketViewDetails?.taxList.some(tax => tax.amount);
	const isHiddenTaxListAvailable = ticketViewDetails?.hiddenTaxList && ticketViewDetails?.hiddenTaxList.length > 0 && ticketViewDetails?.hiddenTaxList.some(tax => tax.amount);
	const isOtherTaxesAvailable = Number(ticketViewDetails?.otherTaxes) > 0;

		return (
			<>
			{isTaxListAvailable || isHiddenTaxListAvailable || isOtherTaxesAvailable ? (
				<>
					{isTaxListAvailable && ticketViewDetails?.taxList?.map((tax: ITaxList, index: number) => (
						<>
						{tax.amount && (
						<div className={viewDetailStyles.row} key={index}>
							<div className={viewDetailStyles.taxName}>{tax.name}:</div>
							<div className={viewDetailStyles.taxValue}>
								{`${getCurrencySymbol(merchantData?.currency)}${Number(tax.amount).toFixed(2)}`}
							</div>
						</div>
						)}
						</>
					))}
					{isOtherTaxesAvailable && (
						<div className={viewDetailStyles.row}>
							<div className={viewDetailStyles.name}>Taxes:</div>
							<div className={viewDetailStyles.taxValue}>
								{`${getCurrencySymbol(merchantData?.currency)}${ticketViewDetails?.otherTaxes}`}
							</div>
						</div>
					)}
					{isHiddenTaxListAvailable && (
						<>
						<div className={viewDetailStyles.hiddenTaxTitle}>Taxes included in prices:</div>
						{ticketViewDetails?.hiddenTaxList?.map((tax: ITaxList, index: number) => (
							<>
							{tax.amount && (
							<div className={viewDetailStyles.row} key={index}>
								<div className={viewDetailStyles.taxName}>{tax.name}:</div>
									<div className={viewDetailStyles.taxValue}>
										({`${getCurrencySymbol(merchantData?.currency)}${Number(tax.amount).toFixed(2)}`})
									</div>
								</div>
							)}
							</>
						))}
						</>
				)}
				</>
				) : (
					<div className={viewDetailStyles.row}>
						{ticketViewDetails?.taxes && Number(ticketViewDetails?.taxes) > 0 && (
							<>
								<div className={viewDetailStyles.name}>Tax:</div>
								<div className={viewDetailStyles.taxValue}>
									{`${getCurrencySymbol(merchantData?.currency)}${
										ticketViewDetails?.taxes
									}`}
								</div>
							</>
						)}
					</div>
				)
			}
			</>)
	}

	return (
		<div>
			{merchantData && (
				<div className={viewDetailStyles.viewDetailContainer}>
					<div className={viewDetailStyles.viewDetailHeading}>
						<div className={viewDetailStyles.backButton}>
							<Button
								icon
								className="float left backButton"
								onClick={handleBackClick}
								disabled={showLoaderIcon}
							>
								<img src={lessThanIcon} className={viewDetailStyles.backButton} alt="back" />
							</Button>
						</div>
						<div id="checkDetails" className={viewDetailStyles.headingTitle}>
							Check Details
						</div>
					</div>
					<div className={viewDetailStyles.viewContainer}>
						<div className={viewDetailStyles.ticketContainer}>
							<div className={viewDetailStyles.ticketRow}>
								<div className={viewDetailStyles.ticketRowLabelBold}>{merchantName}</div>
							</div>
							<div className={viewDetailStyles.ticketRow}>
								<div className={viewDetailStyles.ticketRowLabel}>Ticket #:</div>
								<div className={viewDetailStyles.ticketRowValue}> #{ticketViewDetails?.order}</div>
							</div>
							<div className={viewDetailStyles.ticketRow}>
								<div className={viewDetailStyles.ticketRowLabel}>Table:</div>
								<div className={viewDetailStyles.ticketRowValue}>{ticketViewDetails?.table} </div>
							</div>
							<div className={viewDetailStyles.ticketRow}>
								<div className={viewDetailStyles.ticketRowLabel}>Server:</div>
								<div className={viewDetailStyles.ticketRowValue}> {ticketViewDetails?.employee}</div>
							</div>
						</div>
						<div className={viewDetailStyles.divider} />
						<div className={viewDetailStyles.itemDetailsContainer}>
							{ticketViewDetails?.items?.map((item: IItem, index: number) => {
								const specialRequestsArr: ISpecialRequests[] = [];
								if (item.specialRequests.length > 0) {
									item.specialRequests.map((specialRequestItem: string) => {
										const specialRequestObj = {
											specialRequestBy: specialRequestItem.substring(
												specialRequestItem.indexOf('[') + 1,
												specialRequestItem.indexOf(']')
											),
											specialRequest: specialRequestItem
												.slice(specialRequestItem.lastIndexOf(']') + 1)
												.trim(),
										};
										specialRequestsArr.push(specialRequestObj);
									});
								}

								return (
									<div key={index} className={viewDetailStyles.itemDetailsRow}>
										<div className={viewDetailStyles.itemHeader}>
											<div className={viewDetailStyles.itemName}>
												<span className={viewDetailStyles.quantity}>
													{Number(item.quantity).toFixed(0)}
												</span>
												{item.name}
											</div>
											<div className={viewDetailStyles.itemPrice}>
												{`${getCurrencySymbol(merchantData.currency)}${item.price}`}
											</div>
										</div>
										<div className={viewDetailStyles.itemModifiers}>{item.modifiers}</div>
										<div className={viewDetailStyles.specialRequestContainer}>
											{/* TODO : https://jira.shift4payments.com/browse/STQR-279 Need to fetch the specialRequest value and 
									customer name for item from API which is not yet available in API. Need code modification once above values are available*/}
											{specialRequestsArr?.length > 0 &&
												specialRequestsArr.map(
													(
														specialRequestItem: ISpecialRequests,
														specialRequestIndex: number
													) => {
														return (
															<div key={specialRequestIndex}>
																{specialRequestItem.specialRequest.length > 0 && (
																	<div>
																		<div
																			className={`${viewDetailStyles.ticketRow}  ${viewDetailStyles.specialRequest}`}
																		>
																			<div
																				className={
																					viewDetailStyles.ticketRowLabelBold
																				}
																			>
																				Special Request:
																			</div>
																		</div>
																		<div
																			className={`${viewDetailStyles.ticketRow}  ${viewDetailStyles.specialRequest}`}
																		>
																			<div
																				className={
																					viewDetailStyles.modifiersDomLabel
																				}
																			>
																				For:{' '}
																				{specialRequestItem.specialRequestBy}
																			</div>
																		</div>
																		<div
																			className={`${viewDetailStyles.ticketRow}  ${viewDetailStyles.specialRequest}`}
																		>
																			<div
																				className={
																					viewDetailStyles.modifiersDomLabel
																				}
																			>
																				{specialRequestItem.specialRequest}
																			</div>
																		</div>
																	</div>
																)}
															</div>
														);
													}
												)}
										</div>
									</div>
								);
							})}
						</div>
						<div className={viewDetailStyles.divider} />
						<div className={viewDetailStyles.priceContainer}>
							<div className={viewDetailStyles.row}>
								<div className={viewDetailStyles.name}>Subtotal:</div>
								<div className={viewDetailStyles.value}>
									{`${getCurrencySymbol(merchantData.currency)}${ticketViewDetails?.subtotal}`}
								</div>
							</div>
							<div className={viewDetailStyles.row}>
								{ticketViewDetails?.discounts?.map((discount: IDiscount, index: number) => {
									<div className={viewDetailStyles.header}>Discount:</div>;
									return (
										<div className={viewDetailStyles.innerRow} key={index}>
											<div className={viewDetailStyles.subHeader}>{discount.name}</div>
											<div className={viewDetailStyles.value}>
												{`${getCurrencySymbol(merchantData.currency)}${
													discount?.discountedAmount
												}`}
											</div>
										</div>
									);
								})}
							</div>
							{ticketViewDetails?.surcharges && Number(ticketViewDetails?.surcharges) > 0 && 
							<div className={viewDetailStyles.row}>
								<div className={viewDetailStyles.name}>Surcharge:</div>
								<div className={viewDetailStyles.value}>
									{`${getCurrencySymbol(merchantData.currency)}${
										ticketViewDetails?.surcharges
									}`}
								</div>
							</div>
							}
							<TaxDetails />
							<div className={viewDetailStyles.row}>
								<div className={viewDetailStyles.total}>
									<div className={viewDetailStyles.name}>TOTAL:</div>
									{ticketViewDetails?.total ? (
										<div className={viewDetailStyles.value}>
											{`${getCurrencySymbol(merchantData.currency)}${ticketViewDetails?.total}`}
										</div>
									) : (
										<div className={viewDetailStyles.value}>
											{getCurrencySymbol(merchantData.currency)}
											{0.0}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					{ticketDetails && Object.keys(ticketDetails).length === 0 && <Spinner />}
					{isQrPayEnabled && (
						<div className={viewDetailStyles.footer}>
							<Footer
								externalStyle={''}
								id="payButton"
								buttonColor={'Green'}
								loaderStatus={showLoaderIcon}
								labelText={`Pay ${getCurrencySymbol(merchantData?.currency)}${balanceValue}`}
								balanceValue={balanceValue}
								showQuantityDetail={false}
								addToOrderInnerWithoutParams={addToQRpay}
							/>
						</div>
					)}
				</div>
			)}
			{toastMessage?.length > 0 && <Toast message={toastMessage} />}
		</div>
	);
};
export default ViewTicket;
