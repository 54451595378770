import React, { useState } from 'react';
import MenuItemsPageContext from './MenuItemsPageContext';
import { getMerchantData, getMenuData } from '../../services/MenuItemsPageService';
import { IResponseData, IMerchantData } from '../../pages/home/models/merchantModels';
import { IMenuResponse, IMenuSchedule, ISchedule, ISchedulesCategory } from '../../pages/home/models/menuItemModels';
import updateMenuItemObject, { updateMenuSchedule } from '../../utils/menuItemUtils';
import { getCountryLocales } from '../../components/helper/common';

interface IProps {
	children?: React.ReactNode;
}

const MenuItemsPageContextProvider: React.FC<IProps> = ({ children }) => {
	const [merchantData, setMerchantData] = useState<IMerchantData>();
	const [scheduleCategories, setScheduleCategories] = useState<ISchedulesCategory[]>([]);
	const [activeCategory, setActiveCategory] = useState<string>('');
	const [menuListSchedule, setMenuListSchedule] = useState<IMenuSchedule[]>();
	const [menuName, setMenuName] = useState<string>('Select');
	const [menuItemDetail, setMenuItemDetail] = useState<ISchedule[]>([]);

	const updateMerchantDetail = async (locationId: string) => {
		if (merchantData) {
			return;
		}
		const merchantDetail: IResponseData = await getMerchantData(locationId);
		setMerchantData(merchantDetail.merchants);
	};

	const getMenuDetail = async (locationId: string) => {
		if (menuListSchedule || !merchantData) {
			return;
		}
		const menuListDetail: IMenuResponse = await getMenuData(locationId);
		const menuItemData = updateMenuItemObject(menuListDetail);
		const locale: undefined | string[] = getCountryLocales(merchantData?.countryCode);
		setMenuListSchedule(updateMenuSchedule(menuListDetail, locale));
		if (updateMenuSchedule(menuListDetail, locale).length !== 0 && menuName === 'Select') {
			setMenuName(updateMenuSchedule(menuListDetail, locale)[0].name);
		}
		setMenuItemDetail(menuItemData);
		if (menuItemData && menuItemData.length != 0) {
			const activeCategories = menuItemData[0].categories.filter(
				(categories: ISchedulesCategory) => categories.categoryDetails?.available
			);
			setScheduleCategories(activeCategories);
			if (activeCategories?.length > 0) {
				setActiveCategory(activeCategories[0].categoryRef);
			}
		}
		if (menuListDetail && menuListDetail.menu) {
			const modifiersData = {
				modifiers: menuListDetail.menu.modifiers,
				modifierSets: menuListDetail.menu.modifierSets,
			};
			localStorage.setItem('modifiersData', JSON.stringify(modifiersData));
		}
	};

	const value = {
		merchantData,
		updateMerchantDetail,
		scheduleCategories,
		getMenuDetail,
		activeCategory,
		menuListSchedule,
		menuName,
		menuItemDetail,
		setScheduleCategories,
		setActiveCategory,
		setMenuName,
	};

	return <MenuItemsPageContext.Provider value={value}>{children}</MenuItemsPageContext.Provider>;
};

export default MenuItemsPageContextProvider;
