import React, { useState } from 'react';
import OrderConfirmPageContext from './OrderConfirmPageContext';
import { getQRPay } from '../../services/OrderConfirmPageService';
import { IErrorResponse } from '../../pages/viewTickets/models/ticketDetailsModels';

interface IProps {
	children?: React.ReactNode;
}

const OrderConfirmPageContextProvider: React.FC<IProps> = ({ children }) => {
	const [payRedirectError, setPayRedirectError] = useState<IErrorResponse>();
	const [showLoaderIcon, setShowLoaderIcon] = useState<boolean>(false);

	const getQRPayHost = async (locationId: string, sessionId: string): Promise<void> => {
		setShowLoaderIcon(true);

		const response = await getQRPay(locationId, sessionId);
		if (response?.error && [500, 400, 404].includes(response.error.status)) {
			setPayRedirectError(response.error);
		} else {
			setPayRedirectError(undefined);
			window.location = response.url;
		}
		setShowLoaderIcon(false);
	};
	const value = {
		getQRPayHost,
		payRedirectError,
		setPayRedirectError,
		showLoaderIcon,
		setShowLoaderIcon,
	};

	return <OrderConfirmPageContext.Provider value={value}>{children}</OrderConfirmPageContext.Provider>;
};

export default OrderConfirmPageContextProvider;
