import React, { useContext } from 'react';
import { IPlaceOrderResponse, IOrderDetailRequestObject } from '../../pages/home/models/orderDetailModels';
import { postOrderData } from '../../services/OrderDetailSessionServices';
import ItemOrderPageContext from './OrderDetailPageContext';
import AppContext, { IAppContext } from '../AppContext/AppContext';

interface IProps {
	children?: React.ReactNode;
}

const OrderDetailPageContextProvider: React.FC<IProps> = ({ children }) => {
	const { validSessionId } = useContext(AppContext) as IAppContext;
	const postOrderDetail = async (
		locationId: string,
		orderData: IOrderDetailRequestObject
	): Promise<IPlaceOrderResponse> => {
		return postOrderData(locationId, validSessionId, orderData);
	};
	const value = {
		postOrderDetail,
	};

	return <ItemOrderPageContext.Provider value={value}>{children}</ItemOrderPageContext.Provider>;
};

export default OrderDetailPageContextProvider;
