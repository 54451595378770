import styles from './ErrorModal.module.scss';
import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
interface IErrorModalProps {
	typeOfError: string | undefined;
	errorMsg: string | undefined;
	handleDismiss: () => void;
	showButton: boolean;
}
const ErrorModal: React.FC<IErrorModalProps> = ({
	typeOfError,
	errorMsg,
	handleDismiss,
	showButton,
}: IErrorModalProps) => {
	const [hide, setHide] = useState(true);
	const handleDismissClose = () => {
		handleDismiss();
		setHide(false);
	};

	return (
		<>
			{hide && (
				<div className={styles.errorSection}>
					<div className={styles.errorModal}>
						<div className={styles.modalHeader}>{typeOfError} </div>
						<div className={styles.modalBody}>
							<div className={styles.modalContent}>{errorMsg}</div>
							<div className={styles.modalButton}>
								{showButton && (
									<Button
										type="button"
										primary
										className={`${styles.closeBtn} ${styles.btn} fluid`}
										onClick={handleDismissClose}
									>
										Dismiss
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default ErrorModal;
