import {
	IMenuResponse,
	IItem,
	IHour,
	ISchedulesCategory,
	ISchedule,
	ICategory,
	IMenuSchedule,
} from '../pages/home/models/menuItemModels';
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;

/**
 * This below function modify menu item response to data to expected format for rendering the
 * menu items based on the schedueles and respective catagory.
 * @param {IMenuResponse} menuItemsResponseData - reponse data received from menu api call.
 * @returns {ISchedule[]} - returns the updated menu data having a mapping with schedules and
 * category.
 */
export const updateMenuItemObject = (menuItemsResponseData: IMenuResponse): ISchedule[] => {
	const menuItemData = JSON.parse(JSON.stringify(menuItemsResponseData));
	const { schedules } = menuItemData.menu;
	schedules.map((schedule: ISchedule) => {
		schedule.categories.map((category: ISchedulesCategory) => {
			const itemList: IItem[] = [];
			category.itemRefs.map((itemRef: string) => {
				if (menuItemsResponseData && menuItemsResponseData.menu) {
					const itemObject = menuItemsResponseData.menu.items.find(
						(item: IItem) => item.available && item.ref === itemRef
					);
					if (itemObject) {
						itemList.push(itemObject);
					}
				}
			});
			category.itemList = itemList;
			if (menuItemsResponseData && menuItemsResponseData.menu) {
				const categoryDetails = menuItemsResponseData.menu.categories.find(
					(categoryObj: ICategory) => categoryObj.ref === category.categoryRef
				);
				if (categoryDetails) {
					category.categoryDetails = categoryDetails;
				}
			}
		});
	});

	return schedules;
};

/**
 * This below function converts schedule start/end time to the time format according to the region (eg: 11:59 PM for US region and 23:59 for UK)
 * @param {string} hms - It represents menu schedule time slot (it can be start time or end time) .
 * @param {string} locale - It represents locale for the specific region(i.e "en-US" for US region and "en-GB" for UK region) .
 * @returns {string} - returns time in format according to the region.
 */
export const getFormattedTime = (hms: string, locale: undefined | string[]): string => {
	const date = new Date();
	const [hours, minutes] = hms.split(':');
	date.setHours(Number(hours));
	date.setMinutes(Number(minutes));

	return date.toLocaleTimeString(locale, {
		hour: '2-digit',
		minute: '2-digit',
	});
};

/**
 * This below function converts schedule start/end time from "16:15:00" to "58,500" seconds.
 * which we can use checking schedule range with present time in seconds.
 * @returns {string} - returns time in second.
 */
export const getTimeInSeconds = (scheduleTime: string): number => {
	return (
		Number(scheduleTime.substring(0, 2)) * MINUTES_IN_HOUR * SECONDS_IN_MINUTE +
		Number(scheduleTime.substring(3, 5)) * SECONDS_IN_MINUTE +
		Number(scheduleTime.substring(6, 9))
		/* this above line get the time in seconds Example : '16:15:00' is into = 16*60*60 + 15*60+ 00 which is '58500' */
	);
};

/**
 * This below function gives present day's name like "MONDAY" or "TUESDAY".
 * @returns {string} - gives present day's name like "MONDAY" or "TUESDAY"
 */
export const getTodayName = (): string => {
	const DAYS_ARRAY = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
	const presentTime = new Date();
	const dayName = DAYS_ARRAY[presentTime.getDay()];
	return dayName;
};

/**
 * This below function takes below input.
 * * @param {IMenuResponse} menuItemsResponseData - It represents menu api response . and returns the output as below
 * @returns {IMenuSchedule[]} presentSchedule - It return menu data with schedule name and time .
 */
export const updateMenuSchedule = (
	menuItemsResponseData: IMenuResponse,
	locale: undefined | string[]
): IMenuSchedule[] => {
	const presentSchedule: IMenuSchedule[] = [];
	let scheduleDataList: ISchedule[] = [];
	if (menuItemsResponseData && menuItemsResponseData.menu) {
		scheduleDataList = menuItemsResponseData.menu.schedules;
	}
	const presentTime = new Date();
	const secondNow =
		presentTime.getHours() * MINUTES_IN_HOUR * SECONDS_IN_MINUTE +
		presentTime.getMinutes() * SECONDS_IN_MINUTE +
		presentTime.getSeconds();
	scheduleDataList.forEach((scheduleDetail: ISchedule) => {
		if (scheduleDetail.available) {
			scheduleDetail.hours.forEach((schedule: IHour) => {
				if (schedule.dayOfWeek === getTodayName()) {
					const start = getTimeInSeconds(schedule.startTime.toString());
					const end = getTimeInSeconds(schedule.endTime.toString());
					if (start <= secondNow && secondNow <= end) {
						presentSchedule.push({
							name: scheduleDetail.name,
							dayOfWeek: schedule.dayOfWeek,
							endTime: getFormattedTime(schedule.endTime.toString(), locale),
							startTime: getFormattedTime(schedule.startTime.toString(), locale),
						});
					}
				}
				return presentSchedule;
			});
		}
	});
	return presentSchedule;
};

/**
 * This below function takes below input.
 * @param {string} selectedSchedule - It represents selected Menu schedule name
 * @param {ISchedule[]} menuItems - It represents selected Menu details which is required to set Menu details .
 * filter out and gives the index of menu item which helps to show the category
 * @returns {number} - index of menu item
 */
export const getSelectedMenuItemIndex = (selectedSchedule: string, menuItems: ISchedule[]): number => {
	return menuItems?.findIndex((item: ISchedule) => item.name === selectedSchedule);
};
export default updateMenuItemObject;
