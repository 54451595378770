import React from 'react';

import styles from './common.module.scss';
import { IItemsModifier } from './common';
import RadioButtonField from './RadioButtonField';

interface IRadioButtonSelectionProps {
	modifiers: IItemsModifier[];
	selectedModifierSets: string[];
	parentKey: string;
	handleModifiers(updateSelectedModifierSets: string[], selectedKey?: string, controlSelection?: string): void;
	isChildDisabled: boolean;
	modifierSetIndex: string;
}

const RadioButtonSelection: React.FC<IRadioButtonSelectionProps> = ({
	modifiers,
	selectedModifierSets,
	handleModifiers,
	isChildDisabled,
	parentKey,
	modifierSetIndex,
}: IRadioButtonSelectionProps) => {
	return (
		<div className={styles.mainBlock}>
			{modifiers?.length &&
				modifiers.map((modifier: IItemsModifier) => {
					return modifier?.modifierDetails?.available ? (
						<RadioButtonField
							parentKey={parentKey}
							key={modifier.key}
							modifier={modifier}
							selectedModifierSets={selectedModifierSets}
							handleModifiers={handleModifiers}
							isChildDisabled={isChildDisabled}
							modifierSetIndex={modifierSetIndex}
						/>
					) : null;
				})}
		</div>
	);
};

export default RadioButtonSelection;
