import { createContext } from 'react';
import {
	I4goIdPostData,
	IAccessDetailsPostData,
	IAccessDetailsResponse,
} from '../../pages/preAuthenticationDetail/models/PreAuthModel';

export interface IPreAuthItemContext {
	getTableName: (locationId: string) => void;
	tableName: string;
	accessDetails: IAccessDetailsResponse;
	setAccessDetails: (accessDetails: IAccessDetailsResponse) => void;
	getAccessToken: (locationId: string, orderData: IAccessDetailsPostData) => void;
	accessDetailsError: string;
	setAccessDetailsError: (error: string) => void;
	getSessionId: (locationId: string, i4goIdData: I4goIdPostData) => void;
	sessionId: string;
	sessionIdError: string;
	setSessionIdError: (sessionError: string) => void;
	setSessionId: (sessionId: string) => void;
	setRecaptchaToken: (recaptchaToken: string) => void;
	isActiveSession: boolean;
	setIsActiveSession: (isActiveSession: boolean) => void;
	recaptchaToken: string;
}

export const PreAuthPageContext: React.Context<IPreAuthItemContext | null> = createContext<IPreAuthItemContext | null>(
	null
);
export default PreAuthPageContext;
