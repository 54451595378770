import styles from './LegalConsentModal.module.scss';
import React from 'react';
import { Button } from 'semantic-ui-react';
import cancelIcon from '../../../assets/cancelIcon.svg';
import { CONSENT_TEXT } from '../../constants/constants';

interface IConsentModalProps {
	modalHeader: string;
	consentMessage: string;
	showModal: boolean;
	handleDismiss: () => void;
	handleConsent: () => void;
}
const LegalConsentModal: React.FC<IConsentModalProps> = ({
	modalHeader,
	consentMessage,
	showModal,
	handleDismiss,
	handleConsent
}: IConsentModalProps) => {

	return (
		<>
			{showModal && (
				<div className={styles.consentModalSection}>
					<div className={styles.consentModal}>
						<Button icon className={styles.consentCloseBtn} onClick={handleDismiss}>
							<img src={cancelIcon} className={styles.closeButton} alt="close" />
						</Button>
						<div className={styles.modalHeader}>{modalHeader}</div>
						<hr />
						<div className={styles.modalBody}>
							<div className={styles.modalContent}>{consentMessage}</div>
							<div className={styles.modalButton}>
								<Button
									type="button"
									success
									className={`${styles.closeBtn} ${styles.btn} fluid`}
									onClick={handleConsent}
								>
									ACCEPT
								</Button>
							</div>
							<div className={styles.consentText}>{CONSENT_TEXT}</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default LegalConsentModal;
