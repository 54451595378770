import React, { useEffect, useRef, useState } from 'react';
import MinifiedHeader from '../minifiedheader/MinifiedHeader';
import styles from './Header.module.scss';

interface IHeaderProps {
	restaurantInfo: string;
	restaurantAddress?: string;
	restaurantContact?: string;
	restaurantImage?: string;
	restaurantLogoImage?: string;
	id: string;
}

const Header: React.FC<IHeaderProps> = ({
	restaurantInfo,
	restaurantAddress,
	restaurantContact,
	restaurantImage,
	restaurantLogoImage,
	...props
}: IHeaderProps) => {
	const [isPageScrolled, setIsPageScrolled] = useState<boolean>(false);
	const imgRef = useRef<HTMLElement>(null);
	const HEADER_SCROLL_YLENGTH = 30;

	const listenToScroll = () => {
		const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
		if (imgRef.current && winScroll > imgRef.current.clientHeight - HEADER_SCROLL_YLENGTH) {
			setIsPageScrolled(true);
		} else {
			setIsPageScrolled(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', listenToScroll);
		return () => window.removeEventListener('scroll', listenToScroll);
	}, []);

	return (
		<>
			{restaurantImage && restaurantImage?.length > 0 ? (
				<>
					<header style={{ backgroundImage: `URL(${restaurantImage})` }} {...props} ref={imgRef}>
						{restaurantLogoImage && (
							<div className={styles.logo} id={props.id}>
								<img src={restaurantLogoImage} className={styles.storeLogo} alt={restaurantInfo} />
							</div>
						)}
					</header>
					<div className={styles.header}>
						{isPageScrolled ? (
							<div className={styles.fixedHeader} {...props}>
								<div className={styles.logo}>
									{restaurantLogoImage && (
										<img
											src={restaurantLogoImage}
											className={styles.menuLogo}
											alt={restaurantInfo}
										/>
									)}
									<div className={styles.navbarRight}>{restaurantInfo}</div>
								</div>
							</div>
						) : (
							<div className={styles.restaurantDetails}>
								{restaurantInfo && <h1 className={styles.restaurantName}>{restaurantInfo}</h1>}
								{restaurantContact && (
									<h6 className={styles.restaurantAddress}>
										{restaurantAddress}
										<span className={styles.restaurantContact}>{restaurantContact}</span>
									</h6>
								)}
							</div>
						)}
					</div>
				</>
			) : (
				<>
					<MinifiedHeader
						restaurantName={restaurantInfo}
						restaurantLogoImage={restaurantLogoImage ? restaurantLogoImage : ''}
					/>
				</>
			)}
		</>
	);
};

export default Header;
