import React from 'react';
import styles from './MinifiedHeader.module.scss';

interface IMinifiedHeaderProps {
	restaurantName: string;
	restaurantLogoImage?: string;
}

const MinifiedHeader: React.FC<IMinifiedHeaderProps> = ({
	restaurantName,
	restaurantLogoImage,
	...props
}: IMinifiedHeaderProps) => (
	<>
		<header className={styles.fixedHeader} {...props}>
			<div className={styles.logo}>
				{restaurantLogoImage && (
					<img src={restaurantLogoImage} className={styles.menuLogo} alt={restaurantName} />
				)}
				<div className={styles.navbarRight}>{restaurantName}</div>
			</div>
		</header>
	</>
);

export default MinifiedHeader;
