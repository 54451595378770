import React, { useEffect, useState, useContext } from 'react';
import { Button, Divider } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import checkedWithGreenBg from '../../../assets/checkedWithGreenBg.svg';
import shift4Logo from '../../../assets/shift4Logo.svg';
import styles from './OrderConfirmView.module.scss';
import Toast from '../../components/toast/Toast';
import AppContext, { IAppContext } from '../../Contexts/AppContext/AppContext';
import ViewTicketDetailPageContext, {
	IViewTicketDetailPageContext,
} from '../../Contexts/ViewTicketPageContext/ViewTicketPageContext';

import OrderConfirmPageContext, {
	IOrderConfirmContext,
} from '../../Contexts/OrderConfirmPageContext/OrderConfirmPageContext';

import LoaderIcon from '../../components/loaderIcon/LoaderIcon';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';
import {
	INVALID_TICKET_ERROR_MESSAGE,
	PARTIAL_PAYMENT_ERROR_MESSAGE,
	SESSION_NOT_FOUND_ERROR_MESSAGE,
	TICKET_NOT_FOUND_ERROR_MESSAGE,
} from './labels';
import PreAuthPageContext, { IPreAuthItemContext } from '../../Contexts/PreAuthPageContext/PreAuthPageContext';

interface IProps {
	match: {
		params: {
			storeId: string;
		};
	};
}

const OrderConfirmView: React.FC<IProps> = (props) => {
	const history = useHistory();
	const {
		isValidSession,
		setIsValidSession,
		setValidSessionId,
		checkSessionData,
		validSessionId,
		toastMessage,
		updateToastMessage,
	} = useContext(AppContext) as IAppContext;
	const { getQRPayHost, payRedirectError } = useContext(OrderConfirmPageContext) as IOrderConfirmContext;
	const { ticketDetails, ticketError, setShouldRedirectToViewCheck, setTicketDetails } = useContext(
		ViewTicketDetailPageContext
	) as IViewTicketDetailPageContext;
	const { merchantData, updateMerchantDetail } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const { setIsActiveSession } = useContext(PreAuthPageContext) as IPreAuthItemContext;

	const [payView, setPayView] = useState<boolean>(false);
	const [showLoader, setShowLoader] = useState<boolean>(false);
	const { storeId } = props.match.params;
	const isQrPayEnabled = localStorage.getItem('isQRPayEnabled') === 'true';
	const customerName = localStorage.getItem('customerName');
	const tableRefId = localStorage.getItem('tableRef');
	const merchantInfo = localStorage.getItem('merchantLogo');
	const isSessionAvailable = localStorage.getItem('storedSessionId');
	const shopName = localStorage.getItem('merchantName');
	const orderConfirmationMessage =
		merchantData?.orderConfirmationMessage || localStorage.getItem('orderConfirmationMessage');
	const { getTicket } = useContext(ViewTicketDetailPageContext) as IViewTicketDetailPageContext;

	useEffect(() => {
		if (payRedirectError || ticketError) {
			const apiError = payRedirectError ? payRedirectError : ticketError;
			if (apiError && apiError.code === 'USER_SESSION_NOT_FOUND') {
				updateToastMessage(SESSION_NOT_FOUND_ERROR_MESSAGE);
			} else if (apiError && apiError.code === 'USER_SESSION_INVALID_TICKET') {
				updateToastMessage(INVALID_TICKET_ERROR_MESSAGE);
			} else if (apiError && apiError.code === 'PARTIAL_PAYMENT_EXISTS') {
				updateToastMessage(PARTIAL_PAYMENT_ERROR_MESSAGE);
			} else {
				updateToastMessage(TICKET_NOT_FOUND_ERROR_MESSAGE);
			}
			setShowLoader(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payRedirectError, ticketError]);

	const handleOrderView = () => {
		setShouldRedirectToViewCheck(false);
		setIsActiveSession(false);
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};
	const handlePayView = async () => {
		setShowLoader(true);
		setTicketDetails({});
		if (isSessionAvailable) {
			getTicket(storeId, isSessionAvailable, false);
			setPayView(true);
		}
	};

	useEffect(() => {
		if (payView && ticketDetails?.amountPaid) {
			if (Number(ticketDetails?.amountPaid > 0)) {
				updateToastMessage(PARTIAL_PAYMENT_ERROR_MESSAGE);
				return;
			}
			if (isValidSession && validSessionId) {
				getQRPayHost(storeId, validSessionId);
			}
			setPayView(false);
			setShowLoader(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketDetails, payView, isValidSession]);

	useEffect(() => {
		const smsCode = localStorage.getItem('clientCode') || '';
		if (!isSessionAvailable) {
			if (tableRefId && validSessionId) {
				checkSessionData(storeId, validSessionId, smsCode, tableRefId);
			}
		} else {
			setIsValidSession(true);
			setValidSessionId(isSessionAvailable);
		}
		updateMerchantDetail(storeId);
		localStorage.removeItem('orderDetail');
		updateToastMessage('');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<section id="orderConfirmContainer" className={`${styles.orderConfirmation} Container`}>
				<section className={`${styles.orderPage} `}>
					<div className={styles.logo}>
						{merchantInfo && (
							<img src={merchantInfo} className={`${styles.restaurantLogo}`} alt="restaurant name " />
						)}
					</div>

					<h3 className={styles.restaurantName}>{shopName}</h3>
					<Divider className={styles.divider}></Divider>
					{!history?.location?.state?.orderPlaced && (
						<div className={styles.confirmationBlock}>
							<img src={checkedWithGreenBg} className={styles.successIcon} alt="successIcon"></img>
							<div className={styles.successMsg}>
								Thank you {customerName}. {orderConfirmationMessage}
							</div>
						</div>
					)}
					<div className={styles.orderActions}>
						<Button
							id="orderMoreBtn"
							type="button"
							primary
							className={`${styles.orderMoreBtn} ${styles.btn} fluid`}
							onClick={handleOrderView}
						>
							Order More
						</Button>
						{isQrPayEnabled && (
							<Button
								type="button"
								id="payCheckButton"
								positive
								className={`${styles.payBtn}  ${styles.btn} fluid`}
								onClick={handlePayView}
							>
								{showLoader && <LoaderIcon />}Pay Check
							</Button>
						)}
						{toastMessage?.length > 0 && <Toast message={toastMessage} />}
					</div>
				</section>
				<img src={shift4Logo} className={`${styles.shift4Logo} ${styles.fixedBottom}`} alt="Shift4 logo" />
			</section>
		</>
	);
};

export default OrderConfirmView;
