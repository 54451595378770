import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import SelectTicketView from '../selectTicketView/SelectTicketView';
import Footer from '../../components/footer/Footer';
import ErrorModal from '../../components/errormodal/ErrorModal';
import Spinner from '../../components/spinner/Spinner';
import lessThanIcon from '../../../assets/lessThanIcon.svg';
import ViewTicketDetailPageContext, {
	IViewTicketDetailPageContext,
} from '../../Contexts/ViewTicketPageContext/ViewTicketPageContext';

import { IModifierDetails, IModifierSetDetails, IOrderDetailItems } from '../home/models/orderDetailModels';

import Toast from '../../components/toast/Toast';
import AppContext, { IAppContext } from '../../Contexts/AppContext/AppContext';
import OrderDetailPageContext, {
	IOrderDetailContext,
} from '../../Contexts/OrderDetailPageContext/OrderDetailPageContext';
import { getFormattedPrice } from '../../components/helper/common';
import * as labels from './labels';
import orderDetailStyles from './OrderDetail.module.scss';
import { constructPlaceOrderRequestBody } from '../../utils/orderUtils';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';

interface IProps {
	match: {
		params: {
			storeId: string;
		};
	};
}

const OrderDetail: React.FC<IProps> = (props) => {
	const history = useHistory();
	const [isOrderDetailViewActive, setIsOrderDetailViewActive] = useState(true);
	const [apiErrorMessage, setApiErrorMessage] = useState({ MESSAGE: '', TITLE: '', STATUS: 0 });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const {
		isValidSession,
		sessionErrorStatus,
		ticketRefDetail,
		setTicketRefDetail,
		toastMessage,
		updateToastMessage,
		showLoaderIcon,
		setShowLoaderIcon,
	} = useContext(AppContext) as IAppContext;
	const { getTicket } = useContext(ViewTicketDetailPageContext) as IViewTicketDetailPageContext;
	const { postOrderDetail } = useContext(OrderDetailPageContext) as IOrderDetailContext;
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const tableRefId = localStorage.getItem('tableRef');
	const merchantLogo = localStorage.getItem('merchantLogo');
	const [orderDetail, setOrderDetail] = useState({
		shopName: '',
		customerName: '',
		table: '',
		order: {
			items: [
				{
					menuItemGuid: '',
					specialRequest: '',
					itemName: '',
					itemPrice: '',
					quantity: 1,
					modSets: [],
				},
			],
			totalPrice: '',
		},
	});
	const { storeId } = props.match.params;
	const order = localStorage.getItem('orderDetail');
	const existingSessionId = localStorage.getItem('storedSessionId');
	const existingTicketRef = localStorage.getItem('ticketRefDetail');
	const existingTableRef = localStorage.getItem('tableRef');

	useEffect(() => {
		if (!existingTableRef && !order) {
			setShowLoaderIcon(false);
			setApiErrorMessage(labels.BROWSER_ERRORS);
		}
		if (order) {
			localStorage.setItem('cartItems', JSON.stringify(JSON.parse(order).order));
		}
		if (existingTicketRef) {
			setTicketRefDetail(JSON.parse(existingTicketRef));
		}
		updateToastMessage('');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (orderDetail?.order?.items?.length === 0) {
			localStorage.removeItem('orderDetail');
			localStorage.removeItem('cartItems');
			localStorage.removeItem('ticketRefDetail');
			history.push(`/${storeId}/homePage`);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderDetail]);

	useEffect(() => {
		if (order) {
			setOrderDetail(JSON.parse(order));
			if (ticketRefDetail?.tickets && ticketRefDetail?.tickets?.length > 0) {
				setIsOrderDetailViewActive(false);
			} else {
				setIsOrderDetailViewActive(true);
			}
		}

		if (!isValidSession && sessionErrorStatus) {
			const apiError = labels.API_ERRORS;
			const apiErrorIndex = apiError.findIndex((error) => error.STATUS === sessionErrorStatus);
			if (apiErrorIndex !== -1) {
				setApiErrorMessage(apiError[apiErrorIndex]);
				return;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketRefDetail]);

	const placeOrder = async () => {
		setIsLoading(true);
		if (orderDetail) {
			const orderDetailRequestBody = constructPlaceOrderRequestBody(orderDetail);
			const orderDetailResponseObject = await postOrderDetail(storeId, orderDetailRequestBody);
			if (orderDetailResponseObject?.error && [500, 400, 404].includes(orderDetailResponseObject.error.status)) {
				updateToastMessage(orderDetailResponseObject.error.message);
			} else {
				const selectedPreviousTicketRef = localStorage.getItem('selectedPreviousTicketRef');
				if (!selectedPreviousTicketRef && orderDetailResponseObject.order?.ticketGuid) {
					localStorage.setItem('selectedPreviousTicketRef', orderDetailResponseObject.order.ticketGuid);
				}
				localStorage.removeItem('cartItems');
				if (existingSessionId) {
					getTicket(storeId, existingSessionId, false);
				}
				history.push(`/${storeId}/OrderConfirmView`);
			}
		}
		setTimeout(() => setIsLoading(false), 3000);
	};
	const handleBackClick = () => {
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	const modifiersDom = (modifiers: IModifierDetails, modifiersDomIndex: number) => {
		return (
			<div key={modifiersDomIndex}>
				{modifiers.menuModGuid && modifiers.menuModName && (
					<div className={orderDetailStyles.ticketRow}>
						<div className={orderDetailStyles.modifiersDomLabel}>{modifiers.menuModName}</div>
						<div className={orderDetailStyles.ticketRowValue}>
							{Number(modifiers.modifierPrice) !== 0 &&
								merchantData?.currency &&
								`+${getFormattedPrice(Number(modifiers.modifierPrice), merchantData?.currency)}`}
						</div>
					</div>
				)}
				{modifiers.modSets &&
					modifiers.modSets?.length >= 0 &&
					modifiers.modSets.map((modifierSet3: IModifierSetDetails, modifierSet3Index: number) => {
						return modifierSetDom(modifierSet3, modifierSet3Index);
					})}
			</div>
		);
	};

	const modifierSetDom = (modifierSet: IModifierSetDetails, modifierSetIndex: number) => {
		return (
			<div className={orderDetailStyles.modifierSetDomContainer} key={modifierSetIndex}>
				<div className={orderDetailStyles.ticketRow}>
					<div className={orderDetailStyles.modifierSetDomLabel}>{modifierSet.menuModSetName}</div>
				</div>

				{modifierSet?.mods &&
					modifierSet?.mods?.length >= 0 &&
					modifierSet?.mods.map((modifierSet2: IModifierDetails, modifiersDomIndex: number) => {
						return modifiersDom(modifierSet2, modifiersDomIndex);
					})}
			</div>
		);
	};

	const itemsRenderDom = (itemDetails: IOrderDetailItems, itemDetailIndex: number) => {
		return (
			<Fragment key={itemDetailIndex}>
				<div className={orderDetailStyles.ticketRow}>
					<div className={orderDetailStyles.ticketRowLabelBold}>
						{itemDetails.quantity} {itemDetails.itemName}
					</div>
					<div className={orderDetailStyles.ticketRowValue}>
						{merchantData?.currency &&
							getFormattedPrice(Number(itemDetails.itemPrice), merchantData?.currency)}
					</div>
				</div>
				{itemDetails.modSets &&
					itemDetails.modSets?.length >= 0 &&
					itemDetails.modSets.map(
						(itemDetailsModifierSet: IModifierSetDetails, itemDetailsModifierSetIndex: number) => {
							return modifierSetDom(itemDetailsModifierSet, itemDetailsModifierSetIndex);
						}
					)}

				<div className={orderDetailStyles.removeIcon}>
					<Button
						className={orderDetailStyles.link}
						onClick={() => handleRemoveItem(itemDetails, itemDetailIndex)}
					>
						Remove
					</Button>
				</div>
			</Fragment>
		);
	};

	const handleErrorClick = () => {
		if (sessionErrorStatus === 404) {
			history.push(`/${storeId}/startSession`);
		} else {
			history.push(`/${storeId}/homePage`);
		}
	};

	const handleRemoveItem = (removeItem: IOrderDetailItems, itemDetailIndex: number) => {
		let modifierPrice = 0;
		const itemsInCart = localStorage.getItem('cartItems');
		const merchantName = localStorage.getItem('merchantName');
		const tableRef = localStorage.getItem('tableRef');

		if (itemsInCart) {
			let pendingItems = JSON.parse(itemsInCart);
			let updatedPrice = Number(pendingItems.totalPrice);
			const itemQuantity = Number(removeItem.quantity);
			let removedItemPrice = Number(removeItem.itemPrice);
			removedItemPrice = removedItemPrice * itemQuantity;
			if (pendingItems?.items[itemDetailIndex]?.menuItemGuid === removeItem.menuItemGuid) {
				pendingItems?.items?.splice(itemDetailIndex, 1);
			}
			pendingItems = pendingItems.items;
			const modLevel = (modLevelItem: IModifierDetails[]) => {
				modLevelItem.forEach((item2: IModifierDetails) => {
					modifierPrice = modifierPrice + Number(item2.modifierPrice) * itemQuantity;

					if (item2.modSets) {
						modsetLevel(item2.modSets);
					}
				});
			};
			const modsetLevel = (modsetLevelItem: IModifierSetDetails[]) => {
				modsetLevelItem.forEach((item1: IModifierSetDetails) => {
					if (item1.mods) {
						modLevel(item1.mods);
					}
				});
			};
			if (removeItem.modSets) {
				modsetLevel(removeItem.modSets);
			}
			updatedPrice = updatedPrice - removedItemPrice - modifierPrice;
			const updatedOrderDetail = JSON.stringify({
				order: { totalPrice: updatedPrice, items: pendingItems },
				shopName: merchantName,
				table: tableRef,
			});
			localStorage.setItem('orderDetail', updatedOrderDetail);
			setOrderDetail(JSON.parse(updatedOrderDetail));
			localStorage.setItem('cartItems', JSON.stringify({ totalPrice: updatedPrice, items: pendingItems }));
		} else {
			localStorage.removeItem('orderDetail');
			localStorage.removeItem('cartItems');
			localStorage.removeItem('storedSessionId');
			history.push(`/${storeId}/homePage`);
		}
	};

	return isOrderDetailViewActive ? (
		<>
			{showLoaderIcon ? (
				<Spinner />
			) : apiErrorMessage.MESSAGE?.length > 0 ? (
				<ErrorModal
					handleDismiss={handleErrorClick}
					typeOfError={apiErrorMessage.TITLE}
					errorMsg={apiErrorMessage.MESSAGE}
					showButton={true}
				/>
			) : (
				<>
					<div className={orderDetailStyles.orderDetailContainer} id="orderDetailContainer">
						<div className={orderDetailStyles.orderDetailHeading}>
							<div className={orderDetailStyles.backButton}>
								<Button
									icon
									className="float left backButton"
									onClick={handleBackClick}
									disabled={isLoading}
								>
									<img src={lessThanIcon} className={orderDetailStyles.backButton} alt="back" />
								</Button>
							</div>
							<div className={orderDetailStyles.headingTitle}>Order Details</div>
						</div>
						<div className={orderDetailStyles.divider}></div>
						<div className={orderDetailStyles.detailContainer}>
							<div className={orderDetailStyles.ticketContainer}>
								<div className={orderDetailStyles.ticketRow}>
									<div className={orderDetailStyles.ticketRowTableLabelBold}>
										{orderDetail.shopName}
									</div>
								</div>
							</div>
							<div className={orderDetailStyles.divider} />
							<div className={orderDetailStyles.itemDetailsContainer}>
								<div className={orderDetailStyles.menuItemsTitle}>Menu Items:</div>
								{orderDetail?.order?.items?.map(
									(itemDetail: IOrderDetailItems, itemDetailIndex: number) => {
										return itemsRenderDom(itemDetail, itemDetailIndex);
									}
								)}
							</div>
							<div className={orderDetailStyles.divider} />
							<div className={orderDetailStyles.ticketContainer}>
								<div className={orderDetailStyles.ticketRow}>
									<div className={orderDetailStyles.ticketRowTableLabelBold}> ITEM TOTAL:</div>
									<div className={orderDetailStyles.ticketRowTableBoldValue}>
										{merchantData?.currency &&
											getFormattedPrice(
												Number(orderDetail?.order?.totalPrice),
												merchantData?.currency
											)}
									</div>
								</div>
							</div>
						</div>
						<div className={orderDetailStyles.footer}>
							<Footer
								id="Place Order"
								externalStyle={orderDetailStyles.placeOrderBackground}
								buttonColor={'Blue'}
								loaderStatus={isLoading}
								labelText="Place Order"
								showQuantityDetail={false}
								addToOrderInnerWithoutParams={placeOrder}
							/>
						</div>
					</div>
					{toastMessage?.length > 0 && <Toast message={toastMessage} />}
				</>
			)}
		</>
	) : (
		<SelectTicketView storeId={storeId} merchantName={orderDetail.shopName} merchantLogoUrl={merchantLogo} />
	);
};
export default OrderDetail;
