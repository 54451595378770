import React, { Fragment, useEffect, useState } from 'react';
import styles from './MenuDropdown.module.scss';
import selected from '../../../assets/selected.svg';
import caretDown from '../../../assets/caretDown.svg';
import { IMenuSchedule } from '../../pages/home/models/menuItemModels';
import { TransitionablePortal, Message, Modal } from 'semantic-ui-react';

export interface IMenuDropdownProps {
	options: IMenuSchedule[] | undefined;
	changeSchedule: (scheduleName: string) => void;
	menuName: string;
}

const MENU_ANIMATION_TIME = 375;
const MenuDropdown: React.FC<IMenuDropdownProps> = (props: IMenuDropdownProps) => {
	const [selectedMenu, setSelectedMenu] = useState<string>(props.menuName);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isTransitionOpen, setIsTransitionOpen] = useState(false);
	useEffect(() => {
		setSelectedMenu(props.menuName);
	}, [props.menuName]);
	return (
		<>
			<button
				className={styles.menuText}
				onClick={() => {
					setIsModalOpen(true);
					setIsTransitionOpen(true);
				}}
			>
				{selectedMenu}
				<img src={caretDown} alt="caretDown" className={styles.caretDownSpace} />
			</button>

			<TransitionablePortal
				open={isTransitionOpen}
				transition={{ animation: 'fade up', duration: MENU_ANIMATION_TIME }}
			>
				<Modal
					open={isModalOpen}
					onClose={() => {
						setIsTransitionOpen(false);
						setIsModalOpen(false);
					}}
					onOpen={() => setIsModalOpen(true)}
					className={`${styles.modalMenu} ${styles.ui} ${styles.modal}`}
				>
					<Message className={`${styles.optionRow} ${styles.ui} ${styles.message}`}>
						<Message.Content>
							<div className={styles.headingMenu}>Menus</div>
						</Message.Content>
					</Message>
					<div className={styles.divider} />
					{props.options?.map((schedule, index) => (
						<Fragment key={index}>
							<Message
								icon
								className={`${styles.optionRow} ${styles.ui} ${styles.message}`}
								onClick={() => {
									setIsTransitionOpen(false);
									setIsModalOpen(false);
									setSelectedMenu(schedule.name);
									props.changeSchedule(schedule.name);
								}}
							>
								<Message.Content>
									<div className={styles.optionHeadMenu}>{schedule.name}</div>
									<div className={styles.optionMenu}>
										{schedule.startTime} - {schedule.endTime}
									</div>
								</Message.Content>
								{schedule.name === selectedMenu && <img src={selected} alt="selected" />}
							</Message>
							<div
								className={`${styles.divider} ${
									schedule.name === selectedMenu && styles.dividerSelected
								}`}
							/>
						</Fragment>
					))}
				</Modal>
			</TransitionablePortal>
		</>
	);
};
export default MenuDropdown;
