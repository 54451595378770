import { createContext } from 'react';
import { IMerchantData } from '../../pages/home/models/merchantModels';
import { IMenuSchedule, ISchedule, ISchedulesCategory } from '../../pages/home/models/menuItemModels';

export interface IMenuItemContext {
	updateMerchantDetail: (locationId: string) => void;
	merchantData?: IMerchantData | undefined;
	getMenuDetail: (locationId: string) => void;
	scheduleCategories: ISchedulesCategory[];
	activeCategory: string;
	menuListSchedule: IMenuSchedule[] | undefined;
	menuName: string;
	menuItemDetail: ISchedule[];
	setScheduleCategories: React.Dispatch<React.SetStateAction<ISchedulesCategory[]>>;
	setActiveCategory: React.Dispatch<React.SetStateAction<string>>;
	setMenuName: React.Dispatch<React.SetStateAction<string>>;
}

export const MenuItemsPageContext: React.Context<IMenuItemContext | null> = createContext<IMenuItemContext | null>(
	null
);
export default MenuItemsPageContext;
