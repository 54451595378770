// Home page constants
export const SECTION_PADDING = 10;
export const SECTION_BUFFER_OFFSET = 285;
export const MENU_DROPDOWN_YLENGTH = 60;
export const CATEGORY_NAV_LENGTH = 46;
export const MENU_NAVIGATION_MARGIN = 15;
export const MENU_SCROLL_MODIFIER_BUFFER_OFFSET = 170;
export const FIRST_CATEGORY_NAVIGATION_ALIGN = -150;

// ItemOrder page constants
export const HEADER_DEFAULT_HEIGHT = 432;
export const MINIFIED_HEADER_DEFAULT_HEIGHT = 132;
export const SCROLL_MODIFIER_BUFFER_OFFSET = 2;
export const NAVIGATION_MARGIN = 10;
export const SPECIAL_INSTRUCTION_HEIGHT = 214;
export const SPECIAL_INSTRUCTION_BUFFER_PADDING = 300;
export const SPECIAL_INSTRUCTION_SCROLL_BUFFER = 128;

//Customer Details page
export const MIN_PHONE_NUMBER_LENGTH = 10;
export const MAX_PHONE_NUMBER_LENGTH = 12;

// Pre-Auth Details Page
export const ZIP_CODE_COUNTRY_CODES = 'US';
export const CONSENT_MODAL_HEADER = 'Terms and Agreements';
export const CONSENT_TEXT = 'By clicking the Accept button, you agree to the Payment Agreement and Default Tip Policy.';
export const CONSENT_MODAL_TIME = 5000;
