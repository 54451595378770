export const cssRules = `
	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
	* { box-sizing: border-box; }
	body {
		background: #f8f9fa;
		margin: 0;
		font-size: 14px;
		line-height: 1.4285em;
		color: rgba(0, 0, 0, 0.87);
		font-family: 'Open Sans', sans-serif;
		position: relative;
	}
	input, select {
		width: 100%;
		height: 48px;
		margin: 0 0 1em 0;
		padding: 11px;
		outline: none;
		-webkit-appearance: none;
		tap-highlight-color: rgba(255, 255, 255, 0);
		line-height: 1.21428571em;
		background: #FFFFFF;
		border: 1px solid rgba(34, 36, 38, 0.15);
		color: #121212;
		border-radius: 0.28571429rem;
		-webkit-box-shadow: 0 0 0 0 transparent inset;
		box-shadow: 0 0 0 0 transparent inset;
		-webkit-transition: color 0.1s ease, border-color 0.1s ease;
		transition: color 0.1s ease, border-color 0.1s ease;
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 26px;
		align-items: center;
	}
	input[type="text"]:not(.error):focus,
	input[type="tel"]:not(.error):focus,
	select:not(.error):focus {
		color: rgba(0, 0, 0, 0.95);
		border-color: #85B7D9;
		border-radius: 0.28571429rem;
		background: #FFFFFF;
		-webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 35%) inset;
		box-shadow: 0 0 0 0 rgb(34 36 38 / 35%) inset;
	}
	input[type="text"]:not(.error):focus {
		max-height: 100%;
  		max-width: 100%;
	}
	label {
		display: block;
		margin: 0 0 0.28571429rem 0;
		color: #1A1A1A;
		font-size: 12px;
		font-weight: 600;
		text-transform: none;
		line-height: 21px;
	}
	a { color: #0078d4; text-decoration: none; }
	a:hover { color: #006abb; }
	/* Button styles */
	input[type="submit"] {
			cursor: pointer;
			display: block;
			min-height: 1em;
			outline: none;
			border: none;
			vertical-align: baseline;
			font-family: 'Open Sans', sans-serif;
			margin: 0;
			padding: 0.78571429em 1.5em 0.78571429em;
			text-transform: none;
			font-weight: bold;
			line-height: 1em;
			font-style: normal;
			text-align: center;
			text-decoration: none;
			border-radius: 0.28571429rem;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-webkit-transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
			transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
			transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
			transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
			will-change: '';
			-webkit-tap-highlight-color: transparent;
			color: #FFFFFF;
			text-shadow: none;
			background-image: none;
			-webkit-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
			box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
	}
	input[type="submit"] { background-color: #0078d4; }
	input[type="submit"]:hover { background-color: #006abb; }
	input[type="submit"]:focus { background-color: #0061ab; }
	input[type="submit"]:active { background-color: #006abb; }
	input.error, select.error { border-color: #E31C1C;}
	input.error, select.error { margin-bottom: 0.25em; }
	label.error { color: #E31C1C; margin-bottom: 1em; line-height: 16px; font-weight: 400; }
	select:nth-child(2) { width: 50%; }
	select:nth-child(3) { width: 48%; float: right; }
	div[helpactive="1"] { margin-top: -0.75em; margin-bottom: 1em; }
	#i4go_cvv2Code-label small {
		color: transparent;
	}
	#i4go_cvv2Code-label a {
		color: #F04438;
		text-decoration: underline;
	}
	#i4go_submit-label, .container ~ p { display: none; }
	.ui-widget.ui-widget-content { border: none !important; }
	::-webkit-input-placeholder { color: #848484; }
	::-moz-placeholder { color: #848484; }
	:-ms-input-placeholder { color: #848484; }
	input::placeholder { color: #848484; }
	.cardHolderName {
		border-bottom: 1px solid #cccccc;
	}

	.cardNumber {
		margin-top: 10px;
	}

`;
