export const REQUIRED = 'Required';
export const OPTIONAL = 'Optional';

export interface IModifierSet {
	ref: string;
	name: string;
	description: string | null;
	minModifiersPerSet: number | null;
	maxModifiersPerSet: number | null;
	available: boolean;
}

export interface IModifier {
	ref: string;
	name: string;
	available: boolean;
	price: number;
}

export interface IItemsModifier {
	modifierRef: string;
	taxRefs?: string[];
	price?: number;
	modifierSets?: IItemsModifierSet[];
	modifierDetails?: IModifier;
	key: string;
}

export interface IItemsModifierSet {
	modifierSetRef: string;
	modifiers?: IItemsModifier[];
	modifierSetDetails?: IModifierSet;
	key: string;
	hasModifiers: boolean;
}

export interface ISelectedModifier {
	modifierName: string;
	modifierValue: string | string[];
}

/**
 * This Method returns string Required and Optional based on minimum modifiers and maximum modifiers per set
 * @param min - minimum modifier per set
 * @returns Required/Optional
 */
export const isModifierRequiredOrOptional = (min: number): string => {
	if (min && min > 0) {
		return REQUIRED;
	}
	return OPTIONAL;
};

/**
 * This Method returns message choose of modifiers
 * @param min - minimum modifier per set
 * @param max - maximum modifier per set
 * @param totalModifierAvailable  - total modifiers available per set
 * @returns based on condition returns message choose of modifiers
 */
export const messageToChoose = (min: number, max: number, totalModifierAvailable: number): string => {
	// min and max definitions are pre validated before this call in itemOrderUtils
	let maxModifierSet = max;
	if (max >= totalModifierAvailable) {
		maxModifierSet = totalModifierAvailable;
	}

	if (min && maxModifierSet && min === maxModifierSet) {
		return `Choose ${maxModifierSet}`;
	} else if (min && maxModifierSet && min < maxModifierSet) {
		return `Choose ${min} to ${maxModifierSet}`;
	} else if (maxModifierSet) {
		return `Choose up to ${maxModifierSet}`;
	}
	return '';
};
