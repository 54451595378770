import { apiCall } from '../api/apiCall';
import { isPreAuthEnabled } from '../utils/homeUtils';

/**
 * This below function call the api for checking session detail
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {string} sessionId - session id received from BE sent URL.
 */
// eslint-disable-next-line
export const getQRPay = async (locationId: string, sessionId: string): Promise<any> => {
	if (isPreAuthEnabled()) {
		return await apiCall(`/api/v2/merchants/${locationId}/order/${sessionId}/pay`, 'GET');
	}
	return await apiCall(`/api/v1/merchants/${locationId}/order/${sessionId}/pay`, 'GET');
};
