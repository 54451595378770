import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import MenuItemWithBadges from '../../components/menuitemswithbadges/MenuItemsWithBadges';
import MenuItemWithoutBadges from '../../components/menuitemwithoutbadges/MenuItemsWithoutBadges';
import styles from '../../components/categories/Categories.module.scss';
import warning from '../../../assets/warning.svg';
import { Loader } from '../../components/loader/Loader';
import { IItem, ISchedulesCategory } from './models/menuItemModels';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';
import ViewTicketDetailPageContext, {
	IViewTicketDetailPageContext,
} from '../../Contexts/ViewTicketPageContext/ViewTicketPageContext';
import AppContext, { IAppContext } from '../../Contexts/AppContext/AppContext';
import Toast from '../../components/toast/Toast';
import MenuDropdown from '../../components/menudropdown/MenuDropdown';
import { getSelectedMenuItemIndex } from '../../utils/menuItemUtils';
import {
	REVIEW_PLACE_ORDER,
	VIEW_CHECK_BUTTON,
	MENU_UNAVAILABLE_MESSAGE,
	INVALID_GUID_ERROR,
	INVALID_TICKET_ERROR_MESSAGE,
	PAY_NOW,
} from './labels';
import homeStyles from './Home.module.scss';
import ErrorModal from '../../components/errormodal/ErrorModal';
import {
	CATEGORY_NAV_LENGTH,
	FIRST_CATEGORY_NAVIGATION_ALIGN,
	MENU_DROPDOWN_YLENGTH,
	MENU_NAVIGATION_MARGIN,
	MENU_SCROLL_MODIFIER_BUFFER_OFFSET,
	SECTION_BUFFER_OFFSET,
	SECTION_PADDING,
} from '../../constants/constants';
import { isValidGuid } from '../../utils/homeUtils';
import * as labels from '../orderDetails/labels';
import PreAuthPageContext, { IPreAuthItemContext } from '../../Contexts/PreAuthPageContext/PreAuthPageContext';
import usePreAuthScripts from '../../hooks/usePreAuthScripts';
import OrderConfirmPageContext, {
	IOrderConfirmContext,
} from '../../Contexts/OrderConfirmPageContext/OrderConfirmPageContext';
import {
	PARTIAL_PAYMENT_ERROR_MESSAGE,
	SESSION_NOT_FOUND_ERROR_MESSAGE,
	TICKET_NOT_FOUND_ERROR_MESSAGE,
} from '../viewTickets/labels';
import { getCurrencySymbol } from '../../components/helper/common';

interface IProps {
	match: {
		params: {
			storeId: string;
		};
	};
}

const Home: React.FC<IProps> = (props) => {
	const history = useHistory();
	const [showLoader, setShowLoader] = useState<boolean>(true);
	const [selectedCategory, setSelectedCategory] = useState<string>();
	const [isPendingItemInCart, setIsPendingItemInCart] = useState<boolean>(false);
	const [totalPriceInCart, setTotalPriceInCart] = useState<number>(0);
	const [categoryNavXPosition, setCategoryNavXPosition] = useState<number>(MENU_NAVIGATION_MARGIN);
	const [isGuidValid, setIsGuidValid] = useState<boolean>(true);
	const [isMenuOnlyMode, setIsMenuOnlyMode] = useState<boolean>(false);
	const [isPreAuthEnabled, setIsPreAuthEnabled] = useState<boolean>(false);
	const [isSessionFlowRedirect, setIsSessionFlowRedirect] = useState<boolean>(false);
	const [payView, setPayView] = useState<boolean>(false);
	const [showToastError, setShowToastError] = useState<boolean>(false);
	const [showPayLoader, setShowPayLoader] = useState<boolean>(false);
	const {
		getTicket,
		isCheckSessionValid,
		setIsCheckSessionValid,
		isCheckSessionLoading,
		ticketError,
		setTicketError,
		shouldRedirectToViewCheck,
		ticketDetails,
	} = useContext(ViewTicketDetailPageContext) as IViewTicketDetailPageContext;
	const { getQRPayHost, payRedirectError } = useContext(OrderConfirmPageContext) as IOrderConfirmContext;

	const {
		merchantData,
		scheduleCategories,
		getMenuDetail,
		menuListSchedule,
		menuName,
		setMenuName,
		menuItemDetail,
		setScheduleCategories,
	} = useContext(MenuItemsPageContext) as IMenuItemContext;
	usePreAuthScripts(isPreAuthEnabled, true);
	const {
		isValidSession,
		checkSessionData,
		toastMessage,
		updateToastMessage,
		itemScrollPosition,
		setItemScrollPosition,
		showLoaderIcon,
		sessionErrorStatus,
		setSessionErrorStatus,
		setIsClientTokenSet,
	} = useContext(AppContext) as IAppContext;
	const { isActiveSession } = useContext(PreAuthPageContext) as IPreAuthItemContext;

	const { storeId } = props.match.params;
	const { search, pathname } = useLocation();
	const existingTableRef = localStorage.getItem('tableRef');
	const hasTableRef = new URLSearchParams(search).has('tableRef');
	const tableRef = hasTableRef ? new URLSearchParams(search).get('tableRef') : existingTableRef;
	const merchantLogoUrl = merchantData?.logoUrl ? merchantData?.logoUrl : '';
	const merchantBannerImageUrl = merchantData?.bannerUrl ? merchantData?.bannerUrl : '';
	const existingSessionId = localStorage.getItem('storedSessionId');
	const [apiErrorMessage, setApiErrorMessage] = useState({ MESSAGE: '', TITLE: '', STATUS: 0 });
	const smsCode = localStorage.getItem('clientCode') || '';
	const isQrPayEnabled = merchantData?.isQRPayEnabled || localStorage.getItem('isQRPayEnabled') === 'true';

	if (merchantData) {
		localStorage.setItem('isQRPayEnabled', JSON.stringify(merchantData.isQRPayEnabled));
	}

	useEffect(() => {
		localStorage.setItem('menuPage', `${pathname}${search}`);
		getMenuDetail(storeId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [merchantData]);

	useEffect(() => {
		const redirectToHomepage = () => {
			window.location.href = 'https://www.shift4.com/';
		};
		const updateLocalStorage = () => {
			if (merchantData) {
				if (merchantData?.isPreAuthEnabled) {
					localStorage.setItem('preAuth', 'true');
					setIsPreAuthEnabled(true);
				} else {
					localStorage.removeItem('preAuth');
				}
				if (existingSessionId) {
					setIsCheckSessionValid(false);
					setShowLoader(true);
					getTicket(storeId, existingSessionId, false);
				}
			}
		};

		const handleTableRef = () => {
			if (tableRef) {
				if (existingTableRef && existingTableRef !== tableRef) {
					localStorage.clear();
				}
				if (!isValidGuid(tableRef.toString())) {
					setIsGuidValid(false);
					return;
				}
				localStorage.setItem('tableRef', tableRef);
			} else {
				setIsGuidValid(false);
			}
		};

		if (!storeId) {
			redirectToHomepage();
			return;
		}
		updateLocalStorage();
		handleTableRef();
		updateToastMessage('');
		setTicketError({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [merchantData, existingSessionId]);

	useEffect(() => {
		if (showToastError && (payRedirectError || ticketError)) {
			const apiError = payRedirectError ? payRedirectError : ticketError;
			if (apiError && apiError.code === 'USER_SESSION_NOT_FOUND') {
				updateToastMessage(SESSION_NOT_FOUND_ERROR_MESSAGE);
			} else if (apiError && apiError.code === 'USER_SESSION_INVALID_TICKET') {
				updateToastMessage(INVALID_TICKET_ERROR_MESSAGE);
			} else if (apiError && apiError.code === 'PARTIAL_PAYMENT_EXISTS') {
				updateToastMessage(PARTIAL_PAYMENT_ERROR_MESSAGE);
			} else {
				updateToastMessage(TICKET_NOT_FOUND_ERROR_MESSAGE);
			}
			setShowPayLoader(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payRedirectError, ticketError, showToastError]);

	useEffect(() => {
		if (payView && ticketDetails?.amountPaid) {
			if (Number(ticketDetails?.amountPaid > 0)) {
				updateToastMessage(PARTIAL_PAYMENT_ERROR_MESSAGE);
				setShowPayLoader(false);
				return;
			}
			if (isCheckSessionValid && existingSessionId) {
				getQRPayHost(storeId, existingSessionId);
			}
			setPayView(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketDetails, payView, isValidSession]);

	useEffect(() => {
		const itemInCart = localStorage.getItem('cartItems');
		if (itemInCart) {
			const { totalPrice, items } = JSON.parse(itemInCart);
			if (totalPrice >= 0 && items?.length > 0) {
				setTotalPriceInCart(totalPrice);
				setIsPendingItemInCart(true);
			}
		}
	}, []);

	useEffect(() => {
		if (menuItemDetail) {
			setMenuName(menuName);
			if (getSelectedMenuItemIndex(menuName, menuItemDetail) !== -1) {
				const activeCategories = menuItemDetail[
					getSelectedMenuItemIndex(menuName, menuItemDetail)
				].categories.filter((category: ISchedulesCategory) => category.categoryDetails?.available);
				setScheduleCategories(activeCategories);
				if (activeCategories.length > 0) {
					setSelectedCategory(activeCategories[0].categoryRef);
				}
			} else {
				setScheduleCategories([]);
				setSelectedCategory('');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuItemDetail]);

	// show loader while fetching data from merchant and menu endpoints
	// TODO: refactor the code while working on QOH API calls
	useEffect(() => {
		if (merchantData && menuListSchedule) {
			localStorage.setItem('merchantName', merchantData.name);
			document.title = merchantData.name ? `${merchantData.name} - SkyTab QR` : `SkyTab QR`;

			if (!existingSessionId) {
				setShowLoader(false);
				return;
			}

			if (shouldRedirectToViewCheck) {
				history.push({
					pathname: `/${storeId}/viewTicketDetail`,
					state: {
						storeId,
					},
				});
			}

			if (!isCheckSessionLoading) {
				if (isCheckSessionValid && isActiveSession) {
					history.push({
						pathname: `/${storeId}/OrderConfirmView`,
						state: {
							orderPlaced: true,
						},
					});
				} else {
					setShowLoader(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		merchantData,
		menuListSchedule,
		showLoader,
		isCheckSessionLoading,
		isCheckSessionValid,
		isActiveSession,
		shouldRedirectToViewCheck,
	]);

	useEffect(() => {
		if (!isCheckSessionValid && ticketError?.status && !showToastError) {
			const apiError = labels.PRE_AUTH_API_ERRORS;
			const apiErrorIndex = apiError.findIndex((error) => error.STATUS === ticketError?.status);
			if (apiErrorIndex !== -1) {
				setApiErrorMessage(apiError[apiErrorIndex]);
			}
		}
	}, [isCheckSessionValid, ticketError, isPreAuthEnabled, showToastError]);

	useEffect(() => {
		if (isSessionFlowRedirect && !showLoaderIcon) {
			if (isValidSession) {
				storeOrderDetails();
				history.push({
					pathname: `/${storeId}/orderDetail`,
					state: {
						storeId,
					},
				});
			} else if (sessionErrorStatus) {
				const apiError = labels.PRE_AUTH_API_ERRORS;
				const apiErrorIndex = apiError.findIndex((error) => error.STATUS === sessionErrorStatus);
				if (apiErrorIndex !== -1) {
					setApiErrorMessage(apiError[apiErrorIndex]);
				}
			} else {
				history.push({
					pathname: `/${storeId}/startSession`,
					state: {
						storeId,
					},
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValidSession, isSessionFlowRedirect, showLoaderIcon]);

	useEffect(() => {
		window.scrollTo(0, itemScrollPosition);
		window.addEventListener('scroll', scrollHighlight);
		return () => {
			window.removeEventListener('scroll', scrollHighlight);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scheduleCategories]);

	useEffect(() => {
		const selectedItem = selectedCategory;
		//Horizontal scroll when the hidden Nav heading got highlighted
		const navigationCategory = document.getElementById(`nav-${selectedItem}`);
		if (selectedItem && navigationCategory) {
			const navigationCategoryHorizontalPosition = navigationCategory.getBoundingClientRect().x;
			const navigationCategoryWidth = navigationCategory.getBoundingClientRect().width;
			if (navigationCategoryHorizontalPosition && navigationCategoryWidth) {
				const categoryScrollLeftX =
					navigationCategoryHorizontalPosition +
					navigationCategoryWidth +
					categoryNavXPosition -
					screen.width;
				if (categoryScrollLeftX > 0 || categoryScrollLeftX < FIRST_CATEGORY_NAVIGATION_ALIGN) {
					const menuHeader = document.getElementById('navMenuHeader');
					if (menuHeader) {
						menuHeader.scrollLeft = categoryScrollLeftX;
					}
				}
				if (categoryScrollLeftX > 0) {
					setCategoryNavXPosition(categoryScrollLeftX + MENU_NAVIGATION_MARGIN);
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategory]);

	/**
	 * This below function scroll the page to selected category on click. Also set the clicked category
	 * for style changes
	 * @param {string} categoryRef - clicked category ref.
	 */
	const handleCategoryClick = (categoryRef: string) => {
		const ScrollPos = document.getElementById('scrollSpy');
		const headerLogoElement = document.getElementById('headerLogo')?.offsetHeight
			? document.getElementById('headerLogo')?.offsetHeight
			: document.getElementsByTagName('header')[0].offsetHeight;
		const sectionTop = document.getElementById('scrollSpy')?.offsetTop;

		const categoryRefElement = document.getElementById(categoryRef);
		if (categoryRef && sectionTop && ScrollPos && categoryRefElement) {
			const Ylength =
				headerLogoElement && headerLogoElement + MENU_DROPDOWN_YLENGTH + CATEGORY_NAV_LENGTH - SECTION_PADDING;
			if (Ylength && headerLogoElement)
				window.scrollTo({
					top: categoryRefElement.offsetTop - sectionTop,
					behavior: 'smooth',
				});
		}
	};

	/**
	 * This below function select the menu schedule from the dropdown list
	 * @param {string} scheduleName - selected schedule name.
	 */
	const selectMenuSchedule = (scheduleName: string) => {
		setMenuName(scheduleName);
		const activeCategories = menuItemDetail[
			getSelectedMenuItemIndex(scheduleName, menuItemDetail)
		].categories.filter((category: ISchedulesCategory) => category.categoryDetails?.available);
		setScheduleCategories(activeCategories);
		if (activeCategories?.length > 0) {
			setSelectedCategory(activeCategories[0].categoryRef);
		}
		window.scrollTo(0, 0);
	};

	/**
	 * This below function route to item detail page on click of respective item
	 */
	const handleItemClick = (itemRef: string) => {
		setItemScrollPosition(window.pageYOffset);
		if (merchantData?.isDineInEnabled && !isMenuOnlyMode) {
			history.push({
				pathname: `/${storeId}/itemOrderPage/${itemRef}`,
			});
		}
		if (isMenuOnlyMode) {
			updateToastMessage(INVALID_GUID_ERROR.MESSAGE);
		}
	};

	const handleErrorClick = () => {
		localStorage.clear();
		setIsGuidValid(true);
		setIsMenuOnlyMode(true);
	};

	const handlePayView = async () => {
		setShowToastError(true);
		setShowPayLoader(true);
		if (existingSessionId) {
			getTicket(storeId, existingSessionId, false);
			setPayView(true);
		}
	};

	const handleSessionErrorClick = () => {
		localStorage.removeItem('_grecaptcha');
		history.go(0);
		setSessionErrorStatus(0);
		setIsClientTokenSet(false);
	};

	const storeOrderDetails = () => {
		const itemInCart = localStorage.getItem('cartItems');
		const orderDetail = {
			shopName: merchantData?.name,
			table: tableRef,
			order: itemInCart && JSON.parse(itemInCart),
		};
		if (merchantData) {
			localStorage.setItem('merchantName', merchantData.name);
		}
		localStorage.setItem('orderDetail', JSON.stringify(orderDetail));
	};

	const handlePlaceOrder = () => {
		setIsSessionFlowRedirect(true);
		if (isPreAuthEnabled) {
			storeOrderDetails();
			setIsCheckSessionValid(false);
			history.push({
				pathname: `/${storeId}/orderDetail`,
				state: {
					storeId,
				},
			});
			return;
		}

		if (tableRef && existingSessionId) {
			checkSessionData(storeId, existingSessionId, smsCode, tableRef);
		}
	};

	const handleViewCheckClick = () => {
		setShowToastError(true);
		if (existingSessionId) {
			getTicket(storeId, existingSessionId, true);
		}
	};

	// tracks scroll position when user scroll and highlight category.
	const scrollHighlight = () => {
		const scrollPos = document.documentElement.scrollTop;
		let currentSection = 0;
		scheduleCategories?.map((section: ISchedulesCategory, index: number) => {
			const element = document.getElementById(section.categoryRef);
			const sectionTop = element?.offsetTop;
			const sectionHeight = element?.clientHeight;
			if (
				sectionTop &&
				sectionHeight &&
				scrollPos &&
				scrollPos >= sectionTop + sectionHeight - MENU_SCROLL_MODIFIER_BUFFER_OFFSET
			) {
				currentSection = index + 1;
			}
		});
		setSelectedCategory(scheduleCategories?.[currentSection]?.categoryRef);
	};

	if (!isGuidValid) {
		return (
			<ErrorModal
				handleDismiss={handleErrorClick}
				typeOfError={INVALID_GUID_ERROR.TITLE}
				errorMsg={INVALID_GUID_ERROR.MESSAGE}
				showButton={false}
			/>
		);
	}

	if (apiErrorMessage.MESSAGE?.length > 0) {
		return (
			<ErrorModal
				handleDismiss={handleSessionErrorClick}
				typeOfError={apiErrorMessage.TITLE}
				errorMsg={apiErrorMessage.MESSAGE}
				showButton={true}
			/>
		);
	}

	const MenuFooter = () => {
		const showReviewPlaceOrderBtn =
			isPendingItemInCart &&
			!isMenuOnlyMode &&
			merchantData?.isDineInEnabled &&
			!showLoader;
		const balanceValue = ticketDetails?.amountDue || 0;
		const payLabel = `${PAY_NOW}${merchantData && getCurrencySymbol(merchantData?.currency)}${balanceValue}`;

		return (
			<>
				{showReviewPlaceOrderBtn && (
					<Footer
						externalStyle={''}
						id={REVIEW_PLACE_ORDER}
						buttonColor={'Red'}
						labelText={REVIEW_PLACE_ORDER}
						itemPrice={totalPriceInCart}
						showQuantityDetail={false}
						addToOrderInner={handlePlaceOrder}
						loaderStatus={showLoaderIcon}
					/>
				)}
				{isCheckSessionValid && !isPendingItemInCart && isQrPayEnabled && (
					<Footer
						externalStyle={''}
						id={PAY_NOW}
						buttonColor={'Green'}
						labelText={payLabel}
						showQuantityDetail={false}
						addToOrderInnerWithoutParams={handlePayView}
						loaderStatus={showPayLoader}
					/>
				)}
			</>
		);
	};

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<>
					{merchantData && (
						<Header
							id="headerLogo"
							restaurantAddress={merchantData.address}
							restaurantContact={merchantData.phone}
							restaurantInfo={merchantData.name}
							restaurantLogoImage={merchantLogoUrl}
							restaurantImage={merchantBannerImageUrl}
						/>
					)}
					{merchantData?.isDineInEnabled && menuListSchedule?.length ? (
						<>
							<div className={homeStyles.menuBlock}>
								<MenuDropdown
									options={menuListSchedule}
									changeSchedule={selectMenuSchedule}
									menuName={menuName}
								/>
								{isCheckSessionValid && (
									<button className={homeStyles.viewCheck} onClick={handleViewCheckClick}>
										{VIEW_CHECK_BUTTON}
									</button>
								)}
							</div>
							<nav className={styles.categoriesNav}>
								<ul id="navMenuHeader" className={styles.scrollingNav}>
									{scheduleCategories?.map((category: ISchedulesCategory) => (
										<button
											key={category.categoryRef}
											name={category.categoryRef}
											id={`nav-${category.categoryRef}`}
											className={
												category.categoryRef === selectedCategory
													? styles.active
													: styles.categoryName
											}
											onClick={() => handleCategoryClick(category.categoryRef)}
										>
											{category.categoryDetails?.name}
										</button>
									))}
								</ul>
							</nav>
							<section
								id="scrollSpy"
								className={homeStyles.scrollSpy}
								style={{
									paddingBottom: `calc(100vh - ${SECTION_BUFFER_OFFSET}px)`,
								}}
							>
								{scheduleCategories?.map((category: ISchedulesCategory) => (
									<div id={category.categoryRef} key={category.categoryRef}>
										<div className={styles.categoryInfo}>
											<h3 className={styles.categoryName}>{category.categoryDetails?.name}</h3>
											<p className={styles.categoryDesc}>
												{category.categoryDetails?.description}
											</p>
										</div>
										{category.itemList?.map((item: IItem, index: number) => (
											<Fragment key={item.ref}>
												{[...item.customLabels, ...item.tags].length ? (
													<div
														role="button"
														onClick={() => handleItemClick(item.ref)}
														onKeyDown={() => handleItemClick(item.ref)}
														tabIndex={index}
													>
														<MenuItemWithBadges
															key={item.ref}
															badges={[...item.customLabels, ...item.tags]}
															itemDescription={item.description}
															itemImage={item.imageUrl800x800}
															itemImageSize="medium"
															itemName={item.name}
															itemPrice={item.price}
															currency={merchantData.currency}
														/>
													</div>
												) : (
													<div
														role="button"
														onClick={() => handleItemClick(item.ref)}
														onKeyDown={() => handleItemClick(item.ref)}
														tabIndex={index}
													>
														<MenuItemWithoutBadges
															key={item.ref}
															itemDescription={item.description}
															itemImage={item.imageUrl800x800}
															itemImageSize={
																item?.imageUrl800x800?.length ? 'medium' : 'hideElement'
															}
															itemName={item.name}
															itemPrice={item.price}
															currency={merchantData.currency}
														/>
													</div>
												)}
											</Fragment>
										))}
									</div>
								))}
							</section>
						</>
					) : (
						<div className={homeStyles.menuErrorContainer}>
							<div className={homeStyles.menuErrorIcon}>
								<img src={warning} className={homeStyles.menuErrorImg} alt="warning sign" />
							</div>
							<div className={homeStyles.menuErrorMsg}>{MENU_UNAVAILABLE_MESSAGE}</div>
						</div>
					)}
				</>
			)}
			<MenuFooter />
			{toastMessage?.length > 0 && <Toast message={toastMessage} />}
		</>
	);
};

export default Home;
