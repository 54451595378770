import cancelIconSvg from '../../../assets/cancelIcon.svg';
import cancelIconSvgWithBackground from '../../../assets/cancelIconWithBg.svg';
import styles from './CancelIcon.module.scss';
interface ICancelEventProps {
	type: string;
	handleCancelClick: () => void;
}
const CancelIcon = ({ type, handleCancelClick }: ICancelEventProps): JSX.Element => {
	return (
		<>
			{type === 'withoutBackground' ? (
				<input
					type="image"
					src={cancelIconSvg}
					alt="cancel"
					onClick={handleCancelClick}
					className={styles.cancelIconWithoutBackground}
				/>
			) : (
				<input
					type="image"
					src={cancelIconSvgWithBackground}
					alt="cancel"
					onClick={handleCancelClick}
					className={styles.cancelIconWithBackground}
				/>
			)}
		</>
	);
};
export default CancelIcon;
