import React, { useContext, useEffect } from 'react';
import { Route, Switch, withRouter, useHistory } from 'react-router-dom';

import { RouteConfig } from './routeConfig/routeConfig';
import Home from './pages/home/Home';
import OrderConfirmView from './pages/orderDetails/OrderConfirmView';
import ItemOrder from './pages/ItemOrder/ItemOrder';
import CustomerDetails from './pages/customerdetail/CustomerDetails';
import OrderDetail from './pages/orderDetails/OrderDetail';
import ViewTicketDetail from './pages/viewTickets/ViewTicketDetail';

import style from './App.module.scss';
import PreAuthDetails from './pages/preAuthenticationDetail/PreAuthenticationDetail';
import { extractStoreIdFromURL, isPreAuthEnabled } from './utils/homeUtils';
import OrderDetailPreAuth from './pages/orderDetails/orderDetailsPreAuth';
import MenuItemsPageContext, { IMenuItemContext } from './Contexts/MenuItemsPageContext/MenuItemsPageContext';
import PreAuthPageContext, { IPreAuthItemContext } from './Contexts/PreAuthPageContext/PreAuthPageContext';

export interface IRoutedComponentProps {
	location?: Location;
	history?: History;
}

type IProps = IRoutedComponentProps;
const AppShell: React.FC<IProps> = () => {
	const browserHistory = useHistory();
	const menuPage = localStorage.getItem('menuPage');
	const { merchantData, updateMerchantDetail } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const existingSessionId = localStorage.getItem('storedSessionId');
	const { setIsActiveSession } = useContext(PreAuthPageContext) as IPreAuthItemContext;
	const storeId = extractStoreIdFromURL(browserHistory.location.pathname);

	useEffect(() => {
		const loadMerchantData = async () => {
			// update title on app refresh after it is set in Home.tsx
			const merchantName = localStorage.getItem('merchantName');
			document.title = merchantName ? `${merchantName} - SkyTab QR` : `SkyTab QR`;

			await updateMerchantDetail(storeId);

			if (!merchantData && existingSessionId) {
				setIsActiveSession(true);
				browserHistory.push(menuPage);
			}
		};
		loadMerchantData();
	}, [storeId, merchantData, existingSessionId]);

	useEffect(() => {
		if (window.grecaptcha) {
			window.grecaptcha.ready(() => {
				const badge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
				if (!badge) return;
				badge.style.display = 'none';
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.grecaptcha]);

	return (
		<div className={style.app}>
			<Switch>
				<Route exact path={RouteConfig.root} render={(routerProps: any) => <Home {...routerProps} />} />
				<Route exact path={RouteConfig.homePage} render={(routerProps: any) => <Home {...routerProps} />} />
				<Route exact path={RouteConfig.startPage} render={(routerProps: any) => <Home {...routerProps} />} />
				<Route
					exact
					path={RouteConfig.orderConfirmView}
					render={(routerProps: any) => <OrderConfirmView {...routerProps} />}
				/>
				<Route
					exact
					path={RouteConfig.itemOrderPage}
					render={(routerProps: any) => <ItemOrder {...routerProps} />}
				/>
				<Route
					exact
					path={RouteConfig.startSession}
					render={(routerProps: any) => {
						return isPreAuthEnabled() ? (
							<PreAuthDetails {...routerProps} />
						) : (
							<CustomerDetails {...routerProps} />
						);
					}}
				/>
				<Route
					exact
					path={RouteConfig.orderDetail}
					render={(routerProps: any) => {
						return isPreAuthEnabled() ? (
							<OrderDetailPreAuth {...routerProps} />
						) : (
							<OrderDetail {...routerProps} />
						);
					}}
				/>

				<Route
					exact
					path={RouteConfig.viewTicketDetail}
					render={(routerProps: any) => <ViewTicketDetail {...routerProps} />}
				/>
			</Switch>
		</div>
	);
};

export const App = withRouter(AppShell);
