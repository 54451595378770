import { apiCall } from '../api/apiCall';
import { ICustomerData, IClientToken, ITickets } from '../pages/customerdetail/models/customerDataModels';

/**
 * This below function call the api for starting a session
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {ICustomerData} userData - user data containing name, number and table ref.
 */
export const postUserData = async (locationId: string, userData: ICustomerData): Promise<IClientToken> =>
	await apiCall(`/api/v1/merchants/${locationId}/session`, 'POST', {}, userData);

/**
 * This below function call the api for checking session validity
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {string} sessionId - session id received from BE sent URL.
 * @param {string} clientToken - clientToken received from session storage.
 * @param {string} code - sms code received from customer.
 * @param {string} tableRefId - tableRefId recevied from the scanned QR data.
 */
export const fetchSession = async (
	locationId: string,
	sessionId: string,
	clientToken: string,
	code: string,
	tableRefId: string
): Promise<ITickets> =>
	await apiCall(`/api/v1/merchants/${locationId}/session/${sessionId}?tableRef=${tableRefId}`, 'GET', {
		'client-token': clientToken,
		'sms-code': code,
	});

/**
 * This below function calls the api for assigning existing tickets to the existing session
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {string} sessionId - session id received from BE sent URL.
 * @param {string} ticketRef - selected ticketsRef by the customer.
 * @param {string} tableRefId - tableRefId recevied from the scanned QR data.
 */
export const assignTicketSession = async (
	locationId: string,
	sessionId: string,
	ticketRef: string,
	tableRefId: string
): Promise<ITickets> =>
	await apiCall(
		`/api/v1/merchants/${locationId}/session/${sessionId}`,
		'PUT',
		{},
		{
			tableRef: tableRefId,
			ticketRef: ticketRef,
		}
	);

/**
 * This below function calls the api to send new sms code to validate the session
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {string} sessionId - session id received from BE sent URL.
 * @param {string} clientToken - clientToken received from session storage.
 */
export const resendCode = async (locationId: string, sessionId: string, clientToken: string): Promise<ITickets> =>
	await apiCall(
		`/api/v1/merchants/${locationId}/session/${sessionId}/sms-code `,
		'POST',
		{
			'client-token': clientToken,
		},
		{}
	);
