import React from 'react';

const Tick: React.FC = () => {
	return (
		<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.35352 10.0137C4.72266 10.0137 5.00977 9.87305 5.20898 9.57422L10.3828 1.65234C10.5234 1.43555 10.582 1.23633 10.582 1.04883C10.582 0.539062 10.2012 0.175781 9.68555 0.175781C9.33398 0.175781 9.11133 0.298828 8.89453 0.638672L4.33594 7.83984L2.01562 4.96289C1.81641 4.72266 1.59961 4.61133 1.29492 4.61133C0.773438 4.61133 0.392578 4.98633 0.392578 5.49609C0.392578 5.71875 0.462891 5.91797 0.65625 6.14648L3.52148 9.61523C3.75 9.89062 4.01367 10.0137 4.35352 10.0137Z"
				fill="#3E9550"
			/>
		</svg>
	);
};

export default Tick;
