import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'semantic-ui-css/semantic.min.css';
import './index.module.scss';
import { App } from './app/App';
import MenuItemsPageContextProvider from './app/Contexts/MenuItemsPageContext/MenuItemsPageContextProvider';
import ItemOrderPageContextProvider from './app/Contexts/ItemOrderPageContext/ItemOrderPageContextProvider';
import OrderConfirmPageContextProvider from './app/Contexts/OrderConfirmPageContext/OrderConfirmPageContextProvider';
import AppContextProvider from './app/Contexts/AppContext/AppContextProvider';

import reportWebVitals from './reportWebVitals';

import OrderDetailPageContextProvider from './app/Contexts/OrderDetailPageContext/OrderDetailPageContextProvider';
import ViewTicketPageContextProvider from './app/Contexts/ViewTicketPageContext/ViewTicketPageContextProvider';
import PreAuthPageContextProvider from './app/Contexts/PreAuthPageContext/PreAuthPageContextProvider';

const queryClient = new QueryClient();

ReactDOM.render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<AppContextProvider>
				<MenuItemsPageContextProvider>
					<ItemOrderPageContextProvider>
						<OrderDetailPageContextProvider>
							<OrderConfirmPageContextProvider>
								<ViewTicketPageContextProvider>
									<PreAuthPageContextProvider>
										<App />
									</PreAuthPageContextProvider>
								</ViewTicketPageContextProvider>
							</OrderConfirmPageContextProvider>
						</OrderDetailPageContextProvider>
					</ItemOrderPageContextProvider>
				</MenuItemsPageContextProvider>
			</AppContextProvider>
		</QueryClientProvider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
