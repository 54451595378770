import { apiCall } from '../api/apiCall';
import { ITicketResponse } from '../pages/viewTickets/models/ticketDetailsModels';
import { isPreAuthEnabled } from '../utils/homeUtils';

/**
 * This below function call the api for checking session detail
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {string} sessionId - session id received from BE sent URL.
 */

export const getTicketDetails = async (locationId: string, sessionId: string): Promise<ITicketResponse> => {
	if (isPreAuthEnabled()) {
		return await apiCall(`/api/v2/merchants/${locationId}/session/${sessionId}/check`, 'GET');
	}
	return await apiCall(`/api/v1/merchants/${locationId}/session/${sessionId}/check`, 'GET');
};
