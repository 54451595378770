export const REVIEW_PLACE_ORDER = 'Review & Place Order';
export const PAY_NOW = 'Pay Now ';
export const VIEW_CHECK_BUTTON = 'View Check';
export const MENU_UNAVAILABLE_MESSAGE = `We're sorry, viewing the menu is not available at this time.`;
export const INVALID_GUID_ERROR = {
	MESSAGE: `QR Code is invalid. Please contact a server.`,
	TITLE: `Error`,
};
export const INVALID_TICKET_ERROR_MESSAGE = 'This ticket is closed. Please contact server for more information.';
export const VALID_QUANTITY_ERROR = 'Please enter valid quantity.';
export const INVALID_PHONE_NUMBER_ERROR = 'Please provide valid phone number.';
export const INVALID_CODE_ERROR = 'Please provide a valid code';
export const SCRIPT_ERROR = 'Something went wrong. Please contact server for more information.';
