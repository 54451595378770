export const RouteConfig = {
	root: '/',
	startPage: '/:storeId',
	homePage: '/:storeId/homePage',
	itemOrderPage: '/:storeId/itemOrderPage/:itemRef',
	orderConfirmView: '/:storeId/orderConfirmView',
	startSession: '/:storeId/startSession',
	orderDetail: '/:storeId/orderDetail',
	viewTicketDetail: '/:storeId/viewTicketDetail',
	preAuthPage: '/:storeId/preAuthPage',
};
