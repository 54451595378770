import { useContext, useState } from 'react';
import { getCurrencySymbol, getFormattedPrice } from '../helper/common';
import styles from './Footer.module.scss';

import minus from '../../../assets/minus.svg';
import plus from '../../../assets/plus.svg';
import minusDisable from '../../../assets/minusDisable.svg';
import LoaderIcon from '../loaderIcon/LoaderIcon';
import Toast from '../toast/Toast';
import { VALID_QUANTITY_ERROR } from '../../pages/home/labels';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';

export interface IFooterCompProps {
	externalStyle: string;
	id?: string;
	labelText: string;
	buttonColor?: string;
	showQuantityDetail: boolean;
	itemPrice?: number;
	loaderStatus?: boolean;
	addToOrderInner?: (totalPrice: number, quantity: number) => void;
	addToOrderInnerWithoutParams?: () => void;
	updateQuantityValue?: (quantity: number) => void;
	quantityValue?: number;
	balanceValue?: number;
}

const Footer = ({
	externalStyle,
	labelText,
	buttonColor,
	showQuantityDetail,
	itemPrice,
	loaderStatus,
	addToOrderInner,
	addToOrderInnerWithoutParams,
	updateQuantityValue,
	quantityValue,
	balanceValue,
}: IFooterCompProps): JSX.Element => {
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const [itemQuantity, setItemQuantity] = useState(quantityValue?.toString() || '1');
	const [error, setError] = useState(false);
	const MINIMUM_ITEM_QUANTITY = 1;
	const MAXIMUM_ITEM_QUANTITY = 99;
	const getTotalPrice = () => {
		if (itemPrice && itemQuantity) {
			return Number(itemQuantity) * itemPrice;
		}
		return itemPrice;
	};
	const [totalPrice, setTotalPrice] = useState(getTotalPrice());

	const updateTotalPrice = (updatedItemQuantity: number) => {
		const itemTotalPrice = updatedItemQuantity * Number(itemPrice);
		setItemQuantity(updatedItemQuantity.toString());
		setTotalPrice(Number(itemTotalPrice.toFixed(2)));
	};

	/**
	 * Decrease the quantity by 1 and also verify number to be in the min and max range of 1 to 99
	 * Remove the Error Message
	 * And update the totalPrice
	 */

	const decreaseQuantity = () => {
		setError(false);
		const decreaseValidityCondition =
			Number(itemQuantity) > MINIMUM_ITEM_QUANTITY && Number(itemQuantity) <= MAXIMUM_ITEM_QUANTITY;
		if (decreaseValidityCondition) {
			if (updateQuantityValue) {
				updateQuantityValue(Number(itemQuantity) - 1);
			}
			setItemQuantity((Number(itemQuantity) - 1).toString());
			updateTotalPrice(Number(itemQuantity) - 1);
		}
	};

	/**
	 * Increase the quantity by 1 and also verify number to be in the min and max range of 1 to 99
	 * Remove the Error Message
	 * And update the totalPrice
	 */

	const increaseQuantity = () => {
		setError(false);
		if (itemQuantity) {
			const increaseValidityCondition =
				Number(itemQuantity) >= MINIMUM_ITEM_QUANTITY && Number(itemQuantity) < MAXIMUM_ITEM_QUANTITY;
			if (increaseValidityCondition) {
				if (updateQuantityValue) {
					updateQuantityValue(Number(itemQuantity) + 1);
				}
				setItemQuantity((Number(itemQuantity) + 1).toString());
				updateTotalPrice(Number(itemQuantity) + 1);
			}
		} else {
			const updatedItemQuantity = 1;
			if (updateQuantityValue) {
				updateQuantityValue(updatedItemQuantity);
			}
			setItemQuantity(updatedItemQuantity.toString());
			updateTotalPrice(updatedItemQuantity);
		}
	};

	/**
	 * Update the quantity input provided value and also verify number to be in the min and max range of 1 to 99
	 * Remove the Error Message
	 * And update the totalPrice
	 */

	const updateOnChange = (inputValue: string) => {
		setError(false);
		if (!inputValue) {
			setItemQuantity(inputValue);
			if (updateQuantityValue) {
				updateQuantityValue(Number(inputValue));
			}
			setTotalPrice(getTotalPrice());
		}
		const onChangeValidityCondition =
			Number(inputValue) >= MINIMUM_ITEM_QUANTITY && Number(inputValue) <= MAXIMUM_ITEM_QUANTITY;
		if (onChangeValidityCondition) {
			setItemQuantity(inputValue);
			if (updateQuantityValue) {
				updateQuantityValue(Number(inputValue));
			}
			setItemQuantity(Number(inputValue).toString());
			updateTotalPrice(Number(inputValue));
		}
	};

	const addToOrder = () => {
		if (Number(itemQuantity) < MINIMUM_ITEM_QUANTITY) {
			setError(true);
		} else if (addToOrderInner && totalPrice != undefined && totalPrice >= 0) {
			addToOrderInner(totalPrice, Number(itemQuantity));
			setError(false);
		} else if (addToOrderInnerWithoutParams) {
			addToOrderInnerWithoutParams();
			setError(false);
		}
	};

	return (
		<>
			<div
				className={`${styles.footer} ${externalStyle} }`}
				style={{ display: balanceValue ? 'block' : 'inline-flex' }}
				id={'footerContainer'}
			>
				{balanceValue && merchantData ? (
					<div className={styles.balanceDueRow}>
						<div className={styles.balanceDueName}>Balance Due:</div>
						<div className={styles.balanceDueValue}>
							{`${getCurrencySymbol(merchantData.currency)}${balanceValue}`}
						</div>
					</div>
				) : null}
				{showQuantityDetail && (
					<div className={styles.quantityContainerDiv}>
						<div className={styles.quantityIconDiv}>
							<input
								type="image"
								src={Number(itemQuantity) > MINIMUM_ITEM_QUANTITY ? minus : minusDisable}
								alt="minus"
								onClick={decreaseQuantity}
								className={styles.quantityIcon}
							/>
						</div>
						<div>
							<input
								className={`${styles.quantityText} ${error && styles.errorBorder} }`}
								type="number"
								value={itemQuantity.toString()}
								onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
								onChange={(e) => updateOnChange(e.target.value)}
							></input>
						</div>
						<div className={styles.quantityIconDiv}>
							<input
								type="image"
								src={plus}
								alt="plus"
								onClick={increaseQuantity}
								className={`${styles.quantityIcon} ${
									Number(itemQuantity) < MAXIMUM_ITEM_QUANTITY ? '' : `${styles.plusOpacity} `
								}`}
							/>
						</div>
					</div>
				)}
				<button
					className={`${styles.btnStyle} ${styles[`button${buttonColor}`]} ${
						!showQuantityDetail && styles.fullWidth
					}`}
					onClick={addToOrder}
					disabled={loaderStatus}
				>
					{loaderStatus && <LoaderIcon />}
					{labelText}
					{totalPrice != undefined && totalPrice >= 0 && merchantData?.currency
						? ` - ${getFormattedPrice(totalPrice, merchantData?.currency)}`
						: null}
				</button>
			</div>
			{error && <Toast message={VALID_QUANTITY_ERROR} />}
		</>
	);
};
export default Footer;
