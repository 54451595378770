import { apiCall } from '../api/apiCall';
import {
	I4goIdPostData,
	I4goIdPostResponse,
	IAccessDetailsPostData,
	IAccessDetailsResponse,
	ITableDataResponse,
} from '../pages/preAuthenticationDetail/models/PreAuthModel';

/**
 * This below function fetches the table data by using tableRef
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {string} tableRef - Table reference ID recevied from the scanned QR data.
 */
export const getTableData = async (locationId: string, tableRef: string): Promise<ITableDataResponse> =>
	await apiCall(`/api/v1/merchants/${locationId}/tables/${tableRef}`, 'GET');

/**
 * This below function fetches the table data by using tableRef
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {string} tableRef - Table reference ID recevied from the scanned QR data.
 */
export const getAccessData = async (
	locationId: string,
	orderData: IAccessDetailsPostData
): Promise<IAccessDetailsResponse> => await apiCall(`/api/v2/merchants/${locationId}/authorize`, 'POST', {}, orderData);

/**
 * This below function fetches the table data by using tableRef
 * @param {string} locationId - merchant locationId recevied from the scanned QR data.
 * @param {string} tableRef - Table reference ID recevied from the scanned QR data.
 */
export const getSessionIdService = async (
	locationId: string,
	i4goIdData: I4goIdPostData
): Promise<I4goIdPostResponse> => await apiCall(`/api/v2/merchants/${locationId}/session`, 'POST', {}, i4goIdData);
