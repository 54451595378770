import React, { CSSProperties } from 'react';
import styles from './SpecialInstruction.module.scss';

interface ISpecialInstructionProps {
	externalStyle: CSSProperties;
	label?: string;
	placeholder?: string;
	text?: string;
	handleChange: (value: string) => void;
}

const MAX_TEXT_ALLOWED = 180;

const SpecialInstruction: React.FC<ISpecialInstructionProps> = ({
	externalStyle,
	label,
	placeholder,
	text,
	handleChange,
	...props
}: ISpecialInstructionProps) => (
	<>
		<section className={styles.specialInstruction} {...props} style={externalStyle} id="specialInstruction">
			<div>
				<label className={styles.instructionLabel}>{label}</label>
				<textarea
					className={styles.instructionTextarea}
					placeholder={placeholder}
					maxLength={MAX_TEXT_ALLOWED}
					onChange={(e) => handleChange(e.target.value)}
					value={text}
				/>
			</div>
		</section>
	</>
);

export default SpecialInstruction;
