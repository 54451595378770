import { createContext } from 'react';

import { IItem } from '../../pages/home/models/menuItemModels';

export interface IItemOrderContext {
	itemDetails?: IItem | undefined;
	setSelectedItemDetails: (itemRef: string) => void;
	setItemDetails: (ItemValue: IItem) => void;
}

export const ItemOrderPageContext: React.Context<IItemOrderContext | null> = createContext<IItemOrderContext | null>(
	null
);
export default ItemOrderPageContext;
