import { generateApiUrl } from './utils';

export const apiCall = (
	url: string,
	callType: string,
	optionalHeader?: Record<string, unknown>,
	postData?: Record<string, unknown>
): Promise<Record<string, unknown>> => {
	const apiUrl = generateApiUrl(url);

	return new Promise((resolve, reject) => {
		fetch(apiUrl, {
			method: callType,
			headers: {
				...{
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				...optionalHeader,
			},
			body: JSON.stringify(postData),
			redirect: 'manual',
		})
			.then((response) => {
				const contentType = response.headers.get('content-type');
				if (contentType && contentType.indexOf('application/json') !== -1) {
					resolve(response.json());
				} else {
					resolve({
						type: response.type,
						status: response.status,
						url: response.url,
					});
				}
			})
			.catch((error) => {
				if (error.response === undefined) {
					reject(error);
					return;
				}
				resolve(error.response);
			});
	});
};
