import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import lessThanIcon from '../../../assets/lessThanIcon.svg';
import { IModifierDetails, IModifierSetDetails, IOrderDetailItems } from '../home/models/orderDetailModels';
import { getFormattedPrice } from '../../components/helper/common';
import orderDetailStyles from './OrderDetail.module.scss';
import Footer from '../../components/footer/Footer';
import usePreAuthScripts from '../../hooks/usePreAuthScripts';
import ViewTicketDetailPageContext, {
	IViewTicketDetailPageContext,
} from '../../Contexts/ViewTicketPageContext/ViewTicketPageContext';
import { constructPlaceOrderRequestBody } from '../../utils/orderUtils';
import OrderDetailPageContext, {
	IOrderDetailContext,
} from '../../Contexts/OrderDetailPageContext/OrderDetailPageContext';
import * as labels from './labels';
import ErrorModal from '../../components/errormodal/ErrorModal';
import ApiErrorModal from '../../components/errormodal/ApiErrorModal';
import { INVALID_CARD_ERROR } from '../preAuthenticationDetail/labels';
import PreAuthPageContext, { IPreAuthItemContext } from '../../Contexts/PreAuthPageContext/PreAuthPageContext';
import MenuItemsPageContext, { IMenuItemContext } from '../../Contexts/MenuItemsPageContext/MenuItemsPageContext';

interface IProps {
	match: {
		params: {
			storeId: string;
		};
	};
}

const OrderDetailPreAuth: React.FC<IProps> = (props) => {
	const history = useHistory();

	const tableRefId = localStorage.getItem('tableRef');
	const { merchantData } = useContext(MenuItemsPageContext) as IMenuItemContext;
	const { reCaptchaLoaded, generateReCaptchaToken } = usePreAuthScripts(true, false);
	const { getTicket, ticketError, setTicketError, shouldPlaceOrder, setShouldPlaceOrder, isCheckSessionValid } =
		useContext(ViewTicketDetailPageContext) as IViewTicketDetailPageContext;
	const { postOrderDetail } = useContext(OrderDetailPageContext) as IOrderDetailContext;
	const { setRecaptchaToken } = useContext(PreAuthPageContext) as IPreAuthItemContext;
	const [loading, setLoading] = useState<boolean>(false);
	const [orderDetail, setOrderDetail] = useState({
		shopName: '',
		customerName: '',
		table: '',
		order: {
			items: [
				{
					menuItemGuid: '',
					specialRequest: '',
					itemName: '',
					itemPrice: '',
					quantity: 1,
					modSets: [],
				},
			],
			totalPrice: '',
		},
	});
	const { storeId } = props.match.params;
	const order = localStorage.getItem('orderDetail');
	const existingSessionId = localStorage.getItem('storedSessionId');
	const [apiErrorMessage, setApiErrorMessage] = useState({ MESSAGE: '', TITLE: '', STATUS: 0 });
	const [placeOrderError, setPlaceOrderError] = useState({ MESSAGE: '', TITLE: '' });

	useEffect(() => {
		window.scrollTo(0, 0);
		setShouldPlaceOrder(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (order) {
			setOrderDetail(JSON.parse(order));
		}

		if (!isCheckSessionValid && ticketError?.status) {
			setLoading(false);
			const apiError = labels.PRE_AUTH_API_ERRORS;
			const apiErrorIndex = apiError.findIndex((error) => error.STATUS === ticketError?.status);
			if (apiErrorIndex !== -1) {
				setApiErrorMessage(apiError[apiErrorIndex]);
				return;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCheckSessionValid, order, ticketError]);

	useEffect(() => {
		if (shouldPlaceOrder && isCheckSessionValid) {
			placeOrder();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldPlaceOrder, isCheckSessionValid]);

	useEffect(() => {
		if (orderDetail?.order?.items?.length === 0) {
			localStorage.removeItem('orderDetail');
			localStorage.removeItem('cartItems');
			localStorage.removeItem('ticketRefDetail');
			history.push(`/${storeId}?tableRef=${tableRefId}`);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderDetail]);

	const handleBackClick = () => {
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	const modifiersDom = (modifiers: IModifierDetails, modifiersDomIndex: number) => {
		return (
			<div key={modifiersDomIndex}>
				{modifiers.menuModGuid && modifiers.menuModName && (
					<div className={orderDetailStyles.ticketRow}>
						<div className={orderDetailStyles.modifiersDomLabel}>{modifiers.menuModName}</div>
						<div className={orderDetailStyles.ticketRowValue}>
							{Number(modifiers.modifierPrice) !== 0 &&
								merchantData &&
								`+${getFormattedPrice(Number(modifiers.modifierPrice), merchantData?.currency)}`}
						</div>
					</div>
				)}
				{modifiers.modSets &&
					modifiers.modSets?.length >= 0 &&
					modifiers.modSets.map((modifierSet3: IModifierSetDetails, modifierSet3Index: number) => {
						return modifierSetDom(modifierSet3, modifierSet3Index);
					})}
			</div>
		);
	};

	const modifierSetDom = (modifierSet: IModifierSetDetails, modifierSetIndex: number) => {
		return (
			<div className={orderDetailStyles.modifierSetDomContainer} key={modifierSetIndex}>
				<div className={orderDetailStyles.ticketRow}>
					<div className={orderDetailStyles.modifierSetDomLabel}>{modifierSet.menuModSetName}</div>
				</div>

				{modifierSet?.mods &&
					modifierSet?.mods?.length >= 0 &&
					modifierSet?.mods.map((modifierSet2: IModifierDetails, modifiersDomIndex: number) => {
						return modifiersDom(modifierSet2, modifiersDomIndex);
					})}
			</div>
		);
	};

	const itemsRenderDom = (itemDetails: IOrderDetailItems, itemDetailIndex: number) => {
		return (
			<Fragment key={itemDetailIndex}>
				<div className={orderDetailStyles.ticketRow}>
					<div className={orderDetailStyles.ticketRowLabelBold}>
						{itemDetails.quantity} {itemDetails.itemName}
					</div>
					{merchantData && (
						<div className={orderDetailStyles.ticketRowValue}>
							{getFormattedPrice(Number(itemDetails.itemPrice), merchantData?.currency)}
						</div>
					)}
				</div>
				{itemDetails.modSets &&
					itemDetails.modSets?.length >= 0 &&
					itemDetails.modSets.map(
						(itemDetailsModifierSet: IModifierSetDetails, itemDetailsModifierSetIndex: number) => {
							return modifierSetDom(itemDetailsModifierSet, itemDetailsModifierSetIndex);
						}
					)}

				<div className={orderDetailStyles.removeIcon}>
					<Button
						className={orderDetailStyles.link}
						onClick={() => handleRemoveItem(itemDetails, itemDetailIndex)}
					>
						Remove
					</Button>
				</div>
			</Fragment>
		);
	};

	const handleRemoveItem = (removeItem: IOrderDetailItems, itemDetailIndex: number) => {
		let modifierPrice = 0;
		const itemsInCart = localStorage.getItem('cartItems');
		const merchantName = localStorage.getItem('merchantName');
		const tableRef = localStorage.getItem('tableRef');

		if (itemsInCart) {
			let pendingItems = JSON.parse(itemsInCart);
			let updatedPrice = Number(pendingItems.totalPrice);
			const itemQuantity = Number(removeItem.quantity);
			let removedItemPrice = Number(removeItem.itemPrice);
			removedItemPrice = removedItemPrice * itemQuantity;
			if (pendingItems?.items[itemDetailIndex]?.menuItemGuid === removeItem.menuItemGuid) {
				pendingItems?.items?.splice(itemDetailIndex, 1);
			}
			pendingItems = pendingItems.items;
			const modLevel = (modLevelItem: IModifierDetails[]) => {
				modLevelItem.forEach((item2: IModifierDetails) => {
					modifierPrice = modifierPrice + Number(item2.modifierPrice) * itemQuantity;

					if (item2.modSets) {
						modsetLevel(item2.modSets);
					}
				});
			};
			const modsetLevel = (modsetLevelItem: IModifierSetDetails[]) => {
				modsetLevelItem.forEach((item1: IModifierSetDetails) => {
					if (item1.mods) {
						modLevel(item1.mods);
					}
				});
			};
			if (removeItem.modSets) {
				modsetLevel(removeItem.modSets);
			}
			updatedPrice = updatedPrice - removedItemPrice - modifierPrice;
			const updatedOrderDetail = JSON.stringify({
				order: { totalPrice: updatedPrice, items: pendingItems },
				shopName: merchantName,
				table: tableRef,
			});
			localStorage.setItem('orderDetail', updatedOrderDetail);
			setOrderDetail(JSON.parse(updatedOrderDetail));
			localStorage.setItem('cartItems', JSON.stringify({ totalPrice: updatedPrice, items: pendingItems }));
		} else {
			localStorage.removeItem('orderDetail');
			localStorage.removeItem('cartItems');
			history.push(`/${storeId}?tableRef=${tableRefId}`);
		}
	};

	const startSession = async () => {
		await generateReCaptchaToken('order').then((recaptchaToken) => {
			if (recaptchaToken) {
				setRecaptchaToken(recaptchaToken);
				history.push(`/${storeId}/startSession`);
			}
		});
	};

	const handlePlaceOrder = () => {
		if (existingSessionId) {
				setLoading(true);
				getTicket(storeId, existingSessionId, false);
			} else {
				startSession();
			}
	};

	const placeOrder = async () => {
		if (orderDetail) {
			const orderDetailRequestBody = constructPlaceOrderRequestBody(orderDetail);
			const orderDetailResponseObject = await postOrderDetail(storeId, orderDetailRequestBody);
			setShouldPlaceOrder(false);
			setLoading(false);
			if (orderDetailResponseObject?.error && [500, 400, 404].includes(orderDetailResponseObject.error.status)) {
				const apiError = labels.PRE_AUTH_API_ERRORS;
				localStorage.removeItem('storedSessionId');
				if (orderDetailResponseObject.error.status === 500) {
					setApiErrorMessage(apiError[2]);
				} else {
					setPlaceOrderError(INVALID_CARD_ERROR);
				}
			} else {
				const selectedPreviousTicketRef = localStorage.getItem('selectedPreviousTicketRef');
				if (!selectedPreviousTicketRef && orderDetailResponseObject.order?.ticketGuid) {
					localStorage.setItem('selectedPreviousTicketRef', orderDetailResponseObject.order.ticketGuid);
				}
				localStorage.removeItem('cartItems');
				history.push(`/${storeId}/OrderConfirmView`);
			}
		}
	};

	const handleErrorClick = () => {
		setApiErrorMessage({ MESSAGE: '', TITLE: '', STATUS: 0 });
		if (ticketError?.status === 404 || ticketError?.status === 400) {
			startSession();
			setTicketError(null);
		} else {
			setTicketError(null);
			history.push(`/${storeId}?tableRef=${tableRefId}`);
		}
	};

	const handlePlaceOrderError = () => {
		setPlaceOrderError({ MESSAGE: '', TITLE: '' });
		history.push(`/${storeId}?tableRef=${tableRefId}`);
	};

	if (placeOrderError.MESSAGE?.length > 0) {
		return (
			<ApiErrorModal
				handleDismiss={handlePlaceOrderError}
				typeOfError={placeOrderError.TITLE}
				errorMsg={placeOrderError.MESSAGE}
				actionType={'Retry'}
				isBackIconEnabled={true}
			/>
		);
	}

	return (
		<>
			{apiErrorMessage.MESSAGE?.length > 0 ? (
				<ErrorModal
					handleDismiss={handleErrorClick}
					typeOfError={apiErrorMessage.TITLE}
					errorMsg={apiErrorMessage.MESSAGE}
					showButton={true}
				/>
			) : (
				<div className={orderDetailStyles.orderDetailContainer} id="orderDetailContainer">
					<div className={orderDetailStyles.orderDetailHeading}>
						<div className={orderDetailStyles.backButton}>
							<Button icon className="float left backButton" onClick={handleBackClick}>
								<img src={lessThanIcon} className={orderDetailStyles.backButton} alt="back" />
							</Button>
						</div>
						<div className={orderDetailStyles.headingTitle}>Order Details</div>
					</div>
					<div className={orderDetailStyles.divider}></div>
					<div className={orderDetailStyles.detailContainer}>
						<div className={orderDetailStyles.ticketContainer}>
							<div className={orderDetailStyles.ticketRow}>
								<div className={orderDetailStyles.ticketRowTableLabelBold}>{orderDetail.shopName}</div>
							</div>
						</div>
						<div className={orderDetailStyles.divider} />
						<div className={orderDetailStyles.itemDetailsContainer}>
							<div className={orderDetailStyles.menuItemsTitle}>Menu Items:</div>
							{orderDetail?.order?.items?.map(
								(itemDetail: IOrderDetailItems, itemDetailIndex: number) => {
									return itemsRenderDom(itemDetail, itemDetailIndex);
								}
							)}
						</div>
						<div className={orderDetailStyles.divider} />
						{merchantData && (
							<div className={orderDetailStyles.ticketContainer}>
								<div className={orderDetailStyles.ticketRow}>
									<div className={orderDetailStyles.ticketRowTableLabelBold}> ITEM TOTAL:</div>
									<div className={orderDetailStyles.ticketRowTableBoldValue}>
										{getFormattedPrice(
											Number(orderDetail?.order?.totalPrice),
											merchantData?.currency
										)}
									</div>
								</div>
							</div>
						)}
					</div>
					<div className={orderDetailStyles.footer}>
						<Footer
							id={existingSessionId ? 'Place Order' : 'Continue'}
							externalStyle={orderDetailStyles.placeOrderBackground}
							buttonColor={'Blue'}
							labelText={existingSessionId ? 'Place Order' : 'Continue'}
							showQuantityDetail={false}
							addToOrderInnerWithoutParams={handlePlaceOrder}
							loaderStatus={!reCaptchaLoaded || loading}
						/>
					</div>
				</div>
			)}
		</>
	);
};
export default OrderDetailPreAuth;
