export const ENTER_DETAILS = 'Please enter the details below';
export const DELIVER_ORDER = 'This helps us deliver your order to you.';
export const TABLE_DETAILS = 'You are dining at table';
export const AUTHORIZE_ERROR = {
	MESSAGE: 'We could not place an order from this device. Please order directly from a staff member.',
	TITLE: '',
};

export const INVALID_CARD_ERROR = {
	MESSAGE: 'Please place order again and re-enter your CC information',
	TITLE: 'Invalid Card',
};
export const TIME_OUT_ERROR = {
	MESSAGE: 'You’re being timed out due to inactivity. Please place your order again.',
	TITLE: '',
};
