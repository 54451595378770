import { useEffect, useState } from 'react';

declare global {
	interface Window {
		grecaptcha: {
			execute: (siteKey: string, action: unknown) => Promise<string>;
			ready: (fn: () => void) => void;
		};
	}
}

const hideBadge = () => {
	if (!window.grecaptcha) return;
	window.grecaptcha.ready(() => {
		const badge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
		if (!badge) return;
		badge.style.visibility = 'hidden';
	});
};

const usePreAuthScripts = (
	isPreAuthEnabled: boolean,
	loadI4go: boolean
): {
	i4goScriptLoaded: boolean;
	reCaptchaLoaded: boolean;
	generateReCaptchaToken: (action: string) => Promise<string>;
} => {
	const [reCaptchaLoaded, setReCaptchaLoaded] = useState<boolean>(false);
	const [i4goScriptLoaded, setI4goScriptLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (i4goScriptLoaded || !loadI4go || !isPreAuthEnabled) return;
		const script = document.createElement('script');
		script.async = true;
		script.src = `${process.env.I4GO_HOST}/js/jquery.i4goTrueToken.js`;
		script.onerror = () => {
			setI4goScriptLoaded(false);
		};
		script.addEventListener('load', () => {
			setI4goScriptLoaded(true);
		});
		document.body.appendChild(script);
	}, [isPreAuthEnabled, i4goScriptLoaded, loadI4go]);

	useEffect(() => {
		localStorage.removeItem('_grecaptcha');
		if (typeof window === 'undefined' || reCaptchaLoaded || !isPreAuthEnabled) return;
		if (window.grecaptcha) {
			hideBadge();
			setReCaptchaLoaded(true);
			return;
		}
		const script = document.createElement('script');
		script.async = true;
		script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
		script.onerror = () => {
			setReCaptchaLoaded(false);
		};
		script.addEventListener('load', () => {
			setReCaptchaLoaded(true);
			hideBadge();
		});
		document.body.appendChild(script);
		return hideBadge;
	}, [isPreAuthEnabled, reCaptchaLoaded]);

	const generateReCaptchaToken = (action: string): Promise<string> => {
		return new Promise((resolve, reject) => {
			if (!reCaptchaLoaded) return reject(new Error('ReCaptcha not loaded'));
			if (typeof window === 'undefined' || !window.grecaptcha) {
				setReCaptchaLoaded(false);
				return reject(new Error('ReCaptcha not loaded'));
			}
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY || '', { action })
					.then((token: string) => {
						resolve(token);
					});
			});
		});
	};

	return { i4goScriptLoaded, reCaptchaLoaded, generateReCaptchaToken };
};

export default usePreAuthScripts;
