import { createContext } from 'react';
import { ITicket, IItem, IDiscount, IErrorResponse } from '../../pages/viewTickets/models/ticketDetailsModels';

export interface IViewTicketDetailPageContext {
	ticketDetails?: ITicket | undefined;
	ticketItemDetails?: IItem | undefined;
	ticketError: IErrorResponse | null;
	setTicketError: (ticketError: IErrorResponse | null) => void;
	shouldPlaceOrder: boolean;
	setShouldPlaceOrder: (shouldPlaceOrder: boolean) => void;
	shouldRedirectToViewCheck: boolean;
	ticketDiscountDetails?: IDiscount | undefined;
	getTicket: (locationId: string, sessionId: string, viewCheck: boolean) => void;
	getQrPayRedirect: (locationId: string, sessionId: string) => void;
	payRedirectError: IErrorResponse | undefined;
	setPayRedirectError: (payRedirectError: IErrorResponse | undefined) => void;
	showLoaderIcon: boolean;
	setShowLoaderIcon: (showLoaderIcon: boolean) => void;
	setShouldRedirectToViewCheck: (shouldRedirectToViewCheck: boolean) => void;
	setTicketDetails: (ticketDetails: ITicket | undefined) => void;
	isCheckSessionValid: boolean;
	setIsCheckSessionValid: (isCheckSessionValid: boolean) => void;
	isCheckSessionLoading: boolean;
	setIsCheckSessionLoading: (isValidSession: boolean) => void;
}

export const ViewTicketDetailPageContext: React.Context<IViewTicketDetailPageContext | null> =
	createContext<IViewTicketDetailPageContext | null>(null);
export default ViewTicketDetailPageContext;
