import React, { useContext, useState } from 'react';

import ViewTicketPageContext from './ViewTicketPageContext';
import { getTicketDetails } from '../../services/ViewTicketPageServices';
import { getQRPay } from '../../services/OrderConfirmPageService';
import { ITicket, ITicketResponse, IErrorResponse } from '../../pages/viewTickets/models/ticketDetailsModels';
import AppContext, { IAppContext } from '../AppContext/AppContext';

interface IProps {
	children?: React.ReactNode;
}

const ViewTicketPageContextProvider: React.FC<IProps> = ({ children }) => {
	const [ticketDetails, setTicketDetails] = useState<ITicket>();
	const [ticketError, setTicketError] = useState<IErrorResponse | null>(null);
	const [shouldRedirectToViewCheck, setShouldRedirectToViewCheck] = useState<boolean>(false);
	const [payRedirectError, setPayRedirectError] = useState<IErrorResponse>();
	const [showLoaderIcon, setShowLoaderIcon] = useState<boolean>(false);
	const [shouldPlaceOrder, setShouldPlaceOrder] = useState<boolean>(false);
	const [isCheckSessionValid, setIsCheckSessionValid] = useState<boolean>(false);
	const { setValidSessionId } = useContext(AppContext) as IAppContext;
	const [isCheckSessionLoading, setIsCheckSessionLoading] = useState<boolean>(true);

	const getTicket = async (locationId: string, sessionId: string, viewCheck: boolean) => {
		setIsCheckSessionLoading(true);
		const ticketResponse: ITicketResponse = await getTicketDetails(locationId, sessionId);

		if (ticketResponse?.error?.status && [500, 400, 404].includes(ticketResponse.error.status)) {
			ticketResponse.error.code = 'USER_SESSION_INVALID_TICKET';
			if (ticketResponse?.error?.status === 400 || ticketResponse?.error?.status === 404) {
				localStorage.removeItem('storedSessionId');
			}
			setIsCheckSessionValid(false);
			setShouldPlaceOrder(false);
			setTicketError(ticketResponse.error);
			if (viewCheck) {
				setShouldRedirectToViewCheck(false);
			}
			setIsCheckSessionLoading(false);
			return;
		}
		if (ticketResponse.ticket) {
			setValidSessionId(sessionId);
			setShouldPlaceOrder(true);
			setIsCheckSessionValid(true);
			setTicketDetails(ticketResponse.ticket);
			if (viewCheck) {
				setShouldRedirectToViewCheck(true);
			}
			setIsCheckSessionLoading(false);
		}
		setTicketError(null);
	};

	const getQrPayRedirect = async (locationId: string, sessionId: string): Promise<void> => {
		setShowLoaderIcon(true);
		const response = await getQRPay(locationId, sessionId);
		if (response?.error?.status === 404) {
			setPayRedirectError(response.error);
		} else {
			setPayRedirectError(undefined);
			window.location = response.url;
		}
		setShowLoaderIcon(false);
	};
	const value = {
		ticketError,
		setTicketError,
		ticketDetails,
		getTicket,
		setTicketDetails,
		getQrPayRedirect,
		payRedirectError,
		setPayRedirectError,
		showLoaderIcon,
		setShowLoaderIcon,
		shouldRedirectToViewCheck,
		setShouldRedirectToViewCheck,
		shouldPlaceOrder,
		setShouldPlaceOrder,
		isCheckSessionValid,
		setIsCheckSessionValid,
		isCheckSessionLoading,
		setIsCheckSessionLoading,
	};

	return <ViewTicketPageContext.Provider value={value}>{children}</ViewTicketPageContext.Provider>;
};

export default ViewTicketPageContextProvider;
