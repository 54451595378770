import {
	ISchedulesCategory,
	IItem,
	IItemsModifierSet,
	IModifierSet,
	IModifiersDetails,
	IItemsModifier,
	IModifier,
	IOrderModifierSet,
	IOrderModifier,
	IValidateNestedModifierSet,
} from '../pages/home/models/menuItemModels';

/**
 * This below function fetches the selected item and its details from the schedule data which contain
 * all the item details.
 * @param {ISchedulesCategory} scheduleCategories - all the category detail which contains the
 * selected item details.
 * @param {string} itemRef - selected item reference.
 * @returns {IItem} - returns the updated menu data having a mapping with schedules and
 * category.
 */
export const getModifiedItemData = (scheduleCategories: ISchedulesCategory[], itemRef: string): IItem => {
	const ITEM_DATA_INDEX_LENGTH = 0;
	const scheduleCategoriesData = JSON.parse(JSON.stringify(scheduleCategories));
	const items = scheduleCategoriesData
		?.map(
			(category: ISchedulesCategory) =>
				category.itemList && category.itemList.filter((item: IItem) => item.ref === itemRef)
		)
		.filter((item: IItem[]) => item.length > ITEM_DATA_INDEX_LENGTH)[ITEM_DATA_INDEX_LENGTH];

	return items[ITEM_DATA_INDEX_LENGTH];
};

export const updateModifiersSetDetails = (
	modifierSets: IItemsModifierSet[],
	modifiersData?: IModifiersDetails,
	parentValue?: string
): IItemsModifierSet[] => {
	modifierSets.map((modifierSet: IItemsModifierSet) => {
		if (modifiersData) {
			const modifierSetData = modifiersData.modifierSets.find(
				(modifierSetDetail: IModifierSet) => modifierSetDetail.ref === modifierSet.modifierSetRef
			);

			if (modifierSetData) {
				modifierSet.modifierSetDetails = modifierSetData;
				modifierSet.key = parentValue
					? `${parentValue}#${modifierSet.modifierSetRef}`
					: modifierSet.modifierSetRef;
			}

			modifierSet.modifiers = updateModifierData(modifierSet.modifiers || [], modifiersData, modifierSet.key);

			// checking all modifiers available or not based on that we are setting hasModifiers
			if (
				modifierSet.modifiers?.length ===
				modifierSet.modifiers?.filter((modifier) => !modifier.modifierDetails?.available).length
			) {
				modifierSet.hasModifiers = false;
			} else {
				modifierSet.hasModifiers = true;
			}

			// min and max definitions are validated before usage in ItemOrder Page
			// check if max has null | 0 | undefined and min > 0 then max = min
			if (
				!modifierSet.modifierSetDetails?.maxModifiersPerSet &&
				modifierSet.modifierSetDetails?.minModifiersPerSet
			) {
				modifierSet.modifierSetDetails.maxModifiersPerSet = modifierSet.modifierSetDetails?.minModifiersPerSet;
			}
		}
	});
	return modifierSets || [];
};

export const updateModifierData = (
	itemModifiers: IItemsModifier[],
	modifiersMasterData: IModifiersDetails,
	parentValue?: string
): IItemsModifier[] => {
	const itemModifierDetails = JSON.parse(JSON.stringify(itemModifiers));
	// using JSON.parse(JSON.stringify()) for modifiersMasterData?.modifiers
	// incase of duplicate modifiers, same price value assigning to all for others,
	// with this it will create new object so we will get fresh data respected to modifier.price
	const totalModifiers: IModifier[] = JSON.parse(JSON.stringify(modifiersMasterData?.modifiers));
	return itemModifierDetails.map((modifier: IItemsModifier) => {
		const modifierDetails = totalModifiers.find(
			(modifierData: IModifier) => modifierData.ref === modifier.modifierRef
		);
		if (modifierDetails) {
			modifier.modifierDetails = modifierDetails;
			modifier.modifierDetails.price = modifier.price || 0;
			modifier.key = `${parentValue}#${modifier.modifierRef}`;
		}
		if (modifier?.modifierSets?.length) {
			modifier.modifierSets = updateModifiersSetDetails(modifier.modifierSets, modifiersMasterData, modifier.key);
		}
		return modifier;
	});
};

export const updateModifierSetsAsPerOrderApi = (modifierSets: IItemsModifierSet[]): IOrderModifierSet[] => {
	const updateOrderModifierSets: IOrderModifierSet[] = [];
	modifierSets.forEach((modifierSet: IItemsModifierSet) => {
		updateOrderModifierSets.push({
			menuModSetGuid: modifierSet?.modifierSetRef,
			description: modifierSet?.modifierSetDetails?.description || '',
			menuModSetName: modifierSet?.modifierSetDetails?.name || '',
			mods: modifierSet?.modifiers !== undefined ? updateModifiersAsPerOrderApi(modifierSet?.modifiers) : [],
		});
	});

	return updateOrderModifierSets;
};

export const updateModifiersAsPerOrderApi = (modifiers: IItemsModifier[]): IOrderModifier[] => {
	const updateModifiers: IOrderModifier[] = [];
	modifiers.forEach((modifier: IItemsModifier) => {
		updateModifiers.push({
			menuModGuid: modifier.modifierRef,
			quantity: 1,
			description: modifier?.modifierDetails?.description || '',
			menuModName: modifier?.modifierDetails?.name,
			modifierPrice: modifier?.modifierDetails?.price,
			modSets:
				modifier?.modifierSets !== undefined ? updateModifierSetsAsPerOrderApi(modifier?.modifierSets) : [],
		});
	});
	return updateModifiers;
};

const ModifierAvailableCheck = (actualModifierSet: IItemsModifierSet) => {
	return (
		Number(actualModifierSet?.modifierSetDetails?.minModifiersPerSet) > 0 &&
		actualModifierSet?.hasModifiers &&
		actualModifierSet?.modifierSetDetails?.available
	);
};

export const validateSelectedNestedModifierSets = (
	actualModifierSets: IItemsModifierSet[],
	selectedModifierSets: IItemsModifierSet[]
): IValidateNestedModifierSet[] => {
	let validateMessageDetails: IValidateNestedModifierSet[] = [];
	actualModifierSets?.forEach((actualModifierSet: IItemsModifierSet) => {
		const { modifierSetDetails } = actualModifierSet;
		const modifierSetExists = selectedModifierSets.find((selectedModifierSet: IItemsModifierSet) => {
			return selectedModifierSet.modifierSetRef === actualModifierSet.modifierSetRef;
		});
		if (ModifierAvailableCheck(actualModifierSet)) {
			// modifier Required not exists and throw warning required
			if (!modifierSetExists?.modifierSetRef) {
				validateMessageDetails.push({
					message: `${modifierSetDetails?.name} required.`,
					modifierSetName: modifierSetDetails?.name || '',
					modifierSetKey: actualModifierSet.key,
				});
			}

			// select exactly if min and max are equal
			if (
				modifierSetExists?.modifierSetRef &&
				Number(modifierSetDetails?.minModifiersPerSet) === Number(modifierSetDetails?.maxModifiersPerSet) &&
				modifierSetExists?.modifiers?.length !== Number(modifierSetDetails?.minModifiersPerSet)
			) {
				validateMessageDetails.push({
					message: ` ${modifierSetDetails?.name} required exactly ${modifierSetDetails?.minModifiersPerSet}.`,
					modifierSetName: modifierSetDetails?.name || '',
					modifierSetKey: actualModifierSet.key,
				});
			}

			// if min > 1 and max >1 then following rules
			if (
				modifierSetExists?.modifierSetRef &&
				Number(modifierSetDetails?.minModifiersPerSet) >= 1 &&
				Number(modifierSetDetails?.maxModifiersPerSet) > 1
			) {
				if (Number(modifierSetExists?.modifiers?.length) < Number(modifierSetDetails?.minModifiersPerSet)) {
					// minimum required
					validateMessageDetails.push({
						message: ` ${modifierSetDetails?.name} required minimum ${modifierSetDetails?.minModifiersPerSet} .`,
						modifierSetName: modifierSetDetails?.name || '',
						modifierSetKey: actualModifierSet.key,
					});
				} else if (
					Number(modifierSetExists?.modifiers?.length) >= Number(modifierSetDetails?.minModifiersPerSet) &&
					Number(modifierSetExists?.modifiers?.length) > Number(modifierSetDetails?.maxModifiersPerSet)
				) {
					// selected more than maximum then throw below message
					validateMessageDetails.push({
						message: ` ${modifierSetDetails?.name} selected more than ${modifierSetDetails?.maxModifiersPerSet}.`,
						modifierSetName: modifierSetDetails?.name || '',
						modifierSetKey: actualModifierSet.key,
					});
				}
			}
		}
		if (modifierSetExists?.modifiers?.length) {
			if (actualModifierSet?.modifiers?.length && modifierSetExists?.modifiers?.length) {
				const updateValidateMessageDetails = validateSelectedNestedModifiers(
					actualModifierSet?.modifiers,
					modifierSetExists?.modifiers
				);
				validateMessageDetails = validateMessageDetails.concat(updateValidateMessageDetails);
			}
		}
	});
	return validateMessageDetails;
};

export const validateSelectedNestedModifiers = (
	actualModifiers: IItemsModifier[],
	selectedModifiers: IItemsModifier[]
): IValidateNestedModifierSet[] => {
	let validateMessageDetails: IValidateNestedModifierSet[] = [];
	actualModifiers.forEach((actualModifier: IItemsModifier) => {
		if (
			actualModifier?.modifierSets?.length &&
			selectedModifiers?.length &&
			actualModifier.modifierRef === selectedModifiers[0].modifierRef
		) {
			const updateValidateMessageDetails = validateSelectedNestedModifierSets(
				actualModifier?.modifierSets,
				selectedModifiers[0]?.modifierSets || []
			);
			validateMessageDetails = validateMessageDetails.concat(updateValidateMessageDetails);
		}
	});
	return validateMessageDetails;
};

/**
 *
 * @param modifierSets
 * @param itemPrice
 * @param selectedKeys
 * @returns total price for selected modifiers by sending all selected keys  and item price
 */
export const calculateItemTotalPriceWithSelectedKeys = (
	modifierSets: IItemsModifierSet[],
	itemPrice: number,
	selectedKeys: string[]
): number => {
	let updateItemPrice: number = itemPrice;
	if (modifierSets?.length) {
		modifierSets.forEach((modifierSet: IItemsModifierSet) => {
			updateItemPrice = calculateItemPriceWithSelectedModifierkeys(
				modifierSet?.modifiers || [],
				updateItemPrice,
				selectedKeys
			);
		});
	}
	return updateItemPrice;
};

/**
 *
 * @param itemModifiers
 * @param itemPrice
 * @param selectedKeys
 * @returns  addition of  modifiers price with item price
 */
export const calculateItemPriceWithSelectedModifierkeys = (
	itemModifiers: IItemsModifier[],
	itemPrice: number,
	selectedKeys: string[]
): number => {
	let updateItemPrice: number = itemPrice;
	itemModifiers.forEach((itemModifier: IItemsModifier) => {
		if (itemModifier?.modifierDetails?.price && selectedKeys?.includes(itemModifier.key)) {
			updateItemPrice = Number(updateItemPrice) + Number(itemModifier.modifierDetails.price);
		}
		if (itemModifier?.modifierSets?.length) {
			updateItemPrice = calculateItemTotalPriceWithSelectedKeys(
				itemModifier?.modifierSets,
				updateItemPrice,
				selectedKeys
			);
		}
	});
	return updateItemPrice;
};

/**
 *
 * @param modifierSets
 * @param selectedModifierKeys
 * @returns converting selected unique keys into IItemModifierSet[] Type
 */
export const convertSelectedKeysToModifierSetsFormat = (
	modifierSets: IItemsModifierSet[],
	selectedModifierKeys: string[]
): IItemsModifierSet[] => {
	const finalSelectedModifierSets: IItemsModifierSet[] = [];
	if (modifierSets?.length && selectedModifierKeys?.length) {
		selectedModifierKeys?.forEach((selectedModifierKey: string) => {
			const nestedLevelIds = selectedModifierKey.split('#');
			const modifierSetResult = updateModifierSetsWithKeys(
				modifierSets,
				nestedLevelIds,
				0,
				finalSelectedModifierSets
			);
			if (modifierSetResult?.length) {
				const modifierSetIndex: number = finalSelectedModifierSets.findIndex(
					(selectedModifierSet: IItemsModifierSet) =>
						selectedModifierSet.modifierSetRef === modifierSetResult[0].modifierSetRef
				);
				if (modifierSetIndex && modifierSetResult[0].modifierSetDetails?.ref) {
					finalSelectedModifierSets[modifierSetIndex] = {
						key: modifierSetResult[0].key,
						modifierSetRef: modifierSetResult[0].modifierSetRef,
						modifierSetDetails: modifierSetResult[0].modifierSetDetails,
						modifiers: modifierSetResult[0]?.modifiers || [],
						hasModifiers: modifierSetResult[0].hasModifiers,
					};
				} else {
					finalSelectedModifierSets.push(modifierSetResult[0]);
				}
			}
		});
	}
	return removeDuplicateModifierSetInArray(finalSelectedModifierSets);
};

/**
 *
 * @param modifierSets
 * @param nestedLevelIds
 * @param level
 * @param finalSelectedModifierSets
 * @returns converting selected keys into IItemModifierSet Type
 */
export const updateModifierSetsWithKeys = (
	modifierSets: IItemsModifierSet[],
	nestedLevelIds: string[],
	level: number,
	finalSelectedModifierSets: IItemsModifierSet[]
): IItemsModifierSet[] => {
	const finalModifierSets: IItemsModifierSet[] = finalSelectedModifierSets;
	modifierSets?.forEach((modifierSet: IItemsModifierSet) => {
		if (modifierSet.modifierSetRef === nestedLevelIds[level] && modifierSet?.modifierSetDetails?.ref) {
			const modifierSetExists: IItemsModifierSet | undefined = finalSelectedModifierSets.find(
				(selectedModifierSet: IItemsModifierSet) =>
					modifierSet.modifierSetRef === selectedModifierSet.modifierSetRef
			);
			const modifierSetIndex: number = finalSelectedModifierSets.findIndex(
				(selectedModifierSet: IItemsModifierSet) =>
					modifierSet.modifierSetRef === selectedModifierSet.modifierSetRef
			);
			if (modifierSetExists) {
				finalModifierSets[modifierSetIndex] = {
					modifierSetRef: modifierSet?.modifierSetRef,
					modifiers: nestedLevelIds[level + 1]
						? updateModifiersWithKeys(
								modifierSet?.modifiers || [],
								nestedLevelIds,
								level + 1,
								modifierSetExists?.modifiers || []
						)
						: [],
					modifierSetDetails: modifierSet?.modifierSetDetails,
					key: modifierSet.key,
					hasModifiers: modifierSet?.hasModifiers,
				};
			} else {
				finalModifierSets.push({
					modifierSetRef: modifierSet?.modifierSetRef,
					modifiers: nestedLevelIds[level + 1]
						? updateModifiersWithKeys(modifierSet?.modifiers || [], nestedLevelIds, level + 1, [])
						: [],
					modifierSetDetails: modifierSet?.modifierSetDetails,
					key: modifierSet.key,
					hasModifiers: modifierSet?.hasModifiers,
				});
			}
		}
	});

	return finalModifierSets;
};

/**
 *
 * @param modifiers
 * @param nestedLevelIds
 * @param level
 * @param finalSelectedModifiers
 * @returns converting  selected keys of modifiers into IItemModifier type
 */
export const updateModifiersWithKeys = (
	modifiers: IItemsModifier[],
	nestedLevelIds: string[],
	level: number,
	finalSelectedModifiers: IItemsModifier[]
): IItemsModifier[] => {
	const finalModifiers: IItemsModifier[] = finalSelectedModifiers;
	modifiers?.forEach((modifier: IItemsModifier) => {
		if (modifier?.modifierDetails?.ref && modifier.modifierRef === nestedLevelIds[level]) {
			const modifierExists: IItemsModifier | undefined = finalSelectedModifiers.find(
				(selectedModifier: IItemsModifier) => modifier.modifierRef === selectedModifier.modifierRef
			);
			const modifierIndex: number = finalSelectedModifiers.findIndex(
				(selectedModifier: IItemsModifier) => modifier.modifierRef === selectedModifier.modifierRef
			);
			if (modifierExists) {
				finalModifiers[modifierIndex] = {
					modifierRef: modifier.modifierRef,
					key: modifier.key,
					modifierDetails: modifier.modifierDetails,
					modifierSets: nestedLevelIds[level + 1]
						? updateModifierSetsWithKeys(
								modifier?.modifierSets || [],
								nestedLevelIds,
								level + 1,
								modifierExists?.modifierSets || []
						)
						: [],
				};
			} else {
				finalModifiers.push({
					modifierRef: modifier.modifierRef,
					key: modifier.key,
					modifierDetails: modifier.modifierDetails,
					modifierSets: nestedLevelIds[level + 1]
						? updateModifierSetsWithKeys(modifier?.modifierSets || [], nestedLevelIds, level + 1, [])
						: [],
				});
			}
		}
	});
	return finalModifiers;
};

/**
 *
 * @param modifierSets
 * @returns removing duplicate modifier Sets in final modifier set format
 */
export const removeDuplicateModifierSetInArray = (modifierSets: IItemsModifierSet[]): IItemsModifierSet[] => {
	const updateModifierSets: IItemsModifierSet[] = [];
	for (const modifierSet of modifierSets) {
		if (!updateModifierSets.some((modSet) => modSet.modifierSetRef === modifierSet.modifierSetRef)) {
			updateModifierSets.push(modifierSet);
		}
	}
	return updateModifierSets;
};

/**
 * filter the modifier keys after selected key in nested modifiers
 *
 * @param updateSelectedModifierSets
 * @param selectedKey
 * @param controlSelection
 * @param selectedKeys
 * @returns selectedKeys after filtering the keys as per nested modifiers
 */
export const filterSelectedKeys = (
	updateSelectedModifierSets: string[],
	selectedKey: string,
	controlSelection: string,
	selectedKeys: string[],
	modifierSets: IItemsModifierSet[]
): string[] => {
	let finalSelectedModifierKeys: string[] = [];
	// For Level 2 will have more than 3  "#"
	const LEVEL_TWO_MODIFIER_SET = 3;
	// to get all level ids for selected element
	const selectedModifierLevelIds = selectedKey.split('#');
	const parentModifierSet: IItemsModifierSet | undefined = modifierSets?.find(
		(modifierSet: IItemsModifierSet) => modifierSet.key === selectedModifierLevelIds[0]
	);
	const maxModifiersPerSet = parentModifierSet?.modifierSetDetails?.maxModifiersPerSet || 0;

	if (updateSelectedModifierSets?.length && controlSelection === 'unchecked') {
		const updateData = updateSelectedModifierSets.filter((modifierKey: string) => {
			// to get all level keys from parent to child for each modifier element in the loop
			const existingModifierLevelIds = modifierKey.split('#');
			// if parent Modifier unchecked then get level 2 child modifier to remove from selectedKeys
			if (existingModifierLevelIds.length > LEVEL_TWO_MODIFIER_SET) {
				return !existingModifierLevelIds.includes(
					selectedModifierLevelIds[selectedModifierLevelIds.length - 1]
				);
			}
			return true;
		});
		finalSelectedModifierKeys = updateData;
	} else if (controlSelection === 'selected') {
		let selectedParentIdRadioButton = '';
		// remove radio button already selected by creating parent id
		selectedModifierLevelIds.forEach((levelId: string, index: number) => {
			// radio button selection
			if (index < selectedModifierLevelIds.length - 1) {
				selectedParentIdRadioButton = `${selectedParentIdRadioButton}${levelId}#`;
			}
		});
		if (selectedParentIdRadioButton) {
			const updateSelectedKeys: string[] = selectedKeys.filter(
				(modifierKey: string) =>
					!(modifierKey.includes(selectedParentIdRadioButton) && selectedKey.length === modifierKey.length)
			);

			//  if selected child modifier to check parent modifiers key selected or not, if not selected otherwise add to selectedKeys
			if (selectedModifierLevelIds.length > LEVEL_TWO_MODIFIER_SET) {
				finalSelectedModifierKeys = addParentKeyForSelectedChildKey(
					selectedModifierLevelIds,
					updateSelectedKeys,
					selectedKeys,
					selectedKey,
					maxModifiersPerSet
				);
			} else {
				if (maxModifiersPerSet && maxModifiersPerSet <= 1) {
					finalSelectedModifierKeys = updateSelectedKeys.filter(
						(modifierKey: string) => !modifierKey.includes(`${selectedModifierLevelIds[0]}#`)
					);
				}
				finalSelectedModifierKeys = finalSelectedModifierKeys.concat(selectedKey);
			}
		}
	} else if (selectedModifierLevelIds.length > LEVEL_TWO_MODIFIER_SET) {
		finalSelectedModifierKeys = addParentKeyForSelectedChildKey(
			selectedModifierLevelIds,
			updateSelectedModifierSets,
			selectedKeys,
			selectedKey,
			maxModifiersPerSet
		);
	} else {
		finalSelectedModifierKeys = updateSelectedModifierSets;
	}
	return finalSelectedModifierKeys;
};

/**
 *  If user selected  modifier check it has parent modifier or not
 *
 * @param selectedModifierLevelIds
 * @param updateSelectedKeys
 * @param selectedKeys
 * @returns {updateSelectedKeys} gives updated keys along with parent keys.
 */
export const addParentKeyForSelectedChildKey = (
	selectedModifierLevelIds: string[],
	updateSelectedKeys: string[],
	selectedKeys: string[],
	selectedKey: string,
	maxModifiersPerSet: number
): string[] => {
	const parentLevelId = `${selectedModifierLevelIds[0]}#${selectedModifierLevelIds[1]}`;
	if (selectedKeys.indexOf(parentLevelId) > -1) {
		if (updateSelectedKeys.includes(selectedKey)) {
			return updateSelectedKeys;
		}

		return updateSelectedKeys.concat(selectedKey);
	}

	// checking radio button or not
	if (maxModifiersPerSet && maxModifiersPerSet === 1) {
		const updateSelectedKeysWithoutParentKeys = updateSelectedKeys.filter(
			(modifierKey: string) => !modifierKey.includes(`${selectedModifierLevelIds[0]}#`)
		);

		// If selected child but parent modifier is not selected enforce parent is selected.
		return updateSelectedKeysWithoutParentKeys.concat([parentLevelId, selectedKey]);
	}

	// selectedKeys have data and selectedKey is included in updateSelectedKeys add parentLevelId and return otherwise we add parentLevelId AND selectedKey and return
	return updateSelectedKeys.length && updateSelectedKeys.includes(selectedKey)
		? updateSelectedKeys.concat([parentLevelId])
		: updateSelectedKeys.concat([parentLevelId, selectedKey]);
};
