import React, { useContext, useState } from 'react';

import ItemOrderPageContext from './ItemOrderPageContext';
import MenuItemsPageContext, { IMenuItemContext } from '../MenuItemsPageContext/MenuItemsPageContext';
import { getModifiedItemData } from '../../utils/itemOrderUtils';
import { IItem } from '../../pages/home/models/menuItemModels';

interface IProps {
	children?: React.ReactNode;
}

const ItemOrderPageContextProvider: React.FC<IProps> = ({ children }) => {
	const [itemDetails, setItemDetails] = useState<IItem>();
	const { scheduleCategories } = useContext(MenuItemsPageContext) as IMenuItemContext;

	const setSelectedItemDetails = (itemRef: string) => {
		const itemData = scheduleCategories?.length && getModifiedItemData(scheduleCategories, itemRef);
		if (itemData) {
			setItemDetails(itemData);
		}
	};

	const value = {
		itemDetails,
		setSelectedItemDetails,
		setItemDetails,
	};

	return <ItemOrderPageContext.Provider value={value}>{children}</ItemOrderPageContext.Provider>;
};

export default ItemOrderPageContextProvider;
